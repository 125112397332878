<template>
	<q-btn dense outline round icon="person" class="layout-header__btn">
		<q-menu>
			<q-list style="min-width: 100px">
				<q-item
					v-if="
						['ADMIN', 'DATA'].includes(this.logged_user.customer_permission) || JSON.parse(this.current_CG.customer_group.settings).send_verification_email
					"
					clickable
					v-close-popup
					:to="{ name: 'Edit User', params: { id: logged_user.id } }"
				>
					<q-item-section>Profile</q-item-section>
				</q-item>
				<q-item clickable v-close-popup :to="{ name: 'Change Password' }">
					<q-item-section>Change password</q-item-section>
				</q-item>
				<q-item clickable v-close-popup @click="logout">
					<q-item-section>Logout</q-item-section>
				</q-item>
			</q-list>
		</q-menu>
	</q-btn>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'MainLayoutHeaderProfileMenu',
	methods: { ...mapActions(['logout']) },
	computed: {
		...mapGetters(['logged_user', 'current_CG']),
	},
}
</script>
