import FieldsAPI from '@/services/api/IncidentFields.js';
import IncidentFieldGroup from '@/services/api/IncidentFieldGroup.js';
import IncidentForm from '@/services/api/IncidentForm.js';
import axios from 'axios';
import Utils from '@/services/utils.js';
import Vue from "vue";

const state = {
	all_fields: {},
	field_groups: {},
	forms: [],
	fields_errors: "",
	forms_errors: "",
	field_groups_errors: "",
	form_field_mapping_errors: "",
	rules_errors: "",
	template_errors: "",
};

const getters = {
	forms: (state) => state.forms,
	field_groups: (state) => state.field_groups,
	all_fields: (state) => state.all_fields,
	fields_errors: (state) => state.fields_errors,
	forms_errors: (state) => state.forms_errors,
	field_groups_errors: (state) => state.field_groups_errors,
	form_field_mapping_errors: (state) => state.form_field_mapping_errors,
	rules_errors: (state) => state.rules_errors,
	template_errors: (state) => state.template_errors,
	all_errors: (state) => [
		state.fields_errors,
		state.forms_errors,
		state.field_groups_errors,
		state.form_field_mapping_errors,
		state.rules_errors,
		state.template_errors
	]
};

const actions = {

	async getAllFields({commit}) {
		const response = await FieldsAPI.getFields();
		if(response.error){
			commit('getAllFields', "");
		}else{
			commit('getAllFields', response );
		}
	},

	async listForms({commit}){
		const response = await IncidentForm.getForms();
		if(!response.error){
			commit('getForms', response);
		}
	},

	async getForm({commit}, id){
		const response = await IncidentForm.retrieveForm(id);
		if(!response.error){
			return response
		}
	},

	async retrieveFieldGroups({ commit }, id){
		const response = await IncidentForm.retrieveForm(id);
		if(response.data){
			let field_groups = {}
			for (let field_group of response.data.field_groups) {
				field_groups[field_group.id] = field_group
				field_group["show_fields"] = true;
				field_group["show_conditions"] = true;
				field_group["show_content"] = true;
			}
			commit('getFieldGroups', field_groups );
		}
	},

	resetFieldGroups({ commit }) {
		commit('resetFieldGroups')
	},

	async addFieldGroup({commit}, field_group){
		let data = {
			title: field_group.title, 
			display_conditions: JSON.parse(JSON.stringify(field_group.display_conditions)), 
			content: field_group.content, 
			form: field_group.form, 
			form_field_mappings: field_group.field_mappings,
			order: field_group.order
		}
		const response = await IncidentFieldGroup.addFieldGroup(data)
		if(response.data){
			commit('newFieldGroup', response.data);
		}
		return response
	},

	async updateFieldGroup({commit}, field_group){
		let data = {
			title: field_group.title, 
			display_conditions: JSON.parse(JSON.stringify(field_group.display_conditions)), 
			content: field_group.content, 
			form: field_group.form, 
			form_field_mappings: field_group.field_mappings,
			order: field_group.order
		}
		const response = await IncidentFieldGroup.updateFieldGroup(field_group.id, data)
		if(response.data){ commit('updFieldGroup', response.data) }
		return response
	},
	async updateConditionsOnly({commit}, payload) {
		let field_mappings_clean = state.field_groups[payload.fg_id].field_mappings
			.map(field => ({id: field.id, field: field.field.id, is_required: field.is_required}))
		let conditions_clean = payload.conditions.map(condition => ({
			field_group_id: condition.field_group_id, field_id: condition.field_id,
			value: condition.value, type: condition.type
		}))
		const field_group = Utils.deepCopy(state.field_groups[payload.fg_id]);
		
		let data = {
			title: field_group.title, 
			display_conditions: conditions_clean, 
			content: field_group.content, 
			form: field_group.form, 
			form_field_mappings: field_mappings_clean,
			order: state.field_groups[payload.fg_id].order
		}

		const response = await IncidentFieldGroup.updateFieldGroup(field_group.id, data)
		if(response.data){
			commit('updConditionsOnly', response.data);
		}
	},

	async deleteFieldGroup({commit}, id){
		const response = await IncidentFieldGroup.deleteFieldGroup(id);
		if(!response.error){
			commit('removeFieldGroup', id)
		}
		return response
	},
	toggleFieldsVisibility({commit}, payload) {
		commit('updateFieldsVisibility', payload)
	},
	toggleConditionsVisibility({commit}, payload) {
		commit('updateConditionsVisibility', payload)
	},
	toggleContentVisibility({commit}, payload) {
		commit('updateContentVisibility', payload)
	},

	toggleAllFieldsVisibility({commit}, new_val) {
		commit('updateAllFieldsVisibility', new_val)
	},
	toggleAllConditionsVisibility({commit}, new_val) {
		commit('updateAllConditionsVisibility', new_val)
	},
	toggleAllContentVisibility({commit}, new_val) {
		commit('updateAllContentVisibility', new_val)
	},
	set_fields_errors({commit}, error){
		commit('setFieldsErrors', error)
	},
	set_forms_errors({commit}, error){
		commit('setFormsErrors', error)
	},
	set_field_groups_errors({commit}, error){
		commit('setFieldGroupsErrors', error)
	},
	set_form_field_mapping_errors({commit}, error){
		commit('setFormFieldMappingErrors', error)
	},
	set_rules_errors({commit}, error){
		commit('setRulesErrors', error)
	},
	set_template_errors({commit}, error){
		commit('setTemplateErrors', error)
	}
};

const mutations = {

	getForms: (state, forms) => {
		for(let i in forms){
			forms[i].is_open = false;
		}
		state.forms = forms;
	},
	getAllFields: (state, all_fields) => {
		let all_fields_object = {}
		for (let field of all_fields) {
			all_fields_object[field.id] = field;
		}
		state.all_fields = all_fields_object;
	},
	getFieldGroups: (state, field_groups) => state.field_groups = field_groups,
	newFieldGroup: (state, field_group) => {
		field_group["is_open"] = true
		field_group["show_fields"] = true
		field_group["show_conditions"] = true
		field_group["show_content"] = true
		Vue.set(state.field_groups, field_group.id, field_group)
	},
	removeFieldGroup: (state, id) => Vue.delete(state.field_groups, id),
	updFieldGroup: (state, field_group) => {
		Vue.set(field_group, "show_fields", state.field_groups[field_group.id].show_fields);
		Vue.set(field_group, "show_conditions", state.field_groups[field_group.id].show_conditions);
		Vue.set(field_group, "show_content", state.field_groups[field_group.id].show_content);
		Vue.set(state.field_groups, field_group.id, field_group)
	},
	resetFieldGroups: (state) => state.field_groups = {},
	updConditionsOnly: (state, field_group) => {
		Vue.set(state.field_groups[field_group.id], "display_conditions", field_group.display_conditions);
	},
	updateFieldsVisibility: (state, payload) => state.field_groups[payload.fg_id].show_fields = payload.visible,
	updateConditionsVisibility: (state, payload) => state.field_groups[payload.fg_id].show_conditions = payload.visible,
	updateContentVisibility: (state, payload) => state.field_groups[payload.fg_id].show_content = payload.visible,
	updateAllFieldsVisibility: (state, new_val) => {
		for (let key in state.field_groups) {
			state.field_groups[key].show_fields = new_val;
		}
	},
	updateAllConditionsVisibility: (state, new_val) => {
		for (let key in state.field_groups) {
			state.field_groups[key].show_conditions = new_val;
		}
	},
	updateAllContentVisibility: (state, new_val) => {
		for (let key in state.field_groups) {
			state.field_groups[key].show_content = new_val;
		}
	},
	setFieldsErrors: (state, new_val) => {
		state.fields_errors = new_val;
	},
	setFormsErrors: (state, new_val) => {
		state.forms_errors = new_val;
	},
	setFieldGroupsErrors: (state, new_val) => {
		state.field_groups_errors = new_val;
	},
	setFormFieldMappingErrors: (state, new_val) => {
		state.form_field_mapping_errors = new_val;
	},
	setRulesErrors: (state, new_val) => {
		state.rules_errors = new_val;
	},
	setTemplateErrors: (state, new_val) => {
		state.template_errors = new_val
	}
};

export default{
	state,
	getters,
	actions,
	mutations
}