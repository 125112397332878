import MediaLibraryAPI from '@/services/api/MediaLibrary.js';
import store from '@/store/store.js'
const state = {
	media_library_data: {},
	timeout_message_media_library: "",
	successfull_message: "",
	is_error_message: false,
	public_files: [],
	order_by: "",
	is_descending: "",
	attachments: [],
	media_files_array: [],
	selected_cg: [],
	selected_cg_index: 0,
};

const getters = {
	media_library_data: state => state.media_library_data,
	timeout_message_media_library: state => state.timeout_message_media_library,
	successfull_message: state => state.successfull_message,
	public_files: state => state.public_files,
	order_by: state => state.order_by,
	is_descending: state => state.is_descending,
	media_files_array: state => state.media_files_array,
	attachments: state => state.attachments,
	selected_cg: state => state.selected_cg,
	selected_cg_index: state => state.selected_cg_index
};

const actions = {
	async loadInitialData({commit}){
		const response = await MediaLibraryAPI.getData();
		let all_files = response.all_files
		commit('setMediaLibraryData', all_files);
		commit('setPublicFiles', response.public_files)
		commit('setSelectedCg', all_files[state.selected_cg_index])
	},
	async addFileInMediaLibrary({commit}, args){
		const response = await MediaLibraryAPI.addFileInMediaLibrary(args.data);
		if(response.error){
			let error_message = response.error_message
			if (response.error_message.error_message) {
				error_message = response.error_message.error_message
			}
			if (error_message.length > 250){
				error_message = 'There was a problem with the file. Please contact your administrator.'
			}
			commit('setMediaLibraryTimeoutMessage', error_message);
		}else{
			commit('setSuccessfullMessage', "The file was uploaded successfully.");
		}
		store.dispatch('loadInitialData');
	},
	async deleteMediaLibraryFile({commit}, args){
		const response = await MediaLibraryAPI.deleteMediaLibraryFile(args.media_file);
		if(response){
			commit('setMediaLibraryTimeoutMessage', response.error_message);
			return response;
		}else{
			commit('setSuccessfullMessage', "The file was successfully removed.");
			store.dispatch('loadInitialData');
		}
	},
	async editFileInMediaLibrary({commit}, args){
		const response = await MediaLibraryAPI.editMediaLibraryFile(args);
		if(response){
			let error_message = response.error_message
			if (error_message.length > 250){
				error_message = 'There was a problem with the file. Please contact your administrator.'
			}
			commit('setMediaLibraryTimeoutMessage', error_message);
		}else{
			commit('setSuccessfullMessage', "The file was successfully edited.");
			store.dispatch('loadInitialData');
		}
	},
	pushAttachmentsInArray({commit}, args){
		state.attachments.push(args);
		commit('setAttachments', state.attachments);
	},
	pushMediaFileInArray({commit}, args){
		state.media_files_array.push(args)
		commit('setMediaFiles', state.media_files_array);
	},
	removeAttachmentFromArray({commit}, args){
		const index = state.attachments.indexOf(args);
		if(index > -1){
			state.attachments.splice(index, 1);
		}
		commit('setAttachments', state.attachments);
	},
	removeMediaFileFromArray({commit}, args){
		const index = state.media_files_array.indexOf(args);
		if(index > -1){
			state.media_files_array.splice(index, 1);
		}
		commit('setMediaFiles', state.media_files_array);
	},
	async updateSelectedCg({commit}, val){
		commit('setSelectedCg', val)
	},
	async updateSelectedCgIndex({commit}, val){
		commit('setSelectedCgIndex', val)
	}
};

const mutations = {
	setMediaLibraryData: (state, media_library_data) => {state.media_library_data = media_library_data},
	setMediaLibraryTimeoutMessage: (state, timeout_message_media_library) => state.timeout_message_media_library = timeout_message_media_library,
	setSuccessfullMessage: (state, successfull_message) => state.successfull_message = successfull_message,
	setPublicFiles: (state, public_files) => state.public_files = public_files,
	setOrderBy: (state, order_by) => state.order_by = order_by,
	setIsDescending: (state, is_descending) => state.is_descending = is_descending,
	setAttachments: (state, attachments) => state.attachments = attachments,
	setMediaFiles: (state, media_files_array) => state.media_files_array = media_files_array,
	setSelectedCg: (state, selected_cg) => state.selected_cg = selected_cg,
	setSelectedCgIndex: (state, selected_cg_index) => state.selected_cg_index = selected_cg_index
};

export default {state, getters, actions, mutations}
