<template>
	<q-item
		clickable
		:to="{ name: route_name }"
		v-bind="$attrs"
		:exact="true"
		:active="active"
		:class="{'incident-layout-drawer-item': is_incident_layout_drawer_item}"
		:active-class="is_incident_layout_drawer_item ? 'active_incident_layout_drawer_item' : null"
	>
		<q-item-section avatar>
			<q-avatar :icon="itemIcon" />
		</q-item-section>
		<q-item-section class="text-left">{{ label }}</q-item-section>
	</q-item>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiMicrosoftTeams } from "@quasar/extras/mdi-v5";
import { fabSlack } from "@quasar/extras/fontawesome-v5";

export default {
	name: 'LayoutDrawerItem',
	props: {
		label: String,
		icon: String,
		route_name: String,
		match_exact_for_active: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters([
			'module_name_settings',
		]),
		is_incident_layout_drawer_item() {
			return this.label.includes(this.module_name_settings.module_name_plural) ||
				this.label.includes(this.module_name_settings.module_name)
		},
		active() {
			const children = this.$router.options.routes.filter(
				(route) => route.name === this.route_name
			)[0].children
			const current_route = this.$route
			const item_route = this.$router.resolve({
				name: this.route_name,
			}).resolved
			// Check if the current route is an exact match of the item route
			if (current_route.fullpath === item_route.fullPath) {
				return true
			}
			// Check if the current route is a child of the item route
			if (children) {
				let matched = false
				children.forEach((child) => {
					if (child.name === current_route.name) {
						matched = true
					}
				})
				if (matched) {
					return true
				}
			}
			// Check if it's the current route is a sub route that's not a child of the item route
			if (!this.match_exact_for_active) {
				return current_route.fullPath.includes(item_route.fullPath)
			}
			// Nothing was matched
			return false
		},
		itemIcon(){
			if (this.icon === 'mdiMicrosoftTeams'){
				return mdiMicrosoftTeams
			}
			if (this.icon === 'fabSlack'){
				return fabSlack
			}
			return this.icon
		}
	},
}
</script>

<style>
.incident-layout-drawer-item {
	background-color: var(--ac-incident-pale-color) !important;
}
.active_incident_layout_drawer_item {
	background-color: var(--ac-incident-highlight-color) !important;
	color: #fff !important;
}
</style>
