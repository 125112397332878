import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth.js';
import forms from './modules/forms.js';
import rules from './modules/rules.js';
import incidents from './modules/incidents.js';
import invoke_qr from './modules/invoke_qr_errors.js'
import branding from './modules/branding.js'
import dispatches from './modules/dispatches.js';
import media_library from './modules/media_library.js';
import reports from './modules/reports.js';
import sidebar from './modules/sidebar.js';
import message_forms from './modules/message_forms.js';
import moment from 'moment-timezone';
import authAPI from '@/services/api/auth.js';
import activity_log from './modules/activity_log.js';
import datasets from './modules/datasets/datasets.js';
import data from './modules/datasets/data.js';
import devices from './modules/datasets/devices.js';
import settings from './modules/datasets/settings.js';
import audit from './modules/datasets/audit.js';
import sftp from './modules/datasets/sftp.js';
import conference from './modules/datasets/conference.js';
import on_call from './modules/datasets/on_call.js';
import registration from './modules/datasets/registration.js';
import merge_fields from './modules/datasets/merge_fields.js';
import fields from './modules/datasets/fields.js'
import users from './modules/users/users.js'

import public_registration from './modules/public_registration.js';


Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth,
		forms,
		rules,
		incidents,
		dispatches,
		media_library,
		reports,
		sidebar,
		message_forms,
		activity_log,
		public_registration,
		branding,
		datasets,
		data,
		devices,
		settings,
		audit,
		sftp,
		conference,
		on_call,
		registration,
		merge_fields,
		invoke_qr,
		fields,
		users,
	},
	data () {
		return{
			logged_user_last_activity: '',
			token_expiry_time: '',
		}
	},
	state: {
		is_menu_collapsed: false,
		incident_template_subdomain: false,
		dialog_options: {
			show_dialog: false,
			dialog_type: null,
			alert_dialog_options: {
				dialog_message: null,
				dialog_accept_button_text: null,
				dialog_decline_button_text: null,
				dialog_accept_callback: null,
			},
			single_input_dialog_options: {
				dialog_title: null,
				dialog_button_html: null,
				dialog_button_callback: null,
				input_value: null,
				input_label: null
			}
		},
		snackbar_options: {
			'show': false,
			'type': null,
			'default': {
				'text': null
			},
			'error': {
				'text': null,
			}
		},
		registration_template_subdomain: '',
		idle_expiration: '',
	},
	getters: {
		is_menu_collapsed: (state) => state.is_menu_collapsed,
		incident_template_subdomain: (state) => state.incident_template_subdomain,
		dialog_options: (state) => state.dialog_options,
		snackbar_options: (state) => state.snackbar_options,
		registration_template_subdomain: (state) => state.registration_template_subdomain,
		idle_expiration: (state) => state.idle_expiration,
	},
	actions: {
		toggleMenu( {commit} ) {
			commit('toggleMenu')
		},
		setIdleExpiration({ commit }, idle_expiration) {
			commit("updateIdleExpiration", idle_expiration)
		},
		setInactive( {commit} ) {
			if (!this.getters.current_CG.customer_group.should_use_autologout) {
				return;
			}

			this.token_expiry_time = this.getters.token_expiry
			const check_inactive = setInterval(() => {
				const now = new Date();
				const idle_expiration = new Date(this.getters.idle_expiration);
				const remaining_minutes = (idle_expiration - now) / 1000 / 60;

				if (remaining_minutes < 1){

					authAPI.getLoggedUser().then((response) =>{
						this.logged_user_last_activity = response.data.last_activity
					})

					if (this.logged_user_last_activity){
						// Get new user last activity time
						this.logged_user_last_activity = new Date(this.logged_user_last_activity)
					}else{
						// Get initial last activity time
						this.logged_user_last_activity = new Date(this.getters.logged_user.last_activity)
					}

					this.logged_user_last_activity = moment(this.logged_user_last_activity).format()
					let date_now = moment().subtract(this.token_expiry_time, 'seconds').utc().format()

					if (this.logged_user_last_activity <= date_now || this.state.idleVue.isIdle){
						this.dispatch("logout", true);
						clearInterval(this.interval_run);
					}
				}

				if (!this.state.idleVue.isIdle) {
					clearInterval(check_inactive)
				}
			}, 10000)

		},
		changeIncidentTemplate( {commit}, subdomain ){
			commit('changeIncidentTemplate', subdomain)
		},
		updateDialogOptions({commit}, obj) {
			commit('setDialogOptions', obj);
		},
		initAlertDialogOptions({commit}, obj) {
			commit('setAlertDialogOptions', obj);
		},
		initSingleInputDialogOptions({commit}, obj) {
			commit('setSingleInputDialogOptions', obj);
		},
		updateSingleInputDialogValue({commit}, val) {
			commit('setSingleInputDialogValue', val)
		},
		updateSnackbarOptions({ commit }, obj) {
			commit('setNotificationOptions', obj)
		},
		changePublicRegistrationTemplate ( {commit}, subdomain) {
			commit('changePublicRegistrationTemplate', subdomain)
		}
	},
	mutations: {
		toggleMenu: (state) => state.is_menu_collapsed = !state.is_menu_collapsed,
		changeIncidentTemplate: (state, subdomain) => state.incident_template_subdomain = subdomain,
		setDialogOptions: (state, dialog_options) => {
			state.dialog_options = {...state.dialog_options, ...dialog_options}
		},
		setAlertDialogOptions: (state, alert_dialog_options) => state.dialog_options.alert_dialog_options = alert_dialog_options,
		setSingleInputDialogOptions: (state, single_input_dialog_options) => state.dialog_options.single_input_dialog_options = single_input_dialog_options,
		setSingleInputDialogValue: (state, val) => state.dialog_options.single_input_dialog_options.input_value = val,
		setNotificationOptions: (state, snackbar_options) => state.snackbar_options = snackbar_options,
		changePublicRegistrationTemplate: (state, subdomain) => state.registration_template_subdomain = subdomain,
		updateIdleExpiration: (state, idle_expiration) => state.idle_expiration = idle_expiration,
	}
})
