import IncidentsAPI from '@/services/api/Incidents.js';
import lexorder from '@/services/lexorder.js';
import utils from '@/services/utils.js';
import store from '@/store/store.js'
import moment from 'moment-timezone'

const state = {
	incidents: [],
	paginated_incidents: [],
	number_of_pages: 1,
	current_incident: {
		id: '',
		name: '',
		field_groups: [],
		values: [],
		title: '',
		state: ''
	},
	payload: [],
	ignored_field_groups: [],
	mappings_with_errors: [],
	new_incident: {},
	incidents_errors: "",
	file_from_list: {},
	initial_date: {},
};

const getters = {
	incidents: state => state.incidents,
	paginated_incidents: state => state.paginated_incidents,
	number_of_pages: state => state.number_of_pages,
	new_incident: state => state.new_incident,
	is_new_incident: state => Object.entries(state.new_incident).length > 0,
	current_incident: state => state.current_incident,
	payload: state => state.payload,
	ignored_field_groups: state => state.ignored_field_groups,
	prevent_update: state => state.mappings_with_errors.length > 0,
	incidents_errors: (state) => state.incidents_errors,
	file_from_list: (state) => state.file_from_list,
	initial_date: state => state.initial_date,
};

const actions = {
	async listIncidents({ commit }) {
		let incidents = await IncidentsAPI.getIncidents();
		if(!incidents.error){
			incidents = incidents.map(incident => {
				incident.is_open = false;
				return incident;
			});
			commit('setIncidents', incidents);
			commit('closeIncidents');
		}
	},

	async listIncidentsPagination({ commit }, items) {
		let incidents = await IncidentsAPI.getIncidents(items);
		let number_of_pages = 1;

		if (items.items_per_page != '0' && items.items_per_page != 'All') {
			number_of_pages = incidents.number_of_pages;
			incidents = incidents.data.map(incident => {
				return incident;
			});
		}
		commit('setPaginatedIncidents', incidents);
		commit('setTotalPages', number_of_pages);
	},

	async getIncident({ commit }, incident_id) {
		const response = await IncidentsAPI.retrieveIncident(incident_id);
		if(!response.error){
			commit('closeIncidents', incident_id);
			let values = response.field_values
			for (let value of values){
				if (value.field_type == 'DATE' && value.values){
					let date = moment(value.values.split('T')[0], "YYYY-MM-DD")
					value['values'] = date.format('DD/MM/YYYY');
				}
				if (value.field_type == 'TIME' && value.values){
					let time = value.values.split('.')[0]
					value['values'] = time.split('T')[1]
				}
				if (value.field_type == 'DATETIME' && value.values){
					let datetime = value.values.split('.')[0]
					let date = datetime.split('T')[0]
					let time = datetime.split('T')[1]
					let date_formatted = `${date.split('-')[2]}/${date.split('-')[1]}/${date.split('-')[0]}`
					value['values'] = date_formatted + ' ' + time
				}
			}
			commit('setCurrentIncident', {
				id: response.id,
				name: response.name,
				field_groups: response.form_snapshot.field_groups,
				values: response.field_values,
				title: response.title,
				state: response.state,
			});
		}
	},

	async createIncident({ commit }, data) {
		const order = lexorder.generate(state.incidents, true);
		let incident = await IncidentsAPI.createIncident(data, order)
		if(!incident.error){
			incident.is_open = true;
			commit('setNewIncident', incident);
			commit('closeIncidents', incident.id);
			commit('setCurrentIncident', {
				id: incident.id,
				name: incident.name,
				field_groups: incident.form_snapshot.field_groups,
				values: incident.field_values,
				title: incident.title,
				state: incident.state,
				response_data: '',
			});
		}
	},

	async createIncidentFromTemplate({ commit }, form_id) {
		let incident = await IncidentsAPI.createIncidentFromTemplate(form_id);
		if(!incident.error){
			commit('setNewIncident', incident);
			commit('setCurrentIncident', {
				id: incident.id,
				name: incident.name,
				field_groups: incident.form_snapshot.field_groups,
				values: incident.field_values,
				response_data: '',
				title: incident.title,
				state: incident.state,
			});
			incident.is_open = true;
		}
	},

	async updateIncident({ commit }, data_anonimous) {
		let data = {}
		if (state.current_incident.title){
			data =  { payload: state.payload, 'incident_title' : state.current_incident.title }
		}else{
			data = { payload: state.payload, 'incident_title' : data_anonimous['incident_title'] };
		}

		if (state.ignored_field_groups.length) {
			// Excplicitly filter out duplicate fg ids
			data.ignored_field_groups = Array.from(new Set(state.ignored_field_groups));
		}

		// Update the incident. If this is the first update, or from template, create the incident
		let response = null;
		let payload_ids = []
		let message = []
		if (state.payload){
			for (let item of state.payload){
				payload_ids.push(item.field)
			}
		}
		for (let data of state.current_incident.field_groups){
			if (!state.ignored_field_groups.includes(data.id)){
				for (let field of data.field_mappings){
					if (field.is_required == true && !payload_ids.includes(field.field.id)){
						message = {
							'message': 'Please enter a value. This field is required.',
							'fg_title': data.title,
							'for_field': field.field.label,
						}
						commit("setCurrentIncident", {
							id: state.current_incident.id,
							name: state.current_incident.name,
							field_groups: state.current_incident.field_groups,
							values: state.current_incident.values,
							title: state.current_incident.title,
							state: state.current_incident.state,
							error_message: message,
						});
						return
					}
				}
			}
		}
		if (state.current_incident.id) {
			response = await IncidentsAPI.updateIncident(state.current_incident.id, data);
		} else {
			data.order = state.new_incident.order;
			response = !data_anonimous['is_template'] ?
				await IncidentsAPI.startIncident(state.new_incident.form, data) :
				await IncidentsAPI.startIncidentFromTemplate(state.new_incident.form, data);
		}

		if (response.error) {
			commit("setCurrentIncident", {
				id: state.current_incident.id,
				name: state.current_incident.name,
				field_groups: state.current_incident.field_groups,
				values: state.current_incident.values,
				title: state.current_incident.title,
				state: state.current_incident.state,
				error_message: response.data,
			});
		} else {
			commit('closeIncidents', response.id);
			commit("setCurrentIncident", {
				id: response.id,
				name: response.name,
				field_groups: response.form_snapshot.field_groups,
				values: response.field_values,
				title: response.title,
				state: response.state,
				response_data: "The " + store.getters.module_name + " was successfully updated.",
			});
			if (getters.is_new_incident(state)) {
				commit('addNewIncident', response);
			}
			commit('unsetNewIncident');
		}
	},

	async updateIncidentFromTemplate() {
		let data = { payload: state.payload };
		if (state.ignored_field_groups.length) {
			data.ignored_field_groups = state.ignored_field_groups;
		}
		await IncidentsAPI.updateIncidentFromTemplate(state.current_incident.id, data);
	},

	updatePayload({ commit }, { values, field_group, field }) {
		commit('unsetMappingWithErrors', { field, field_group });
		let val = { field, field_group, values };
		let exists = false;

		for (let i = 0; i < state.payload.length; i++) {
			let item = state.payload[i];

			if (item.field == field && item.field_group == field_group) {
				commit('setPayload', { value: values, index: i });
				exists = true;

				//handle empty values
				if (!values) {
					if (!utils.getValue(state.current_incident.values, val.field_group, val.field)) {
						commit('splicePayload', i);
					}
				}
			}
		}

		if (values && !exists) {
			commit('appendPayload', val);
		}
	},
	set_incidents_errors({commit}, error){
		commit('setIncidentsErrors', error)
	},

	set_file_from_list({commit}, file_from_list){
		commit('setFileFromList', file_from_list)
	},
	updateInitialDate({commit}, initial_date){
		commit('setInitialDate', initial_date)
	},
	updateCurrentIncident({commit}){
		commit('unsetCurrentIncident')
	},
	updateNewIncident({commit}){
		commit('unsetNewIncident')
	}
};

const mutations = {
	setIncidents: (state, incidents) => {
		state.incidents = incidents;
	},

	closeIncidents: (state, incident_id) => {
		state.incidents = state.incidents.map(item => {
			item['is_open'] = item.id == incident_id ? true : false;
			return item;
		});
	},

	setPaginatedIncidents: (state, paginated_incidents) => {
		state.paginated_incidents = paginated_incidents;
	},

	setFileFromList: (state, file_from_list) => {
		state.file_from_list = file_from_list;
	},

	setTotalPages: (state, number_of_pages) => {
		state.number_of_pages = number_of_pages;
	},

	setCurrentIncident: (state, incident) => {
		if (incident.id != state.current_incident.id) {
			state.payload = [];
		}
		state.current_incident = incident;
		state.mappings_with_errors = [];
	},

	setNewIncident: (state, incident) => {
		state.new_incident = incident;
		state.mappings_with_errors = [];
	},

	unsetNewIncident: state => state.new_incident = {},

	unsetCurrentIncident: state => state.current_incident = {},

	addNewIncident: (state, incident) => {
		incident.is_open = true;
		state.incidents.unshift(incident);
	},

	appendPayload: (state, value) => {
		state.payload.push(value);
	},

	setPayload: (state, { value, index }) => {
		state.payload[index].values = value;
	},

	splicePayload: (state, index) => {
		state.payload.splice(index, 1);
	},

	setIgnoredFieldGroups: (state, field_group) => {
		state.ignored_field_groups.push(field_group);
		//remove ignored field_group from payload
		state.payload = state.payload.filter(value => value.field_group != field_group);
		// Ignore all mappings with errors if they are from ignored field_groups
		state.mappings_with_errors = state.mappings_with_errors
			.filter(it => it.field_group != field_group);
	},

	unsetIgnoredFieldGroups: (state, field_group) => {
		state.ignored_field_groups = state.ignored_field_groups
			.filter(id => id != field_group);
	},

	setMappingWithErrors: (state, mapping) => {
		// Remove the mapping from the payload
		state.payload = state.payload
			.filter(it => !(it.field_group == mapping.field_group && it.field == mapping.field));
		state.mappings_with_errors.push(mapping);
	},

	unsetMappingWithErrors: (state, mapping) => {
		// Remove the mapping from the list
		state.mappings_with_errors = state.mappings_with_errors
			.filter(it => !(it.field_group == mapping.field_group && it.field == mapping.field));
	},
	setIncidentsErrors: (state, new_val) => {
		state.incidents_errors = new_val
	},
	setInitialDate: (state, data) => {
		state.initial_date = data
	},
};

export default { state, getters, actions, mutations };
