import axios from 'axios';
import utils from '../utils.js';
import store from '@/store/store.js'

export default {
	async uploadEmailAttachment(form_data) {
		try {
			const response = await axios.post(`/api/upload/email_attachment`, form_data, {
				headers: {
					'Content-Type': undefined,
				},
			});
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},
	async uploadSMSAttachment(form_data) {
		try {
			const response = await axios.post(`/api/upload/sms_attachment`, form_data, {
				headers: {
					'Content-Type': undefined,
				},
			});
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},
	async uploadProviderAttachment(form_data) {
		try {
			const response = await axios.post(`/api/upload/provider_attachment/`, form_data, {
				headers: {
					'Content-Type': undefined,
				},
			});
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},
	async upload_file(form_data, name, newval) {
		try {
			const response = await axios.post(`/api/upload/recording/${name} + ''`, form_data, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			await uploadFileOnS3(newval, response.data);
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},
	async getTranslations(keys) {
		try {
			const response = await axios.post(
				`/api/translations`,
				{ keys: keys },
				{
					headers: {
						'Content-Type': 'application/json',
					},
				},
			);
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},
	async getTTSAudio(message) {
		try {
			const response = await axios.post(`/api/ttsaudio/`, message, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},
	async getRecipientsCount(recipients) {
		try {
			const response = await axios.post(`/api/scenario/get_recipients_count/`, recipients, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			return utils.getError(error);
		}
	},
	async saveScenario(scenario_data) {
		try {
			const response = await axios.post(`/api/scenario/`, scenario_data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			return utils.getError(error);
		}
	},
	async scheduleScenario(scenario_data) {
		try {
			const response = await axios.post(`/api/session/`, scenario_data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			return utils.getError(error);
		}
	},
	async saveExistingScenario(id, data) {
		try {
			const response = await axios.put(`/api/scenario/${id}/`, data);
			return response.data;
		} catch (error) {
			return utils.getError(error);
		}
	},
	async getScenario(id) {
		try {
			const response = await axios.get(`/api/scenario/${id}/`);
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},
	async getFileObject(id) {
		try {
			const response = await axios.get(`/api/media_library/file/${id}/?object=true`);
			return response.data;
		} catch (error) {
			return utils.getError(error);
		}
	},

	async getFileObjectByKey(data) {
		try {
			const response = await axios.post(`/api/media_library/file/`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			return utils.getError(error);
		}
	},
	async sendScenarioWithoutSaving(data) {
		try {
			const response = await axios.post(`/api/scenario/send_without_saving/`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			return utils.getError(error);
		}
	},
	async sendExistingScenarioWithoutSaving(id, data) {
		try {
			const response = await axios.post(`/api/scenario/${id}/send_without_saving/`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		} catch (error) {
			return utils.getError(error);
		}
	},
	async getRunResult(task_id) {
		try {
			const response = await axios.get(`/api/scenario/run_result/${task_id}/`);
			return response.data;
		} catch (error) {
			return utils.getError(error);
		}
	},
	async listAudioNoticeboards() {
		try {
			const response = await axios.get(`/api/audio-noticeboards/`);
			return response.data;
		} catch (error) {
			return utils.getError(error);
		}
	},
	async generateInvokeQR(obj){
		try{
			const response = await axios.post(`/api/email_invoke/generate_invoke_qr/`, obj)
			return {'image_data': response.data, 'error': false}
		}catch(error) {
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_invoke_qr_errors', "An error occurred. Please contact your administrator.");
			}else{
				store.dispatch('set_invoke_qr_errors', error.response.data)
			}
			return response;
		}
	},
	async checkIfNumberExist(cg, number){
		try{
			const response = await axios.get(`/api/customer_group/${cg}/validate_phone_invoke_number?phone_number=${number}`)
			return {'validation_message': response.data, 'error': false}
		}catch(error) {
			if(error.response.status==500){
				store.dispatch('set_invoke_qr_errors', "An error occurred. Please contact your administrator.");
			}
			return {'validation_message': error.response.data, 'error': true};
		}
	},
	async sendMessagesByEmail(data){
		try {
			const response = await axios.post('/api/scenario/send_scheduled_messages_email_report/', data);
			return response.data;
		} catch (err) {
			return {
				error: true,
				error_message: error.response.data,
			};
		}
	}

};


function uploadFileOnS3(file, s3Data){
	var xhr = new XMLHttpRequest();
	xhr.open("POST", s3Data.url,true);

	var postData = new FormData();
	for(var key in s3Data.fields){
		postData.append(key, s3Data.fields[key]);
	}

	postData.append('file', file )
	xhr.onreadystatechange = function() {
		if(xhr.readyState === 4){
			if(xhr.status === 200 || xhr.status === 204){
				var status_data = {
					"status": "uploaded"
				}
			}else{
				return {"error" :  "The file can not be uploaded. Please contact your administrator."}
			}
		 }
	};
	xhr.send(postData);
}
