<template>
	<div class="breadcrumbs size-16">
			<router-link :to="{ name: 'Dashboard' }">
				Dashboard
			</router-link>
		<span v-if="schedule_message">
			<span>/</span>
			<router-link :to="{ name: 'Message Calendar' }">
				Message Calendar
			</router-link>
		</span>
		<span v-if="audio_noticeboard">
			<span>/</span>
			<router-link :to="{ name: 'Audio Noticeboard' }">
				Audio Noticeboard
			</router-link>
		</span>
		<span>{{ current_location }}</span>
	</div>
</template>

<script>
export default {
	name: "breadcrumbs",
	data() {
		return {
			current_location: this.$route.name,
			schedule_message: false,
			audio_noticeboard: false,
		};
	},
	watch: {
		$route(to, from) {
			if(to.name !== 'Dashboard'){
				this.current_location = " / " + to.name;
				if(to.name == 'Schedule Message'){
					this.schedule_message = true
				}else{
					this.schedule_message = false
				}
				if(to.name == 'Update Audio Noticeboard' || to.name == 'Create Audio Noticeboard'){
					this.audio_noticeboard = true
				}else{
					this.audio_noticeboard = false
				}
			}else this.current_location = "";
		}
	}
};
</script>

<style>
.breadcrumbs {
	color: rgba(245, 245, 245, 0.75);
	float: left;
	padding: 15px 0 0 30px;
	margin-top: -50px;
}

.breadcrumbs a {
	color: #fff;
	text-decoration: none;
	font-family: 'Poppins', sans-serif;;
}

.breadcrumbs a:focus{
	color: #fff;
}

.breadcrumbs a:hover {
	color: var(--ac-highlight-color);
}

</style>
