<template>
	<div>
		{{ snackbar_options.default.text }}
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'Default',
	computed: {
		...mapGetters(['snackbar_options'])
	},
}
</script>

<style>

</style>