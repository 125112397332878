import axios from 'axios';

export default {
	async getReportDetails(id){
		try {
			const response = await axios.get(`/api/session/${ id }/report-details/`);
			return response.data;
		} catch (error) {
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
			}
	},
	async getRecipientsPagination(args){
		try {
			let url = "";
			let all_filters = "";
			let search_by = ""
			
			for(let i in args.filters){
				let filters = args.filters[i]
				if(filters){
					all_filters += filters.value && !filters.search_by ? filters.filter_by + "=" + encodeURIComponent(filters.value) + "&" : "";
					if(!search_by){
						search_by = filters.value && filters.search_by ? filters.filter_by + "=" + encodeURIComponent(filters.value) + "&" : "";
					}
				}
			}
			url = "/api/session/" + args.session_id + "/recipients/?page_size=" + args.items_per_page + "&page=" +
			args.page_number + '&' + all_filters + search_by;

			const response = await axios.get(url);
			return response.data;
		} catch (error) {
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
			}
	},

	async aggregate(id, filter) {
		try {
			const response = await axios.get(`/api/session/${ id }/recipients/aggregate/${ filter }/`);
			return response.data;
		} catch (error){
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
			}
	},

	async exportDispatchesCsv(id) {
		try {
			const response = await axios.get(`/api/generate_reports/${ id}/`);
			return response.data;
		} catch (error) {
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
			}
	},

	async exportDispatchesXlsx(id) {
		try {
			const response = await axios.get(`/api/generate_xlsx/${id}/`);
			return response.data;
		} catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			}
		}
	},
	async getGeneratedFileUrl(id) {
		try {
			const response = await axios.get(`/api/export_reports/${ id }/`)
			return response.data;
		} catch (error) {
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
			}
	},
	async getDispatchesByRecipient(session_id, recipient_id) {
		try {
			const response = await axios.get(`/api/session/${session_id}/recipient/${recipient_id}/dispatches/`);
			return response.data;
		} catch(error) {
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
		}
	},
	async exportUsersXlsx(session_id, state) {
		try {
			const response = await axios.post(
				`/api/session/${session_id}/xlsx/detailed/?` + state)
				return response.data;
		} catch(error) {
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
		}
	},
	async sendToNoReplies(session_id) {
		try {
			const response = await axios.patch(`/api/session/${session_id}/resend/?reply=No+Response`);
			return response;
		} catch(error) {
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
		}
	},
	async exportDispatchesPdf(session_id, query_param) {
		try {
			const response = await axios.post(`/api/session/${session_id}/pdf/detailed/?` + query_param);
			return response.data;
		} catch(error) {
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
		}
	},
	async getScenarios(params) {
		const flags = !params.flags_string ? '' : `&flags=${params.flags_string}`;
		try {
			const response = await axios.get(`/api/scenario/?include_sessions=${params.session_flags}&include_scheduled=${params.scheduled_flag}&page=${params.page_number}&page_size=${params.page_size}&active_tab=${params.active_tabs}${flags}`);
			return response.data;
		} catch(error) {
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
		}
	},
	async get_conference_recording_url(conference_recording_id) {
		try {
			const response = await axios.get(`/api/session/conference_recording/${conference_recording_id}/`);
			return response.data;
		} catch(error) {
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
		}

	},
	async getRecipientsIds(params) {
		try {
			const response = await axios.get(`/api/session/${params.session_id}/recipients/ids/?` + params.query_params);
			return response.data;
		} catch(error) {
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
		}
	},
	async getDataFields(customer_group_id, session_id) {
		try {
			const response = await axios.get(
				`/api/customer_group/${customer_group_id}/fields/?include_child_fields=true&aggregate_field_options_with_recipient_count=true&session_id=${session_id}&has_operator_access=true`
			);
			return response.data;
		} catch(error){
				return {
					error: true,
					data: error.response.data,
					statusText: error.response.statusText,
					statusCode: error.response.status,
				}
		}
	}
}
