import store from '@/store/store.js';
export default {
	build_available_languages(rcg_permissions){
		let available_languages = [ "English"];
		if(rcg_permissions.includes('Catalan')){
			available_languages.push('Catalan');
		}
		if(rcg_permissions.includes('Czech')){
			available_languages.push('Czech');
		}
		if(rcg_permissions.includes('Danish')){
			available_languages.push('Danish');
		}
		if(rcg_permissions.includes('Dutch')){
			available_languages.push('Dutch');
		}
		if(rcg_permissions.includes('French')){
			available_languages.push('French');
		}
		if(rcg_permissions.includes('German')){
			available_languages.push('German');
		}
		if(rcg_permissions.includes('Greek')){
			available_languages.push('Greek');
		}
		if(rcg_permissions.includes('Icelandic')){
			available_languages.push('Icelandic');
		}
		if(rcg_permissions.includes('Italian')){
			available_languages.push('Italian');
		}
		if(rcg_permissions.includes('Japanese')){
			available_languages.push('Japanese');
		}
		if(rcg_permissions.includes('Norwegian')){
			available_languages.push('Norwegian');
		}
		if(rcg_permissions.includes('Polish')){
			available_languages.push('Polish');
		}
		if(rcg_permissions.includes('Portuguese')){
			available_languages.push('Portuguese');
		}
		if(rcg_permissions.includes('Romanian')){
			available_languages.push('Romanian');
		}
		if(rcg_permissions.includes('Russian')){
			available_languages.push('Russian');
		}
		if(rcg_permissions.includes('Scottish Gaelic')){
			available_languages.push('Scottish Gaelic');
		}
		if(rcg_permissions.includes('Spanish')){
			available_languages.push('Spanish');
		}
		if(rcg_permissions.includes('Swedish')){
			available_languages.push('Swedish');
		}
		if(rcg_permissions.includes('Turkish')){
			available_languages.push('Turkish');
		}
		if(rcg_permissions.includes('US English')){
			available_languages.push('US English');
		}
		if(rcg_permissions.includes('Welsh')){
			available_languages.push('Welsh');
		}
		return available_languages;
	},

	build_people_list(rcg_permissions){
		let result = [];
		if(rcg_permissions.includes('Catalan')){
			result.push({name:'Rita', value:'Female, Catalan', language:"Catalan", provider:"Cereproc"})
		}
		if(rcg_permissions.includes('Czech')){
			result.push({name:'cs-CZ-Standard-A', value:'Female 1, Czech', language:"Czech", provider:"google_tts"})
			result.push({name:'cs-CZ-Wavenet-A', value:'Female 2, Czech', language:"Czech", provider:"google_tts"})
		}
		if(rcg_permissions.includes('Danish')){
			result.push({name:'da-DK-Wavenet-A', value:'Female 1, Danmark', language:"Danish", provider:"google_tts"});
			result.push({name:'da-DK-Standard-A', value:'Female 2, Danmark', language:"Danish", provider:"google_tts"});
		}
		if(rcg_permissions.includes('Dutch')){
			result.push({name:'nl-NL-Wavenet-A', value:'Female 1, Netherlands', language:"Dutch", provider:"google_tts"});
			result.push({name:'nl-NL-Wavenet-B', value:'Male 1, Netherlands', language:"Dutch", provider:"google_tts"});
			result.push({name:'nl-NL-Wavenet-C', value:'Male 2, Netherlands', language:"Dutch", provider:"google_tts"});
			result.push({name:'nl-NL-Wavenet-D', value:'Female 2, Netherlands', language:"Dutch", provider:"google_tts"});
		}
		for(let i in people){
			result.push(people[i])
		}
		if(rcg_permissions.includes('French')){
			result.push({name:'fr-FR-Wavenet-B', value:'Male 1, France', language: "French", provider:"google_tts"});
			result.push({name:'fr-FR-Wavenet-A', value:'Female 1, France', language: "French", provider:"google_tts"});
			result.push({name:'fr-FR-Wavenet-D', value:'Male 2, France', language: "French", provider:"google_tts"});
			result.push({name:'fr-FR-Wavenet-C', value:'Female 2, France', language: "French", provider:"google_tts"});
			result.push({name:'fr-CA-Wavenet-C', value:'Female 3, Canada', language: "French", provider:"google_tts"});
		}
		if(rcg_permissions.includes('German')){
			result.push({name:'de-DE-Wavenet-B', value:'Male 1, Germany', language: "German", provider:"google_tts"});
			result.push({name:'de-DE-Wavenet-A', value:'Female 1, Germany', language: "German", provider:"google_tts"});
			result.push({name:'de-DE-Wavenet-D', value:'Male 2, Germany', language: "German", provider:"google_tts"});
			result.push({name:'de-DE-Wavenet-C', value:'Female 2, Germany', language: "German", provider:"google_tts"});
		}
		if(rcg_permissions.includes('Greek')){
			result.push({name:'el-GR-Standard-A', value:'Female 1, Greek', language:"Greek", provider:"google_tts"})
			result.push({name:'el-GR-Wavenet-A', value:'Female 2, Greek', language:"Greek", provider:"google_tts"})
		}
		if(rcg_permissions.includes('Icelandic')) {
			result.push({name:'Dora', value:'Female, Iceland', language:"Icelandic", provider:"Polly"});
			result.push({name:'Karl', value:'Male, Iceland', language:"Icelandic", provider:"Polly"});
		}
		if(rcg_permissions.includes('Italian')) {
			result.push({name:'it-IT-Wavenet-C', value:'Male 1, Italy', language: "Italian", provider:"google_tts"});
			result.push({name:'it-IT-Wavenet-A', value:'Female 1, Italy', language: "Italian", provider:"google_tts"});
			result.push({name:'it-IT-Wavenet-D', value:'Male 2, Italy', language: "Italian", provider:"google_tts"});
			result.push({name:'it-IT-Wavenet-B', value:'Female 2, Italy', language: "Italian", provider:"google_tts"});
		}
		if(rcg_permissions.includes('Japanese')) {
			result.push({name:'ja-JP-Wavenet-A', value:'Female 1, Japan', language:"Japanese", provider:"google_tts"});
			result.push({name:'ja-JP-Wavenet-C', value:'Male 1, Japan', language:"Japanese", provider:"google_tts"});
		}
		if(rcg_permissions.includes('Norwegian')) {
			result.push({name:'nb-NO-Wavenet-A', value:'Female 1, Norway', language:"Norwegian", provider:"google_tts"});
			result.push({name:'nb-NO-Wavenet-B', value:'Male 1, Norway', language:"Norwegian", provider:"google_tts"});
			result.push({name:'nb-NO-Wavenet-C', value:'Female 2, Norway', language:"Norwegian", provider:"google_tts"});
			result.push({name:'nb-NO-Wavenet-D', value:'Male 2, Norway', language:"Norwegian", provider:"google_tts"});
		}
		if(rcg_permissions.includes('Polish')) {
			result.push({name:'pl-PL-Wavenet-B', value:'Male 1, Poland', language: "Polish", provider:"google_tts"});
			result.push({name:'pl-PL-Wavenet-A', value:'Female 1, Poland', language: "Polish", provider:"google_tts"});
			result.push({name:'pl-PL-Wavenet-C', value:'Male 2, Poland', language: "Polish", provider:"google_tts"});
			result.push({name:'pl-PL-Wavenet-D', value:'Female 2, Poland', language: "Polish", provider:"google_tts"});
		}
		if(rcg_permissions.includes('Portuguese')) {
			result.push({name:'pt-PT-Wavenet-A', value:'Female 1, Portugal', language:"Portuguese", provider:"google_tts"});
			result.push({name:'pt-PT-Wavenet-B', value:'Male 1, Portugal', language:"Portuguese", provider:"google_tts"});
			result.push({name:'pt-PT-Wavenet-C', value:'Male 2, Portugal', language:"Portuguese", provider:"google_tts"});
			result.push({name:'pt-PT-Wavenet-D', value:'Female 2, Portugal', language:"Portuguese", provider:"google_tts"});
			result.push({name:'pt-BR-Wavenet-A', value:'Female 3, Brazil', language:"Portuguese", provider:"google_tts"});
		}
		if(rcg_permissions.includes('Romanian')) {
			result.push({name:'Carmen', value:'Female, Romania', language:"Romanian", provider:"Polly"});
		}
		if(rcg_permissions.includes('Russian')) {
			result.push({name:'ru-RU-Wavenet-A', value:'Female 1, Russia', language:"Russian", provider:"google_tts"});
			result.push({name:'ru-RU-Wavenet-B', value:'Male 1, Russia', language:"Russian", provider:"google_tts"});
			result.push({name:'ru-RU-Wavenet-C', value:'Female 2, Russia', language:"Russian", provider:"google_tts"});
			result.push({name:'ru-RU-Wavenet-D', value:'Male 2, Russia', language:"Russian", provider:"google_tts"});
		}
		if(rcg_permissions.includes('Scottish Gaelic')) {
			result.push({name:'Ceitidh', value:'Female, Scotland', language:"Scottish Gaelic", provider:"Cereproc"});
		}
		if(rcg_permissions.includes('Spanish')) {
			result.push({name:'Enrique', value:'Male 1, Spain', language:"Spanish", provider:"Polly"});
			result.push({name:'Miguel', value:'Male 2, Spain', language:"Spanish", provider:"Polly"});
			result.push({name:'Conchita', value:'Female 2, Spain', language:"Spanish", provider:"Polly"});
			result.push({name:'Penelope', value:'Female 3, Spain', language:"Spanish", provider:"Polly"});
			result.push({name:'Sara', value:'Female 4, Castilian', language:"Spanish", provider:"Cereproc"});
			result.push({name:'Ana', value:'Female 5, Mexico', language:"Spanish", provider:"Cereproc"});
		}
		if(rcg_permissions.includes('Swedish')) {
			result.push({name:'sv-SE-Wavenet-A', value:'Female 1, Sweden', language:"Swedish", provider:"google_tts"});
			result.push({name:'sv-SE-Standard-A', value:'Female 2, Sweden', language:"Swedish", provider:"google_tts"});
		}
		if(rcg_permissions.includes('Turkish')) {
			result.push({name:'tr-TR-Wavenet-A', value:'Female 1, Turkey', language:"Turkish", provider:"google_tts"})
			result.push({name:'tr-TR-Standard-B', value:'Male 1, Turkey', language:"Turkish", provider:"google_tts"})
		}
		if(rcg_permissions.includes('US English')) {
			result.push({name:'Joey', value:'Male 1, USA', language:"US English", provider:"Polly"});
			result.push({name:'Joanna', value:'Female 1, USA', language:"US English", provider:"Polly"});
			result.push({name:'Justin', value:'Male 2, USA', language:"US English", provider:"Polly"});
			result.push({name:'Salli', value:'Female 2, USA', language:"US English", provider:"Polly"});
			result.push({name:'Adam', value:'Male 3, USA', language:"US English", provider:"Cereproc"});
			result.push({name:'Kendra', value:'Female 3, USA', language:"US English", provider:"Polly"});
			result.push({name:'Nathan', value:'Male 4, USA', language:"US English", provider:"Cereproc"});
			result.push({name:'Kimberly', value:'Female 4, USA', language:"US English", provider:"Polly"});
			result.push({name:'Isabella', value:'Female 5, USA', language:"US English", provider:"Cereproc"});
			result.push({name:'Katherine', value:'Female 6, USA', language:"US English", provider:"Cereproc"});
			result.push({name:'Hannah', value:'Female 7, USA', language:"US English", provider:"Cereproc"});
			result.push({name:'Megan', value:'Female 8, USA', language:"US English", provider:"Cereproc"});
		}
		if(rcg_permissions.includes('Welsh')) {
			result.push({name:'Gwyneth', value:'Female, Wales', language:"Welsh", provider:"Polly"});
		}
		return result
	},

	default_people() {
		return people;
	},

	createPreview() {
		let customer_group_settings = JSON.parse(store.getters.current_CG.customer_group.settings)
		let email_subject_preview = "";
		let email_preview = "";
		let sms_preview = "";
		let voice_preview_live = "";
		let voice_preview_amd = "";
		let noticeboard_message_preview = "";
		let teams = "";
		let slack = "";
		let show_noticeboard_tts_preview = false;

		if(store.getters.messages_content) {
			if(store.getters.email_channel_active) {
				email_preview = store.getters.global_message;
			}
			if(store.getters.email_subject_line) {
				email_subject_preview = store.getters.email_subject_line
			}
			if(store.getters.sms_channel_active) {
				sms_preview = store.getters.global_message;
			}
			if(store.getters.voice_channel_active) {
				voice_preview_live = store.getters.global_message;
				if(store.getters.scenario_type === 'conference') {
					if(store.getters.amd_conference) {
						voice_preview_amd = customer_group_settings.conference.default_message_amd;
					}else{
						voice_preview_amd = store.getters.message_text;
					}
					if(store.getters.conference_bridge_intro) {
						voice_preview_live = customer_group_settings.conference.default_message_live;
					}else{
						voice_preview_live = store.getters.global_message;
					}
				} else {
					voice_preview_amd = create_default_amd_message_from_settings(customer_group_settings);
					if(!voice_preview_amd) {
						voice_preview_amd = store.getters.global_message;
					}
				}
			}
			if (store.getters.use_microsoft_teams){
				teams = store.getters.global_message
			}
			if (store.getters.use_slack){
				slack = store.getters.global_message
			}
		}else {
			for(let item in store.getters.devices_message_content) {
				if(store.getters.devices_message_content[item].dispatch_type === 'VOICE') {
					voice_preview_live = store.getters.devices_message_content[item].text_value;
				}else if(store.getters.devices_message_content[item].dispatch_type === 'SMS') {
					sms_preview = store.getters.devices_message_content[item].text_value;
				}else if(store.getters.devices_message_content[item].dispatch_type === 'EMAIL') {
					email_preview = store.getters.devices_message_content[item].text_value;
					email_subject_preview = store.getters.email_subject_line
				}
			}

			voice_preview_amd = create_amd_preview(customer_group_settings);
			if(!voice_preview_amd) {
				voice_preview_amd = voice_preview_live;
			}

			if (store.getters.message_content_teams){
				teams = store.getters.message_content_teams
			}
			if (store.getters.message_content_slack){
				slack = store.getters.message_content_slack
			}
		}

		if(voice_preview_live) {
			voice_preview_live = create_opening_text(voice_preview_live, customer_group_settings);
			if(store.getters.polling_value) {
				let polling_options_voice = store.getters.please_press[store.getters.selected_language];
				voice_preview_live += '. ' + store.getters.please_press[store.getters.selected_language];
				for(let polling_option in store.getters.polling_options) {
					if(store.getters.polling_options[polling_option]['option_digit'] != undefined && store.getters.polling_options[polling_option]['option_text'] != undefined) {
						if(polling_option !=0 && store.getters.polling_options.length-1 == polling_option){
							voice_preview_live += " " + store.getters.or_press[store.getters.selected_language] + " " + store.getters.polling_options[polling_option]['option_digit'] + " " + store.getters.for[store.getters.selected_language] + " " +
							store.getters.polling_options[polling_option]['option_text'] + '.';
							polling_options_voice += " " + store.getters.or_press[store.getters.selected_language] + " " + store.getters.polling_options[polling_option]['option_digit'] + " " + store.getters.for[store.getters.selected_language] + " " +
							store.getters.polling_options[polling_option]['option_text'] + '.';
						} else {
							voice_preview_live += " " + store.getters.polling_options[polling_option]['option_digit'] + " " + store.getters.for[store.getters.selected_language] + " " +
							store.getters.polling_options[polling_option]['option_text'] + ',';
							polling_options_voice += " " + store.getters.polling_options[polling_option]['option_digit'] + " " + store.getters.for[store.getters.selected_language] + " " +
							store.getters.polling_options[polling_option]['option_text'] + '.';
						}
					}
				}

				if (voice_preview_live.charAt(voice_preview_live.length-1) === ',') {
					voice_preview_live = voice_preview_live.substring(0, voice_preview_live.length-1) + '.'
				}

				polling_options_voice = polling_options_voice.replace(polling_options_voice.charAt(polling_options_voice.length-1), '.');
				store.dispatch('updatePollingOptionsVoice', polling_options_voice);
			}

			voice_preview_live = create_closing_text(voice_preview_live, customer_group_settings);
		}

		if(sms_preview) {
			sms_preview = create_opening_text(sms_preview, customer_group_settings);
			if(store.getters.polling_value) {
				sms_preview += create_response_for_sms_and_email();
			}
			sms_preview = create_closing_text(sms_preview, customer_group_settings);

			if(customer_group_settings['test_message_header'] && store.getters.scenario_test && sms_preview != '') {
				if (store.getters.is_subset_scenario && store.getters.current_CG.root_customer_group_permissions.indexOf('Allow Subset') >= 0){
					sms_preview = store.getters.current_CG.customer_group.session_type_name.subset_scenario_name.toUpperCase() + ': ' + sms_preview;
				}else{
					sms_preview = store.getters.current_CG.customer_group.session_type_name.test_scenario_name.toUpperCase() + ': ' + sms_preview;
				}
			}
		}

		if(email_preview) {
			if(store.getters.polling_value) {
				email_preview += create_response_for_sms_and_email();
			}
		}

		if(store.getters.noticeboard_message != undefined &&  store.getters.noticeboard_message != "") {
			noticeboard_message_preview = store.getters.noticeboard_message;
			show_noticeboard_tts_preview = true;
		}

		return {email_subject_preview, email_preview, voice_preview_live, voice_preview_amd, sms_preview, noticeboard_message_preview, show_noticeboard_tts_preview, teams, slack}
	}
}

const people = [
	{ name: 'Jack', value:'Male, London', language: "English"},
	{ name: 'Amy', value:'Female 3, England', language: "English", provider:'Polly'},
	{ name: 'Jess', value:'Female, London', language: "English"},
	{ name: 'Giles', value:'Male, Southern England', language: "English"},
	{ name: 'Lauren', value:'Female, Southern England', language: "English"},
	{ name: 'William', value:'Male, Central England', language: "English"},
	{ name: 'Sarah', value:'Female, Central England', language: "English"},
	{ name: 'Sue', value:'Female, Black Country', language: "English"},
	{ name: 'Caitlin', value:'Female, Ireland', language: "English"},
	{ name: 'Stuart', value:'Male 2, Scotland', language: "English"},
	{ name: 'Heather', value:'Female, Scotland', language: "English"},
	{ name: 'Kirsty', value:'Female 2, Scotland', language: "English"},
	{ name: 'Brian', value:'Male 2, England', language: "English", provider:'Polly'},
	{ name: 'Emma', value:'Female 4, England', language: "English", provider:'Polly'},
	{ name: 'Geraint', value:'Male 3, England', language: "English", provider:'Polly'}
]


function create_polling_options_for_sms_and_email() {
	let preview = '';
	preview = '. ' + store.getters.please_reply[store.getters.selected_language] + ': <br>'
	for(let polling_option in store.getters.polling_options) {
		if(store.getters.polling_options[polling_option]['option_digit'] != undefined && store.getters.polling_options[polling_option]['option_text'] != undefined) {
			preview += store.getters.polling_options[polling_option]['option_digit'] + ' = ' + store.getters.polling_options[polling_option]['option_text'] + '<br>';
		}
	}
	return preview;
}

function create_opening_text(preview, customer_group_settings) {
	if(customer_group_settings.opening_text) {
		return customer_group_settings.opening_text + ' ' + preview;
	}
	return preview;
}

function create_closing_text(preview, customer_group_settings) {
	if(customer_group_settings.closing_text) {
		return preview + ' ' + customer_group_settings.closing_text;
	}
	return preview;
}

function create_response_for_sms_and_email() {
	if(store.getters.sms_two_way_link){
		return '. ' + store.getters.response_button_label[store.getters.selected_language] + ' https://alertcascade.net/r/12345678/aaaaaa/';
	}else {
		return create_polling_options_for_sms_and_email();
	}
}

function create_amd_preview(customer_group_settings) {
	let preview = '';
	preview = create_default_amd_message_from_settings(customer_group_settings);
	if(store.getters.message_text && store.getters.message_text.length) {
		preview = store.getters.message_text;
	}

	return preview;
}

function create_default_amd_message_from_settings(customer_group_settings) {
	if(customer_group_settings.default_amd_message && customer_group_settings.default_amd_message.message) {
		if(customer_group_settings.default_amd_message.message.name) {
			return "Default amd message from settings - " + customer_group_settings.default_amd_message.message.name;
		}else {
			return "Default amd message from settings - " + customer_group_settings.default_amd_message.message;
		}
	}

	return '';
}
