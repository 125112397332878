import axios from "axios";
import store from '@/store/store.js'

export default {
	async retrieveIncidentTemplate(subdomain) {
		try {
			const response = await axios.get(`/api/incidents/get_incident_template/${subdomain}/`);
			store.dispatch('set_template_errors', "");
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_template_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_template_errors', response.data)
			}
			return response
		}
	},
	async listIncidentTemplates() {
		try {
			const response = await axios.get("/api/incidents/incident_template/");
			store.dispatch('set_template_errors', "");
			for(let i in response.data){
				response.data[i].is_open = false;
			}
			return response.data;
		} catch (error) {
			
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_template_errors', "An error occurred. Please contact your administrator.");

				
			}else{
				store.dispatch('set_template_errors', response.data)
			}
			return response
		}
	},

	async createIncidentTemplate(data) {
		try {
			const response = await axios.post("/api/incidents/incident_template/", {data});
			store.dispatch('set_template_errors', "");
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_template_errors', "An error occured. Please contact your administrator.");
			}
			return response
		}
	},
	async deleteIncidentTemplate(id){
		try{
			const response = await axios.delete(`/api/incidents/incident_template/${id}/`)
			store.dispatch('set_template_errors', "");
			return response
		}catch (error){
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_template_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_template_errors', response.data)
			}
			return response
		}
	},
	async updateIncidentTemplate(incident_template){
		try{
			const response = await axios.put(`/api/incidents/incident_template/${incident_template.id}/`, {incident_template})
			store.dispatch('set_template_errors', "");
			return response;
		}catch (error){
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_template_errors', "An error occured. Please contact your administrator.");
			}
			return response
		}
	},
	async getRecaptchaKey(){
		try{
			const response = await axios.get(`/api/registration/public/get_key/`)
			store.dispatch('set_template_errors', "");
			return response.data;
		}catch (error){
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_template_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_template_errors', response.data)
			}
			return response
		}
	}
}