<template>
	<div>
		<div class="profile-icon-wrapper" ref="wrapper_div" @click="toggleMenu">
			<i class="fa fa-user" aria-hidden="true"></i>
			<ul class="dropdown-menu" :class="{ closed: !show_menu }">
				<li
					v-if="
						logged_user.customer_permission !== 'OPERATOR' &&
						logged_user.customer_permission !== 'SENDER'
					"
				>
					<router-link
						:to="{ name: 'Edit User', params: { id: logged_user.id } }"
						@click.native="set_active_item(5)"
					>
						Profile
					</router-link>
					<hr />
				</li>
				<li>
					<router-link
						:to="{ name: 'Change Password' }"
					>
						Change password
					</router-link>
					<hr />
				</li>
				<li>
					<a href="#" @click="logout"><span>Logout</span></a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'ProfileSettings',
	props: {
		customer_group_id: Number,
	},
	data() {
		return {
			show_menu: false,
		}
	},
	methods: {
		...mapActions(['set_active_item']),
		toggleMenu() {
			this.show_menu = !this.show_menu
		},
		logout() {
			this.$emit('logout')
		},
		documentClick(e) {
			let el = this.$refs.wrapper_div
			let target = e.target
			if (el !== target && !el.contains(target)) {
				this.show_menu = false
			}
		},
	},
	computed: {
		...mapGetters(['logged_user']),
	},
	created() {
		document.addEventListener('click', this.documentClick)
	},
	destroyed() {
		document.removeEventListener('click', this.documentClick)
	},
}
</script>

<style scoped>
.profile-icon-wrapper {
	position: relative;
	width: 40px;
	height: 40px;
	margin-top: 6px;
	border-radius: 50%;
	color: var(--ac-incident-profile-icon-color);
	background: var(--ac-incident-profile-icon-container-color);
	cursor: pointer;
}

.closed {
	display: none;
}

i {
	position: absolute;
	margin: 13px -6px;
}

ul.dropdown-menu {
	position: absolute;
	top: 30px;
	right: -10px;
	padding: 0;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

hr {
	margin: 0;
	height: 1px;
	border: none;
	background-color: #ccc;
}

ul li {
	width: 10rem;
	text-align: left;
	list-style: none;
	background: #efefef;
	user-select: none;
}

ul li a {
	display: block;
	padding: 0.5rem 1rem;
	color: black;
	text-decoration: none;
}

ul li:hover {
	background-color: var(--ac-highlight-color);
	color: white;
}

ul li:hover a {
	color: inherit;
}
</style>
