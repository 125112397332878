import axios from 'axios';
import store from '@/store/store.js'


export default {
	async getBranding() {
		try {
			const response = await axios.get(`/api/custom_branding/`)
			return response.data;
		}catch(error) {
			return errorResponse(error)
		}
	},

	async revertBranding() {
		try {
			const response = await axios.patch(`/api/custom_branding/`)
			return response.data;
		}catch(error) {
			return errorResponse(error)
		}
	},

	async updateBranding (obj){
		try{
			const response = await axios.put(`/api/custom_branding/`, obj)
			store.dispatch('updateBrandingErrors', "");
			return response.data
		}	catch(error) {
			return errorResponse(error)
		}
	},

	async deleteBranding(){
		try{
			const response = await axios.delete(`/api/custom_branding/`)
			return response.data
		}	catch(error) {
			return errorResponse(error)
		}
	},
	async generateQRMecard(obj){
		try{
			const response = await axios.post(`/api/custom_branding/me_card/`, obj)
			return response.data
		}	catch(error) {
			return errorResponse(error)
		}
	},
	async generateQRSubdomain(obj){
		try{
			const response = await axios.post(`/api/custom_branding/subdomain/`, obj)
			return response.data
		}	catch(error) {
			return errorResponse(error)
		}
	}
}

function errorResponse(error){
	const response = {
		error: true,
		error_status: error.response.status,
		error_message: error.response.data,
	}
	if(response.error_status==500){
		store.dispatch('updateBrandingErrors', "An error occurred. Please contact your administrator.");
	}else{
		store.dispatch('updateBrandingErrors', response.error_message)
	}
	return response;
}
