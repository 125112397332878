import axios from 'axios';

export default {
	async getActivityLogs(filters) {
		try {
			const response = await axios.get('/api/activity_log/', {params: {filters}});
			return response;
		} catch (err) {
			return {
				error: true,
				error_message: error.response.data,
			};
		}
	},

	async getCustomerGroups(){
		try {
			const response = await axios.get('/api/activity_log/customer_group/');
			return response;
		} catch (err) {
			return {
				error: true,
				error_message: error.response.data,
			};
		}
	},

	async activityLogExportSummary(data){
		try {
			const response = await axios.post('/api/activity_log/export_summary/', data);
			return response.data;
		} catch (err) {
			return {
				error: true,
				error_message: error.response.data,
			};
		}
	},
}