import store from '@/store/store.js'
import MessageFormsAPI from '@/services/api/MessageForm.js';
import message_forms_utils from '@/utils/message_forms.js';
import ScenarioAPI from "@/services/api/scenarios.js";

const state = {
	scenario_type: 'broadcast',
	recipient_types: [],
	devices: [],
	scenario: {"devices": []},
	all_devices_state: [],
	scenario_name: "",
	scenario_message_id: "",
	use_conference_recall: false,
	is_subset_scenario: false,
	messages_content: true,
	device_position: null,
	sequence_time: null,
	is_exclude_recipients: false,
	tts_voice: {},
	selected_language: '',
	is_dashboard: true,
	is_phone_invoke: false,
	is_incident_manager: false,
	is_email_invoke: false,
	recipients_list: [],
	recipient_option: {},
	option_value: "",
	use_noticeboard: false,
	default_noticeboard_message_voice: true,
	noticeboard_number: null,
	noticeboard_name: "",
	noticeboard_edit: false,
	noticeboard_language: {},
	noticeboard_message: "",
	selected_file: [],
	selected_files: [],
	email_subject_line: "",
	is_email_selected: false,
	sms_channel_active: false,
	email_channel_active: false,
	include_links_in_sms: false,
	include_links_in_email: false,
	include_links_in_teams: false,
	include_links_in_slack: false,
	polling_value: false,
	polling_options: [{}],
	global_message: "",
	polling_timeout: {},
	sms_two_way_link: false,
	auto_retry: false,
	auto_retry_minutes: 2,
	selected_digits: [],
	disabled_digit_option: false,
	email_sms_attachment_files: [],
	email_sms_attachments_size: 0,
	voice_channel_active: false,
	use_default_answering_services_content: true,
	use_accounts_default_tts_voice: true,
	message_text: "",
	devices_message_content: [],
	conference_bridge_intro: false,
	noticeboard: true,
	scenario_test: true,
	is_single_criteria: true,
	is_multiple_criteria_all: true,
	is_exclude_recipients_all: true,
	amd_conference: false,
	upload_recording_email: true,
	upload_email_attachment: true,
	upload_sms_attachment: true,
	include_recipient_options: [{}],
	exclude_recipient_options: [{}],
	include_links: [],
	available_sms_field: false,
	available_voice_field: false,
	email_attachment_files: [],
	email_attachments_size: 0,
	teams_attachment_files: [],
	slack_attachment_files: [],
	teams_attachments_size: 0,
	slack_attachments_size: 0,
	sms_attachment_files: [],
	sms_attachments_size: 0,
	sms_attachment_warnings: "",
	email_attachment_warnings: "",
	teams_attachment_warnings: "",
	slack_attachment_warnings: "",
	email_sms_attachment_warnings: "",
	copied_text: "",
	response_button_label: {},
	please_press: {},
	for: {},
	or: {},
	please: {},
	please_reply: {},
	or_press: {},
	press: {},
	send_quick_report: false,
	quick_report_recipients: [],
	people: [],
	select_all_devices: true,
	sms_dispatch_content_value: "",
	email_dispatch_content_value: "",
	voice_dispatch_content_value: "",
	is_session_init_running: false,
	is_schedule_message_running: false,
	is_check_dates_running: false,
	is_audio_noticeboard_running: false,
	message: {},
	errors: {},
	polling_options_voice: "",
	trigger_time: 1,
	triggered_scenario: {},
	trigger_scenario: false,
	removed_media_library_files: [],
	is_duplicate_scenario_name: false,
	response_message: '' ,
	quick_report_recipients_type: null,
	check_holiday: false,
	reschedule_holiday: false,
	check_weekend: false,
	reschedule_weekend: false,
	selected_country: '',
	selected_subdivision: '',
	holiday_list: [],
	use_microsoft_teams: false,
	use_slack: false,
	use_pwa: false,
	selected_teams_webhooks: [],
	selected_slack_webhooks: [],
	message_content_teams: '',
	message_content_slack: '',
};

const getters = {
	scenario_type: (state) => state.scenario_type,
	recipient_types: (state) => state.recipient_types,
	devices: (state) => state.devices,
	scenario: (state) => state.scenario,
	all_devices_state: (state) => state.all_devices_state,
	scenario_test: (state) => state.scenario_test,
	scenario_name: (state) => state.scenario_name,
	scenario_message_id: (state) => state.scenario_message_id,
	use_conference_recall: (state) => state.use_conference_recall,
	is_subset_scenario: (state) => state.is_subset_scenario,
	messages_content: (state) => state.messages_content,
	device_position: (state) => state.device_position,
	sequence_time: (state) => state.sequence_time,
	is_single_criteria: (state) => state.is_single_criteria,
	is_multiple_criteria_all: (state) => state.is_multiple_criteria_all,
	is_exclude_recipients: (state) => state.is_exclude_recipients,
	is_exclude_recipients_all: (state) => state.is_exclude_recipients_all,
	tts_voice: (state) => state.tts_voice,
	selected_language: (state) => state.selected_language,
	is_dashboard: (state) => state.is_dashboard,
	is_phone_invoke: (state) => state.is_phone_invoke,
	is_incident_manager: (state) => state.is_incident_manager,
	is_email_invoke: (state) => state.is_email_invoke,
	recipients_list: (state) => state.recipients_list,
	recipient_option: (state) => state.recipient_option,
	option_value: (state) => state.option_value,
	use_noticeboard: (state) => state.use_noticeboard,
	default_noticeboard_message_voice: (state) => state.default_noticeboard_message_voice,
	noticeboard_number: (state) => state.noticeboard_number,
	noticeboard_name: (state) => state.noticeboard_name,
	noticeboard_edit: (state) => state.noticeboard_edit,
	noticeboard_language: (state) => state.noticeboard_language,
	noticeboard_message: (state) => state.noticeboard_message,
	selected_file: (state) => state.selected_file,
	selected_files: (state) => state.selected_files,
	email_subject_line: (state) => state.email_subject_line,
	is_email_selected: (state) => state.is_email_selected,
	sms_channel_active: (state) => state.sms_channel_active,
	email_channel_active: (state) => state.email_channel_active,
	include_links_in_sms: (state) => state.include_links_in_sms,
	include_links_in_email: (state) => state.include_links_in_email,
	include_links_in_teams: (state) => state.include_links_in_teams,
	include_links_in_slack: (state) => state.include_links_in_slack,
	upload_recording_email: (state) => state.upload_recording_email,
	polling_value: (state) => state.polling_value,
	polling_options: (state) => state.polling_options,
	global_message: (state) => state.global_message,
	polling_timeout: (state) => state.polling_timeout,
	sms_two_way_link: (state) => state.sms_two_way_link,
	auto_retry: (state) => state.auto_retry,
	auto_retry_minutes: (state) => state.auto_retry_minutes,
	selected_digits: (state) => state.selected_digits,
	disabled_digit_option: (state) => state.disabled_digit_option,
	email_sms_attachment_files: (state) => state.email_sms_attachment_files,
	email_sms_attachments_size: (state) => state.email_sms_attachments_size,
	voice_channel_active: (state) => state.voice_channel_active,
	use_default_answering_services_content: (state) => state.use_default_answering_services_content,
	use_accounts_default_tts_voice: (state) => state.use_accounts_default_tts_voice,
	message_text: (state) => state.message_text,
	devices_message_content: (state) => state.devices_message_content,
	include_recipient_options: (state) => state.include_recipient_options,
	exclude_recipient_options: (state) => state.exclude_recipient_options,
	include_links: (state) => state.include_links,
	available_sms_field: (state) => state.available_sms_field,
	available_voice_field: (state) => state.available_voice_field,
	upload_email_attachment: (state) => state.upload_email_attachment,
	upload_sms_attachment: (state) => state.upload_sms_attachment,
	sms_attachment_files: (state) => state.sms_attachment_files,
	email_attachment_files: (state) => state.email_attachment_files,
	email_attachments_size: (state) => state.email_attachments_size,
	teams_attachment_files: (state) => state.teams_attachment_files,
	slack_attachment_files: (state) => state.slack_attachment_files,
	teams_attachments_size: (state) => state.teams_attachments_size,
	slack_attachments_size: (state) => state.slack_attachments_size,
	sms_attachments_size: (state) => state.sms_attachments_size,
	sms_attachment_warnings: (state) => state.sms_attachment_warnings,
	email_attachment_warnings: (state) => state.email_attachment_warnings,
	teams_attachment_warnings: (state) => state.teams_attachment_warnings,
	slack_attachment_warnings: (state) => state.slack_attachment_warnings,
	email_sms_attachment_warnings: (state) => state.email_sms_attachment_warnings,
	copied_text: (state) => state.copied_text,
	response_button_label: (state) => state.response_button_label,
	please_press: (state) => state.please_press,
	for: (state) => state.for,
	or: (state) => state.or,
	please: (state) => state.please,
	please_reply: (state) => state.please_reply,
	or_press: (state) => state.or_press,
	press: (state) => state.press,
	send_quick_report: (state) => state.send_quick_report,
	quick_report_recipients: (state) => state.quick_report_recipients,
	people: (state) => state.people,
	conference_bridge_intro: (state) => state.conference_bridge_intro,
	amd_conference: (state) => state.amd_conference,
	select_all_devices: (state) => state.select_all_devices,
	sms_dispatch_content_value: (state) => state.sms_dispatch_content_value,
	email_dispatch_content_value: (state) => state.email_dispatch_content_value,
	voice_dispatch_content_value: (state) => state.voice_dispatch_content_value,
	is_session_init_running: (state) => state.is_session_init_running,
	is_schedule_message_running: (state) => state.is_schedule_message_running,
	is_check_dates_running: (state) => state.is_check_dates_running,
	is_audio_noticeboard_running: (state) => state.is_audio_noticeboard_running,
	message: (state) => state.message,
	errors: (state) => state.errors,
	tabs_with_errors: (state) => {
		const error_keys = Object.keys(state.errors)

		const tabs = []
		for (const [key, value] of Object.entries(tab_error_mapping)) {
			if (error_keys.some(it => value.includes(it))) {
				tabs.push(key);
			}
		}
		return tabs;
	},
	errors_per_tab: (state) => {
		if (!state.errors) {
			return {};
		}
		const error_messages = {}
		for (let tab_name of Object.keys(tab_error_mapping)) {
			const error_keys = tab_error_mapping[tab_name];
			const errors_list = [];

			error_keys.forEach(key => {
				let errors = state.errors[key]
				if (errors) {
					errors_list.push(...errors)
				}
			});
			if (errors_list) {
				error_messages[tab_name] = errors_list
			}
		}
		return error_messages
	},
	polling_options_voice: (state) => state.polling_options_voice,
	trigger_time: (state) => state.trigger_time,
	triggered_scenario: (state) => state.triggered_scenario,
	trigger_scenario: (state) => state.trigger_scenario,
	removed_media_library_files: (state) => state.removed_media_library_files,
	is_duplicate_scenario_name: (state) => state.is_duplicate_scenario_name,
	response_message: (state) => state.response_message,
	quick_report_recipients_type: (state) => state.quick_report_recipients_type,
	check_holiday: (state) => state.check_holiday,
	reschedule_holiday: (state) => state.reschedule_holiday,
	check_weekend: (state) => state.check_weekend,
	reschedule_weekend: (state) => state.reschedule_weekend,
	selected_country: (state) => state.selected_country,
	selected_subdivision: (state) => state.selected_subdivision,
	holiday_list: (state) => state.holiday_list,
	use_microsoft_teams: (state) => state.use_microsoft_teams,
	use_slack: (state) => state.use_slack,
	use_pwa: (state) => state.use_pwa,
	selected_teams_webhooks: (state) => state.selected_teams_webhooks,
	selected_slack_webhooks: (state) => state.selected_slack_webhooks,
	message_content_teams: (state) => state.message_content_teams,
	message_content_slack: (state) => state.message_content_slack,
};

const actions = {
	updateScenarioType( {commit}, value){
		commit('setScenarioType', value);
	},
	updateScenarioName( {commit}, value){
		commit('setScenarioName', value);
	},
	updateScenarioMessageId( {commit}, value){
		commit('setScenarioMessageId', value);
	},
	async setRecipientTypesAndDevices( {commit} ){
		await store.dispatch('getCustomerGroupIds');
		await store.dispatch('getUsersIds');
		advanced_recipient_option();
	},
	updateDevice( {commit}, device){
		device['position'] = device.position ? device.position : null;
		commit('setDevice', device);
	},
	clearDevices( {commit} ){
		commit('resetScenarioDevices', []);
	},
	updateAllDevicesState( {commit}, obj){
		let all_devices_state = [];
		for(let i=0; i<obj.devices_len;i++){
			all_devices_state.push(obj.state);
		}
		commit('setAllDevicesState', all_devices_state);
	},
	updateOneDeviceState( {commit}, obj){
		let all_devices_state = JSON.parse(JSON.stringify(state.all_devices_state));
		all_devices_state[obj.index] = obj.state;
		commit('setAllDevicesState', all_devices_state);
	},
	removeDevice( {commit}, device){
		let devices = [];
		for(let i in state.scenario.devices){
			if(state.scenario.devices[i].dispatch_type == device.dispatch_type){
				if(state.scenario.devices[i].id != device.id){
					devices.push(state.scenario.devices[i]);
				}
			}else {
				devices.push(state.scenario.devices[i]);
			}
		}
		commit('resetScenarioDevices', devices);
	},
	updateMessagesContent( {commit}, value){
		commit('setMessagesContent', value);
	},
	updateUseConferenceRecall( {commit}, value){
		commit('setUseConferenceRecall', value);
	},
	updateIsSubsetScenario( {commit}, value){
		commit('setIsSubsetScenario', value);
	},
	updateSequenceDevicePosition( {commit}, value){
		let scenario_devices = JSON.parse(JSON.stringify(state.scenario.devices));
		scenario_devices[value.index].position = value.val;
		commit('resetScenarioDevices', scenario_devices);
	},
	updateSequenceTime( {commit}, value){
		commit('setSequenceTime', value);
	},
	updateIsSingleCriteria( {commit}, value){
		commit('setIsSingleCriteria', value);
	},
	updateIsMultipleCriteriaAll( {commit}, value){
		commit('setIsMultipleCriteriaAll', value);
	},
	updateIsExcludeRecipients( {commit}, value){
		commit('setIsExcludeRecipients', value);
	},
	updateIsExcludeRecipientsAll( {commit}, value){
		commit('setIsExcludeRecipientsAll', value);
	},
	updateSelectedLanguage( {commit}, value){
		commit('setSelectedLanguage', value);
	},
	updateTTSVoiceSelected( {commit}, value){
		commit('setTTSVoiceSelected', value);
	},
	updateInvocationMethodIsDashboard( {commit}, value){
		commit('setInvocationMethodIsDashboard', value);
	},
	updateInvocationMethodIsPhoneInvoke( {commit}, value){
		commit('setInvocationMethodIsPhoneInvoke', value);
	},
	updateInvocationMethodIsIncidentManager( {commit}, value){
		commit('setInvocationMethodIsIncidentManager', value);
	},
	updateInvocationMethodIsEmailInvoke( {commit}, value){
		commit('setInvocationMethodIsEmailInvoke', value);
	},
	clearRecipientsList( {commit} ){
		commit('setClearRecipientsList');
	},
	updateUseNoticeboard( {commit}, val){
		commit('setUseNoticeboard', val);
	},
	updateDefaultNoticeboardMessageVoice( {commit}, val){
		commit('setDefaultNoticeboardMessageVoice', val);
	},
	updateNoticeboardNumber( {commit}, val){
		commit('setNoticeboardNumber', val);
	},
	updateNoticeboardName( {commit}, val){
		commit('setNoticeboardName', val);
	},
	updateNoticeboardEdit( {commit}, val){
		commit('setNoticeboardEdit', val);
	},
	updateNoticeboardLanguage( {commit}, val){
		commit('setNoticeboardLanguage', val);
	},
	updateResponseMessage( {commit}, val){
		commit('setResponseMessage', val);
	},
	updateNoticeboardMessage( {commit}, val){
		commit('setNoticeboardMessage', val);
	},
	updateSelectedFile( {commit}, val){
		let selected_file = [];
		if (val.attachments){
			selected_file.push(val);
		}
		commit('setSelectedFile', selected_file);
	},
	updateSelectedFiles( {commit}, val){
		commit('setSelectedFiles', val);
	},
	updateEmailSubjectLine( {commit}, val){
		commit('setEmailSubjectLine', val);
	},
	updateIsEmailSelected( {commit}, val){
		commit('setIsEmailSelected', val);
	},
	updateSMSActiveChannel( {commit}, val){
		commit('setSMSActiveChannel', val);
	},
	updateEmailActiveChannel( {commit}, val){
		commit('setEmailActiveChannel', val);
	},
	updateIncludeLinksInSMS( {commit}, val){
		commit('setIncludeLinksInSMS', val);
	},
	updateIncludeLinksInEmail( {commit}, val){
		commit('setIncludeLinksInEmail', val);
	},
	updateIncludeLinksInTeams( {commit}, val){
		commit('setIncludeLinksInTeams', val);
	},
	updateIncludeLinksInSlack( {commit}, val){
		commit('setIncludeLinksInSlack', val);
	},
	updateUploadRecordingEmail( {commit}, val){
		commit('setUploadRecordingEmail', val);
	},
	updatePollingValue( {commit}, val){
		commit('setUploadPollingValue', val);
	},
	updatePollingOptionText( {commit}, val){
		commit('setPollingOptionText', val);
	},
	updateGlobalMessage( {commit}, val){
		commit('setGlobalMessage', val);
	},
	updatePollingTimeout( {commit}, val){
		commit('setPollingTimeout', val);
	},
	updateSMSTwoWayLink( {commit}, val){
		commit('setSMSTwoWayLink', val);
	},
	updateAutoRetry( {commit}, val){
		commit('setAutoRetry', val);
	},
	updateAutoRetryMinutes( {commit}, val){
		commit('setAutoRetryMinutes', val);
	},
	updateSelectedDigitOption( {commit}, obj){
		let selected_digits = JSON.parse(JSON.stringify(state.selected_digits));
		selected_digits[obj.index] = obj.label;
		commit('setSelectedDigitOption', selected_digits);
	},
	resetSelectedDigits( {commit} ){
		commit('setSelectedDigitOption', []);
	},
	updateDisabledDigitOption( {commit}, val){
		commit('setDisabledDigitOption', val);
	},
	resetPollingOptionValues( {commit} ){
		commit("setPollingOption", [{}]);
	},
	updateEmailSMSAttachmentFiles( {commit}, obj){
		let email_sms_attachments = JSON.parse(JSON.stringify(state.email_sms_attachment_files));
		if(obj.source === 'media_library'){
			if (obj['attachments'][0]){
				email_sms_attachments.push({
					"attachment_name": obj['attachments'][0].media_file['name'],
					"id": obj['attachments'][0].media_file['id'],
					"customer_group": obj['attachments'][0].media_file['customer_group'],
					"attachment_key": obj['attachments'][0].media_file['key'],
					"attachment_source": 'media_library'
				})
			}else {
				email_sms_attachments.push({
					"attachment_name": obj.attachments.name,
					"id": obj.attachments.id,
					"customer_group": obj.attachments.customer_group,
					"attachment_key": obj.attachments.key,
					"attachment_source": 'media_library'
				})
			}
		}else if(obj.source === 'local_device'){
			email_sms_attachments.push({
				'attachment_name': obj.attachments.attachment_name,
				'attachment_key': obj.attachments.attachment_key,
				'attachment_source': 'local_device',
				'attachment_size': obj.attachments.attachment_size
			});
		}

		commit('setEmailSMSAttachmentFiles', email_sms_attachments);
	},
	updateEmailSMSAttachments( {commit}, new_val) {
		commit('setEmailSMSAttachmentFiles', new_val);
	},
	removeEmailSMSAttachment( {commit}, index){
		commit('setRemoveEmailSMSAttachment', index);
	},
	removeAllEmailSMSAttachments( {commit} ){
		commit('setEmailSMSAttachmentFiles', []);
	},
	updateEmailSMSAttachmentsSize( {commit}, val){
		commit('setEmailSMSAttachmentsSize', val);
	},
	updateVoiceActiveChannel( {commit}, val){
		commit('setVoiceActiveChannel', val);
	},
	updateUseDefaultAnsweringServicesContent( {commit}, val){
		commit('setUseDefaultAnsweringServicesContent', val);
	},
	updateUseAccountsDefaultTTSVoice( {commit}, val){
		commit('setUseAccountsDefaultTTSVoice', val);
	},
	updateMessageTextList( {commit}, obj){
		let devices_message_content = JSON.parse(JSON.stringify(state.devices_message_content));
		devices_message_content[obj.index]['text_value'] = obj.text_value;
		commit('setDevicesMessageContent', devices_message_content);
	},
	updateDevicesMessageContent( {commit}, val){
		commit('setDevicesMessageContent', val);
	},
	updateDispatchContentValue({commit}, obj){
		let dispatch_content_value = {
			"SMS": 'setSMSContentMessage',
			"VOICE":'setVoiceContentMessage',
			"EMAIL": "setEmailContentMessage"
		}
		commit(dispatch_content_value[obj.dispatch_type], obj.text_value);
	},
	emptyDevicesMessageContent( {commit} ){
		commit('setEmptyDevicesMessageContent');
	},
	updateAnsweringMachineMessageText( {commit}, val){
		commit('setAnsweringMachineMessageText', val);
	},
	updateIncludeRecipientOptions( {commit}, obj){
		let include_recipient_options = JSON.parse(JSON.stringify(state.include_recipient_options));
		if(!obj){
			include_recipient_options.push({});
		}else{
			include_recipient_options[obj.index]['recipient_type'] = obj.value;
			include_recipient_options[obj.index]['recipient_value'] = [];
		}

		commit('setRecipientOptions', include_recipient_options);
	},
	removeIncludeRecipientOption( {commit}, index){
		let include_recipient_options = JSON.parse(JSON.stringify(state.include_recipient_options));

		include_recipient_options.splice(index, 1);
		commit('setRecipientOptions', include_recipient_options);
	},
	updateIncludeRecipientOptionValue( {commit}, obj){
		let include_recipient_options = JSON.parse(JSON.stringify(state.include_recipient_options));
		include_recipient_options[obj.index]['recipient_value']=obj.value;

		commit('setRecipientOptions', include_recipient_options);
	},
	removeIncludeRecipientOptionValue( {commit}, obj){
		let include_recipient_options = JSON.parse(JSON.stringify(state.include_recipient_options));
		include_recipient_options[obj.index]['recipient_value'].splice(obj.value[0]);

		commit('setRecipientOptions', include_recipient_options);
	},
	updateExcludeRecipientOptions( {commit}, obj){
		let exclude_recipient_options = JSON.parse(JSON.stringify(state.exclude_recipient_options));
		if(!obj){
			exclude_recipient_options.push({});
		}else{
			exclude_recipient_options[obj.index]['recipient_type'] = obj.value;
			exclude_recipient_options[obj.index]['recipient_value'] = [];
		}

		commit('setExcludeRecipientOptions', exclude_recipient_options);
	},
	removeExcludeRecipientOption( {commit}, index){
		let exclude_recipient_options = JSON.parse(JSON.stringify(state.exclude_recipient_options));

		exclude_recipient_options.splice(index, 1);
		commit('setExcludeRecipientOptions', exclude_recipient_options);
	},
	updateExcludeRecipientOptionValue( {commit}, obj){
		let exclude_recipient_options = JSON.parse(JSON.stringify(state.exclude_recipient_options));
		exclude_recipient_options[obj.index]['recipient_value']=obj.value;

		commit('setExcludeRecipientOptions', exclude_recipient_options);
	},
	removeExcludeRecipientOptionValue({commit}, obj){
		let exclude_recipient_options = JSON.parse(JSON.stringify(state.exclude_recipient_options));
		exclude_recipient_options[obj.index]['recipient_value'].splice(obj.value[0]);

		commit('setExcludeRecipientOptions', exclude_recipient_options);
	},
	resetIncludeRecipientsList( {commit} ){
		commit('setRecipientOptions', [{}]);
	},
	resetExcludeRecipientsList( {commit} ){
		commit('setExcludeRecipientOptions', [{}]);
	},
	updateIncludeLinks( {commit}, obj){
		let include_links = JSON.parse(JSON.stringify(state.include_links));
		include_links[obj.index] = obj.value;
		commit('setIncludeLinks', include_links);
	},
	updateAvailableSMSField( {commit}, val){
		commit('setAvailableSMSField', val);
	},
	updateAvailableVoiceField( {commit}, val){
		commit('setAvailableEmailField', val);
	},
	updateUploadEmailAttachment({commit}, val){
		commit('setUploadEmailAttachment', val);
	},
	updateUploadSMSAttachment( {commit}, val){
		commit('setUploadSMSAttachment', val);
	},
	updateSMSAttachmentFiles( {commit}, obj){
		let sms_attachments = JSON.parse(JSON.stringify(state.sms_attachment_files));
		if(obj.source === 'media_library'){
			sms_attachments.push({
				"attachment_name": obj['attachments'][0].media_file['name'],
				"id": obj['attachments'][0].media_file['id'],
				"customer_group": obj['attachments'][0].media_file['customer_group'],
				"attachment_key": obj['attachments'][0].media_file['key'],
				"attachment_source": obj.source
			})
		}else if(obj.source === 'local_device'){
			sms_attachments.push({
				'attachment_name': obj.attachments.attachment_name,
				'attachment_key': obj.attachments.attachment_key,
				'attachment_source': 'local_device',
				'attachment_size': obj.attachments.attachment_size
			});
		}

		commit('setSMSAttachmentFiles', sms_attachments);
	},
	updateEmailAttachmentFiles( {commit}, obj){
		let email_attachments = JSON.parse(JSON.stringify(state.email_attachment_files));
		if(obj.source === 'media_library'){
			email_attachments.push({
				"attachment_name": obj['attachments'][0].media_file['name'],
				"id": obj['attachments'][0].media_file['id'],
				"customer_group": obj['attachments'][0].media_file['customer_group'],
				"attachment_key": obj['attachments'][0].media_file['key'],
				"attachment_source": obj.source
			})
		}else if(obj.source === 'local_device'){
			email_attachments.push({
				'attachment_name': obj.attachments.attachment_name,
				'attachment_key': obj.attachments.attachment_key,
				'attachment_source': 'local_device',
				'attachment_size': obj.attachments.attachment_size
			});
		}

		commit('setEmailAttachmentFiles', email_attachments);
	},
	updateTeamsAttachmentFiles( {commit}, obj){
		let teams_attachments = JSON.parse(JSON.stringify(state.teams_attachment_files));
		if(obj.source === 'media_library'){
			teams_attachments.push({
				"attachment_name": obj['attachments'][0].media_file['name'],
				"id": obj['attachments'][0].media_file['id'],
				"customer_group": obj['attachments'][0].media_file['customer_group'],
				"attachment_key": obj['attachments'][0].media_file['key'],
				"attachment_source": obj.source
			})
		}else if(obj.source === 'local_device'){
			teams_attachments.push({
				'attachment_name': obj.attachments.attachment_name,
				'attachment_key': obj.attachments.attachment_key,
				'attachment_source': 'local_device',
				'attachment_size': obj.attachments.attachment_size
			});
		}
		commit('setTeamsAttachmentFiles', teams_attachments);
	},

	updateSlackAttachmentFiles( {commit}, obj){
		let slack_attachments = JSON.parse(JSON.stringify(state.slack_attachment_files));
		if(obj.source === 'media_library'){
			slack_attachments.push({
				"attachment_name": obj['attachments'][0].media_file['name'],
				"id": obj['attachments'][0].media_file['id'],
				"customer_group": obj['attachments'][0].media_file['customer_group'],
				"attachment_key": obj['attachments'][0].media_file['key'],
				"attachment_source": obj.source
			})
		}else if(obj.source === 'local_device'){
			slack_attachments.push({
				'attachment_name': obj.attachments.attachment_name,
				'attachment_key': obj.attachments.attachment_key,
				'attachment_source': 'local_device',
				'attachment_size': obj.attachments.attachment_size
			});
		}
		commit('setSlackAttachmentFiles', slack_attachments);
	},

	removeEmailAttachment( {commit}, index){
		commit('setRemoveEmailAttachment', index);
	},
	removeTeamsAttachment( {commit}, index){
		commit('setRemoveTeamsAttachment', index);
	},
	removeSlackAttachment( {commit}, index){
		commit('setRemoveSlackAttachment', index);
	},
	removeSMSAttachment( {commit}, index){
		commit('setRemoveSMSAttachment', index);
	},
	removeAllEmailAttachments( {commit} ){
		commit('setEmailAttachmentFiles', []);
	},
	removeAllTeamsAttachments( {commit} ){
		commit('setTeamsAttachmentFiles', []);
	},
	removeAllSlackAttachments( {commit} ){
		commit('setSlackAttachmentFiles', []);
	},
	removeAllSMSAttachments( {commit} ){
		commit('setSMSAttachmentFiles', []);
	},
	updateEmailAttachmentsSize( {commit}, val){
		commit('setEmailAttachmentsSize', val);
	},
	updateSMSAttachmentsSize( {commit}, val){
		commit('setSMSAttachmentsSize', val);
	},
	updateTeamsAttachmentsSize( {commit}, val){
		commit('setTeamsAttachmentsSize', val);
	},
	updateSlackAttachmentsSize( {commit}, val){
		commit('setSlackAttachmentsSize', val);
	},
	updateEmailSMSAttachmentWarnings( {commit}, val){
		commit('setEmailSMSAttachmentWarnings', val);
	},
	updateEmailAttachmentWarnings( {commit}, val){
		commit('setEmailAttachmentWarnings', val);
	},
	updateTeamsAttachmentWarnings( {commit}, val){
		commit('setTeamsAttachmentWarnings', val);
	},
	updateSlackAttachmentWarnings( {commit}, val){
		commit('setSlackAttachmentWarnings', val);
	},
	updateSMSAttachmentWarnings( {commit}, val){
		commit('setSMSAttachmentWarnings', val);
	},
	removeEmailSMSAttachmentSize({commit}, val){
		commit('removeEmailSMSSize', val);
	},
	removeEmailAttachmentSize({commit}, val){
		commit('removeEmailSize', val);
	},
	removeTeamsAttachmentSize({commit}, val){
		commit('removeTeamsSize', val);
	},
	removeSlackAttachmentSize({commit}, val){
		commit('removeSlackSize', val);
	},
	removeSMSAttachmentSize({commit}, val){
		commit('removeSMSSize', val);
	},
	updateCopiedText( {commit}, val){
		commit('setCopiedText', val);
	},
	async getTranslations({commit}, keys){
		const response = await MessageFormsAPI.getTranslations(keys);

		for(let key in response){
			state[key] = response[key]
		}
	},
	updatePollingOption( {commit}, obj){
		let polling_options = JSON.parse(JSON.stringify(state.polling_options));
		if(!obj){
			polling_options.push({});
		}else{
			if(obj.option_digit){
				polling_options[obj.index]['option_digit'] = obj.val;
			}
			else if(obj.option_text){
				polling_options[obj.index]['option_text'] = obj.val;
			}
		}
		commit("setPollingOption", polling_options);
	},
	removePollingOption( {commit}, index){
		let polling_options = JSON.parse(JSON.stringify(state.polling_options));

		polling_options.splice(index, 1);
		commit('setPollingOption', polling_options);
	},
	updateSendQuickReport( {commit}, value){
		commit('setSendQuickReport', value);
	},
	updateQuickReportRecipients({commit}, value){
		let quick_report_recipients = value

		commit('setQuickReportRecipients', quick_report_recipients);
	},
	updatePeopleList( {commit} ){
		let people = message_forms_utils.build_people_list(store.getters.current_CG.root_customer_group_permissions);

		commit('setPeopleList', people);
	},
	updateCongerenceBridgeIntro( {commit}, val){
		commit('setCongerenceBridgeIntro', val);
	},
	updateAMDConference( {commit}, val){
		commit('setAMDConference', val);
	},
	updateSelectAllDevices( {commit}, val){
		commit('setSelectAllDevices', val);
	},
	updateScenarioTest( {commit}, val){
		if (!val){
			commit('setIsSubsetScenario', false);
		}
		commit('setScenarioTest', val);
	},
	updateIncludedRecipients( {commit}, obj){
		commit('setRecipientOptions', obj);
	},
	updateExcludedRecipients( {commit}, obj){
		commit('setExcludeRecipientOptions', obj);
	},
	updatePollingOptions( {commit}, obj){
		commit('setPollingOption', obj);
	},
	updateDevices({commit}, val){
		commit('setSelectedDevices', val);
	},
	setSMSAttachments( {commit}, val){
		commit('setSMSAttachmentFiles', val);
	},
	setEmailAttachments( {commit}, val){
		commit('setEmailAttachmentFiles', val);
	},
	setTeamsAttachments( {commit}, val){
		commit('setTeamsAttachmentFiles', val);
	},
	setSlackAttachments( {commit}, val){
		commit('setSlackAttachmentFiles', val);
	},
	updateIsSessionInitRunning( {commit}, val){
		commit('setIsSessionInitRunning', val);
	},
	updateIsScheduleMessageRunning( {commit}, val){
		commit('setIsScheduleMessageRunning', val);
	},
	updateIsCheckDatesRunning( {commit}, val){
		commit('setIsCheckDatesRunning', val);
	},
	updateIsAudioNoticeboardRunning( {commit}, val){
		commit('setIsAudioNoticeboardRunning', val);
	},
	updateMessage({ commit }, val) {
		if (val.type == 'success') {
			commit('setErrors', {})
		}
		commit('setMessage', val)
	},
	updateErrors({ commit }, val) {
		commit('setErrors', val);
	},
	resetFields( {commit} ){
		commit('setSelectedLanguage', "");
		commit('setInvocationMethodIsDashboard', true);
		commit('setInvocationMethodIsPhoneInvoke', false);
		commit('setInvocationMethodIsIncidentManager', false);
		commit('setScenarioTest', true);
		commit('setIsSubsetScenario', false);
		commit('setScenarioType', 'broadcast');
		commit('setScenarioName', "");
		commit('setScenarioMessageId', "");
		commit('setIsSingleCriteria', true);
		commit('setRecipientOptions', [{}]);
		commit('setIsMultipleCriteriaAll', true);
		commit('setIsExcludeRecipients', false);
		commit('setIsExcludeRecipientsAll', true);
		commit('setExcludeRecipientOptions', [{}]);
		commit('setMessagesContent', true);
		commit('setGlobalMessage', "");
		commit('setUploadPollingValue', false);
		commit('setPollingOption', [{}]);
		commit('setSelectedDigitOption', []);
		commit('setPollingTimeout', {});
		commit('setSMSTwoWayLink', false);
		commit('setIncludeLinksInSMS', false);
		commit('setIncludeLinksInEmail', false);
		commit('setEmailSMSAttachmentFiles', []);
		commit('setEmailSMSAttachmentsSize', 0);
		commit('setUseNoticeboard', false);
		commit('setNoticeboardNumber', {});
		commit('setNoticeboardName', "");
		commit('setNoticeboardMessage', "");
		commit('setNoticeboardLanguage', {});
		commit('setDefaultNoticeboardMessageVoice', true);
		commit('setSelectedDevices', []);
		commit('setAllDevicesState', []);
		commit('setEmailSubjectLine', "");
		commit('setIsEmailSelected', false);
		commit('setAllDevicesState', []);
		commit('setSMSContentMessage', "");
		commit('setVoiceContentMessage', "");
		commit('setEmailContentMessage', "");
		commit('setUseDefaultAnsweringServicesContent', true);
		commit('setAnsweringMachineMessageText', "");
		commit('setTTSVoiceSelected', {});
		commit('setUseAccountsDefaultTTSVoice', true);
		commit('setSMSAttachmentFiles', []);
		commit('setSMSAttachmentsSize', 0);
		commit('setEmailAttachmentFiles', []);
		commit('setEmailAttachmentsSize', 0);
		commit('setTeamsAttachmentFiles', []);
		commit('setSlackAttachmentFiles', []);
		commit('setTeamsAttachmentsSize', 0);
		commit('setSlackAttachmentsSize', 0);
		commit('setSequenceTime', null);
		commit('setCongerenceBridgeIntro', false);
		commit('setAMDConference', false);
		commit('setTriggerTime', 0);
		commit('setTriggeredScenario', {});
		commit('setTriggerScenario', false);
		commit('setRemovedMediaLibraryFiles', [])
		commit('setAutoRetry', false);
		commit('setAutoRetryMinutes', 2);
		commit('setIsDuplicateScenarioName', false)
		commit('setResponseMessage','')
		commit('setQuickReportRecipientsType', null)
		commit('setQuickReportRecipients', [])
		commit('setSendQuickReport', false)
		commit('setUseMicrosoftTeams', false)
		commit('setUseSlack', false)
		commit('setUsePWA', false)
		commit('setSelectedTeamsWebhooks', [])
		commit('setSelectedSlackWebhooks', [])
		commit('setMessageContentTeams', '')
		commit('setMessageContentSlack', '')
		commit('setSelectAllDevices', true)

	},
	updatePollingOptionsVoice( {commit}, val) {
		commit('setPollingOptionsVoice', val);
	},
	updateTriggerTime( {commit}, val) {
		commit('setTriggerTime', val);
	},
	updateTriggeredScenario( {commit}, val) {
		commit('setTriggeredScenario', val);
	},
	updateTriggerScenario( {commit}, val) {
		commit('setTriggerScenario', val);
	},
	updateRemovedMediaLibraryFiles( {commit}, val) {
		let removed_media_library_files = JSON.parse(JSON.stringify(state.removed_media_library_files));
		removed_media_library_files.push(val);
		commit('setRemovedMediaLibraryFiles', removed_media_library_files);
	},
	async validateScenarioName({ commit }, scenario_id){
		const is_real = !state.scenario_test
		try{
			let response = await ScenarioAPI.checkScenarioName(state.scenario_name, scenario_id, is_real)
			commit('setResponseMessage', response)
			commit('setIsDuplicateScenarioName', false)
		}catch(error){
			commit('setResponseMessage', error.data)
			commit('setIsDuplicateScenarioName', true)
		}
	},
	updateQuickReportRecipientsType({commit}, val) {
		commit('setQuickReportRecipientsType', val)
	},
	updateCheckHoliday({commit}, val) {
		commit('setCheckHoliday', val)
	},
	updateRecheduleHoliday({commit}, val) {
		commit('setRescheduleHoliday', val)
	},
	updateCheckWeekend({commit}, val) {
		commit('setCheckWeekend', val)
	},
	updateRescheduleWeekend({commit}, val) {
		commit('setRescheduleWeekend', val)
	},
	updateSelectedCountry({commit}, val) {
		commit('setSelectedCountry', val)
	},
	updateSelectedSubdivision({commit}, val) {
		commit('setSelectedSubdivision', val)
	},
	updateHolidayList({commit}, val) {
		commit('setHolidayList', val)
	},
	updateSelectedDevices({commit}){
		store.dispatch('updateSelectAllDevices', false);
		let is_all_devices_selected = true;
		for(let i in store.getters.all_devices_state){
			if(is_all_devices_selected){
				is_all_devices_selected = is_all_devices_selected && store.getters.all_devices_state[i];
			}else{
				break;
			}
		}
		store.dispatch('updateSelectAllDevices', is_all_devices_selected);
		store.dispatch('checkForAttachmentSliders');
	},
	checkForAttachmentSliders({commit}){
		store.dispatch('updateSMSActiveChannel', false);
		store.dispatch('updateEmailActiveChannel', false);
		store.dispatch('updateVoiceActiveChannel', false);
		store.dispatch('emptyDevicesMessageContent');
		for(let device in store.getters.scenario.devices){
			if(store.getters.scenario.devices[device].dispatch_type === 'SMS'){
				store.dispatch('updateSMSActiveChannel', true);
			}
			if(store.getters.scenario.devices[device].dispatch_type === 'EMAIL'){
				store.dispatch('updateEmailActiveChannel', true);
			}
			if(store.getters.scenario.devices[device].dispatch_type === 'VOICE'){
				let customer_group_settings ={}
				store.dispatch('updateVoiceActiveChannel', true);
				if (store.getters.current_CG.customer_group.settings == null || store.getters.current_CG.customer_group.settings == undefined) {
					customer_group_settings = {};
				} else {
					customer_group_settings = JSON.parse(store.getters.current_CG.customer_group.settings);
				}
				let tts_voice = 'Amy';

				if (!store.getters.tts_voice || !Object.keys(store.getters.tts_voice).length) {
					if (customer_group_settings['default_tts_voice']) {
						tts_voice = customer_group_settings['default_tts_voice']['name'];
					}
				}
				else {
					tts_voice = store.getters.tts_voice.name;
				}

				for (let item in store.getters.people) {
					if (store.getters.people[item]['name'] === tts_voice) {
						store.dispatch('updateTTSVoiceSelected', store.getters.people[item]);
						break;
					}
				}
			}
		}
		if(!store.getters.sms_channel_active){
			store.dispatch('updateIncludeLinksInSMS', false);
			store.dispatch('removeAllSMSAttachments');
			store.dispatch('updateSMSTwoWayLink', false);
		}
		if(!store.getters.email_channel_active){
			store.dispatch('updateIncludeLinksInEmail', false);
			store.dispatch('removeAllEmailAttachments');
		}
		if(!store.getters.include_links_in_sms && !store.getters.include_links_in_email){
			store.dispatch('removeAllEmailSMSAttachments');
		}
		if(!store.getters.sms_channel_active && !store.getters.email_channel_active){
			store.dispatch('removeAllEmailSMSAttachments');
		}

		let map_output = new Map();
		if(!store.getters.voice_channel_active){
			store.dispatch('updateAnsweringMachineMessageText', "");
			store.dispatch('updateDispatchContentValue', {"dispatch_type": 'VOICE', "text_value": ""});
		}
		if(store.getters.voice_channel_active){
			map_output.set('VOICE', {title: 'Main voice call content', placeholder: 'This content will be played when we detect a human has answered the voice call', dispatch_type: 'VOICE', text_value: store.getters.voice_dispatch_content_value});
		}
		if(store.getters.sms_channel_active){
			map_output.set('SMS', {title: 'SMS content', placeholder: 'Write your SMS text here', dispatch_type: 'SMS', text_value: store.getters.sms_dispatch_content_value});
		}
		if(store.getters.email_channel_active){
			map_output.set('EMAIL', {title: 'Email content', placeholder: 'Write your email here', dispatch_type: 'EMAIL', text_value: store.getters.email_dispatch_content_value});
		}

		let list_output = [];

		for (let [key, value] of map_output.entries()){
			list_output.push(value);
		}
		store.dispatch('updateDevicesMessageContent', list_output);
	},
	showEmailSubject(){
		store.dispatch('updateIsEmailSelected', store.getters.scenario.devices.some(device => device.dispatch_type == 'EMAIL'))
	},
	updateUseMicrosoftTeams({commit}, val) {
		commit('setUseMicrosoftTeams', val)
	},
	updateUseSlack({commit}, val){
		commit('setUseSlack', val)
	},
	updateUsePWA({commit}, val){
		commit('setUsePWA', val)
	},
	updateSelectedTeamsWebhooks({commit}, val) {
		commit('setSelectedTeamsWebhooks', val)
	},
	updateSelectedSlackWebhooks({commit}, val) {
		commit('setSelectedSlackWebhooks', val)
	},
	updateMessageContentTeams({commit}, val) {
		commit('setMessageContentTeams', val)
	},
	updateMessageContentSlack({commit}, val){
		commit('setMessageContentSlack', val)
	}
};

const mutations = {
	setScenarioType: (state, new_val) => {state.scenario_type = new_val},
	setRecipientTypes: (state, new_val) => {state.recipient_types.push(new_val)},
	setDevice: (state, new_val) => {state.scenario.devices.push(new_val)},
	clearDevices: (state) => {state.scenario.devices = []},
	setAllDevicesState: (state, new_val) => {state.all_devices_state = new_val},
	resetScenarioDevices: (state, new_val) => {state.scenario.devices = new_val},
	setScenarioName: (state, new_val) => {state.scenario_name = new_val},
	setScenarioMessageId: (state, new_val) => {state.scenario_message_id = new_val},
	setUseConferenceRecall: (state, new_val) => {state.use_conference_recall = new_val},
	setIsSubsetScenario: (state, new_val) => {state.is_subset_scenario = new_val},
	setMessagesContent: (state, new_val) => {state.messages_content = new_val},
	setSequenceTime: (state, new_val) => {state.sequence_time = new_val},
	setIsSingleCriteria: (state, new_val) => {state.is_single_criteria = new_val},
	setIsMultipleCriteriaAll: (state, new_val) => {state.is_multiple_criteria_all = new_val},
	setIsExcludeRecipients: (state, new_val) => {state.is_exclude_recipients = new_val},
	setIsExcludeRecipientsAll: (state, new_val) => {state.is_exclude_recipients_all = new_val},
	setSelectedLanguage: (state, new_val) => {state.selected_language = new_val},
	setTTSVoiceSelected: (state, new_val) => {state.tts_voice = new_val},
	setInvocationMethodIsDashboard: (state, new_val) => {state.is_dashboard = new_val},
	setInvocationMethodIsPhoneInvoke: (state, new_val) => {state.is_phone_invoke = new_val},
	setInvocationMethodIsIncidentManager: (state, new_val) => {state.is_incident_manager = new_val},
	setInvocationMethodIsEmailInvoke: (state, new_val) => {state.is_email_invoke = new_val},
	setClearRecipientsList: (state, new_val) => {state.recipients_list = []},
	setUseNoticeboard: (state, new_val) => {state.use_noticeboard = new_val},
	setDefaultNoticeboardMessageVoice: (state, new_val) => state.default_noticeboard_message_voice = new_val,
	setNoticeboardNumber: (state, new_val) => state.noticeboard_number = new_val,
	setNoticeboardName: (state, new_val) => state.noticeboard_name = new_val,
	setNoticeboardEdit: (state, new_val) => state.noticeboard_edit = new_val,
	setNoticeboardLanguage: (state, new_val) => state.noticeboard_language = new_val,
	setNoticeboardMessage: (state, new_val) => state.noticeboard_message = new_val,
	setSelectedFile: (state, new_val) => state.selected_file = new_val,
	setSelectedFiles: (state, new_val) => state.selected_files = new_val,
	setEmailSubjectLine: (state, new_val) => state.email_subject_line = new_val,
	setIsEmailSelected: (state, new_val) => state.is_email_selected = new_val,
	setSMSActiveChannel: (state, new_val) => state.sms_channel_active = new_val,
	setEmailActiveChannel: (state, new_val) => state.email_channel_active = new_val,
	setIncludeLinksInSMS: (state, new_val) => state.include_links_in_sms = new_val,
	setIncludeLinksInEmail: (state, new_val) => state.include_links_in_email = new_val,
	setIncludeLinksInTeams: (state, new_val) => state.include_links_in_teams = new_val,
	setIncludeLinksInSlack: (state, new_val) => state.include_links_in_slack = new_val,
	setUploadRecordingEmail: (state, new_val) => state.upload_recording_email = new_val,
	setUploadPollingValue: (state, new_val) => state.polling_value = new_val,
	setGlobalMessage: (state, new_val) => state.global_message = new_val,
	setPollingTimeout: (state, new_val) => state.polling_timeout = new_val,
	setSMSTwoWayLink: (state, new_val) => state.sms_two_way_link = new_val,
	setAutoRetry: (state, new_val) => state.auto_retry = new_val,
	setAutoRetryMinutes: (state, new_val) => state.auto_retry_minutes = new_val,
	setDisabledDigitOption: (state, new_val) => state.disabled_digit_option = new_val,
	setEmailSMSAttachmentFiles: (state, new_val) => state.email_sms_attachment_files = new_val,
	setRemoveEmailSMSAttachment: (state, new_val) => state.email_sms_attachment_files.splice(new_val, 1),
	setEmailSMSAttachmentsSize: (state, new_val) => state.email_sms_attachments_size+=new_val,
	setVoiceActiveChannel: (state, new_val) => state.voice_channel_active = new_val,
	setUseDefaultAnsweringServicesContent: (state, new_val) => state.use_default_answering_services_content = new_val,
	setUseAccountsDefaultTTSVoice: (state, new_val) => state.use_accounts_default_tts_voice = new_val,
	setDevicesMessageContent: (state, new_val) => state.devices_message_content = new_val,
	setEmptyDevicesMessageContent: (state, new_val) => state.devices_message_content = [],
	setAnsweringMachineMessageText: (state, new_val) => state.message_text = new_val,
	setRecipientOptions: (state, new_val) => state.include_recipient_options = new_val,
	setExcludeRecipientOptions: (state, new_val) => state.exclude_recipient_options = new_val,
	setIncludeLinks: (state, new_val) => state.include_links = new_val,
	setAvailableSMSField: (state, new_val) => state.available_sms_field = new_val,
	setAvailableEmailField: (state, new_val) => state.available_voice_field = new_val,
	setUploadEmailAttachment: (state, new_val) => state.upload_email_attachment = new_val,
	setUploadSMSAttachment: (state, new_val) => state.upload_sms_attachment = new_val,
	setSMSAttachmentFiles: (state, new_val) => state.sms_attachment_files = new_val,
	setEmailAttachmentFiles: (state, new_val) => state.email_attachment_files = new_val,
	setRemoveEmailAttachment: (state, new_val) => state.email_attachment_files.splice(new_val, 1),
	setTeamsAttachmentFiles: (state, new_val) => state.teams_attachment_files = new_val,
	setSlackAttachmentFiles: (state, new_val) => state.slack_attachment_files = new_val,
	setRemoveTeamsAttachment: (state, new_val) => state.teams_attachment_files.splice(new_val, 1),
	setRemoveSlackAttachment: (state, new_val) => state.slack_attachment_files.splice(new_val, 1),
	setRemoveSMSAttachment: (state, new_val) => state.sms_attachment_files.splice(new_val, 1),
	setEmailAttachmentsSize: (state, new_val) => state.email_attachments_size+=new_val,
	setTeamsAttachmentsSize: (state, new_val) => state.teams_attachments_size+=new_val,
	setSlackAttachmentsSize: (state, new_val) => state.slack_attachments_size+=new_val,
	setSMSAttachmentsSize: (state, new_val) => state.sms_attachments_size+=new_val,
	setEmailSMSAttachmentWarnings: (state, new_val) => state.email_sms_attachment_warnings = new_val,
	setEmailAttachmentWarnings: (state, new_val) => state.email_attachment_warnings = new_val,
	setTeamsAttachmentWarnings: (state, new_val) => state.teams_attachment_warnings = new_val,
	setSlackAttachmentWarnings: (state, new_val) => state.slack_attachment_warnings = new_val,
	setSMSAttachmentWarnings: (state, new_val) => state.sms_attachment_warnings = new_val,
	removeEmailSMSSize: (state, new_val) => state.email_sms_attachments_size-=new_val,
	removeEmailSize: (state, new_val) => state.email_attachments_size-=new_val,
	removeTeamsSize: (state, new_val) => state.teams_attachments_size-=new_val,
	removeSlackSize: (state, new_val) => state.slack_attachments_size-=new_val,
	removeSMSSize: (state, new_val) => state.sms_attachments_size-=new_val,
	setCopiedText: (state, new_val) => state.copied_text = new_val,
	setPollingOption: (state, new_val) => state.polling_options = new_val,
	setSendQuickReport: (state, new_val) => state.send_quick_report = new_val,
	setQuickReportRecipients: (state, new_val) => state.quick_report_recipients = new_val,
	setPeopleList: (state, new_val) => state.people = new_val,
	setCongerenceBridgeIntro: (state, new_val) => state.conference_bridge_intro = new_val,
	setAMDConference: (state, new_val) => state.amd_conference = new_val,
	setSelectAllDevices: (state, new_val) => state.select_all_devices = new_val,
	setScenarioTest: (state, new_val) => state.scenario_test = new_val,
	setSMSContentMessage: (state, new_val) => state.sms_dispatch_content_value = new_val,
	setEmailContentMessage: (state, new_val) => state.email_dispatch_content_value = new_val,
	setVoiceContentMessage: (state, new_val) => state.voice_dispatch_content_value = new_val,
	setSelectedDevices: (state, new_val) => state.scenario.devices = new_val,
	resetRecipientTypes: (state) => state.recipient_types = [],
	setDevices: (state, new_val) => state.devices.push(new_val),
	resetDevices: (state) => state.devices = [],
	setIsSessionInitRunning: (state, new_val) => state.is_session_init_running = new_val,
	setIsScheduleMessageRunning: (state, new_val) => state.is_schedule_message_running = new_val,
	setIsCheckDatesRunning: (state, new_val) => state.is_check_dates_running = new_val,
	setIsAudioNoticeboardRunning: (state, new_val) => state.is_audio_noticeboard_running = new_val,
	setSelectedDigitOption: (state, new_val) => state.selected_digits = new_val,
	setMessage: (state, new_val) => state.message = new_val,
	setErrors: (state, new_val) => state.errors = new_val,
	clearErrors: (state) => {
		state.errors = {};
		state.message = {};
	},
	setPollingOptionsVoice: (state, new_val) => state.polling_options_voice = new_val,
	setTriggerTime: (state, new_val) => state.trigger_time = new_val,
	setTriggeredScenario: (state, new_val) => state.triggered_scenario = new_val,
	setTriggerScenario: (state, new_val) => state.trigger_scenario = new_val,
	setRemovedMediaLibraryFiles: (state, new_val) => state.removed_media_library_files = new_val,
	setIsDuplicateScenarioName: (state, new_val) => state.is_duplicate_scenario_name = new_val,
	setResponseMessage: (state, new_val) => state.response_message = new_val,
	setQuickReportRecipientsType: (state, new_val) => state.quick_report_recipients_type = new_val,
	setCheckHoliday: (state, new_val) => state.check_holiday = new_val,
	setRescheduleHoliday: (state, new_val) => state.reschedule_holiday = new_val,
	setCheckWeekend: (state, new_val) => state.check_weekend = new_val,
	setRescheduleWeekend: (state, new_val) => state.reschedule_weekend = new_val,
	setSelectedCountry: (state, new_val) => state.selected_country = new_val,
	setSelectedSubdivision: (state, new_val) => state.selected_subdivision = new_val,
	setHolidayList: (state, new_val) => state.holiday_list = new_val,
	setUseMicrosoftTeams: (state, new_val) => state.use_microsoft_teams = new_val,
	setUseSlack: (state, new_val) => state.use_slack = new_val,
	setUsePWA: (state, new_val) => state.use_pwa = new_val,
	setSelectedTeamsWebhooks: (state, new_val) => state.selected_teams_webhooks = new_val,
	setSelectedSlackWebhooks: (state, new_val) => state.selected_slack_webhooks = new_val,
	setMessageContentTeams: (state, new_val) => state.message_content_teams = new_val,
	setMessageContentSlack: (state, new_val) => state.message_content_slack = new_val,
};

//  ----------------------- HELPER FUNCTIONS -----------------------------
const tab_error_mapping = {
		'Settings': ["name", "message_id", "language"],
		'Recipients': ['recipients'],
		'Devices': ['devices', 'subject_line'],
		'Sequence': ['sequence_settings'],
		'Content': ['message_forms', 'polling'],
		'Noticeboard': ['noticeboard'],
		'Trigger': ['trigger'],
		'Finish': ['message_notification']
}

function advanced_recipient_option(){
	let numeric_fields = [];
	let user = JSON.parse(JSON.stringify(store.getters.logged_user));
	store.commit('resetRecipientTypes');
	store.commit('resetDevices')

	let everyone_data = {
		'id':'everyone',
		'type':'everyone',
		'label':'Everyone',
		'options': [],
	};

	let user_data = {
		'id':'user',
		'type':'user',
		'label':'Individuals',
		'options': store.getters.descendant_user_ids,
	};
	let group_data = {
		'id':'group',
		'type':'group',
		'label':'Datasets',
		'options': store.getters.customer_group_ids,
	};

	store.commit('setRecipientTypes', everyone_data)
	if (store.getters.current_CG.root_customer_group_permissions.indexOf("On Call core platform access") >= 0){
		let on_call_period_data ={
			'id':'on_call',
			'type':'on_call',
			'label':'Currently On Call',
			'options': []
		};
		store.commit('setRecipientTypes', on_call_period_data);
	}

	store.commit('setRecipientTypes', user_data);
	if(store.getters.customer_group_ids.length > 1){
		store.commit('setRecipientTypes', group_data);
	}

	let default_email_field= {};
	default_email_field.label = 'Work Email';
	default_email_field.field_type = 'EMAIL';
	default_email_field.label_plus_type = 'Email (EMAIL)';
	default_email_field.dispatch_type = 'EMAIL';
	default_email_field.heading = 'Email';
	default_email_field.id = 'null';


	if (store.getters.current_CG.root_customer_group_permissions.indexOf("Phone invoke") >= 0){
		let telephony_id_field = {};
		telephony_id_field.label = "Telephony ID"
		telephony_id_field.id = -1;

		let telephony_pin_field = {};
		telephony_pin_field.label = "Telephony PIN";
		telephony_pin_field.id = -2;

		numeric_fields.push(telephony_pin_field);
		numeric_fields.push(telephony_id_field);
	}

	if(store.getters.scenario_type == 'broadcast' || store.getters.scenario_type == 'sequence'){
		store.commit('setDevices', default_email_field);
	}

	for (let field in user.descendant_fields){
		if(user.descendant_fields[field].field_type=='MULTISELECT' || user.descendant_fields[field].field_type == 'SINGLESELECT'){
			user.descendant_fields[field].type="field";
			user.descendant_fields[field].selected_options = []
			store.commit('setRecipientTypes', user.descendant_fields[field]);
		}else if(user.descendant_fields[field].field_type=='PHONE(SMS&VOICE)'){
			store.dispatch('updateAvailableSMSField', true);
			store.dispatch('updateAvailableVoiceField', true);
			let sms_field = user.descendant_fields[field];
			let voice_field = JSON.parse(JSON.stringify(sms_field));
			sms_field.field_label = sms_field.label;
			sms_field.label_plus_type = sms_field.label + ' (SMS)';
			sms_field.field_type = 'PHONE(SMS)';
			sms_field.field_id = sms_field.id;
			sms_field.heading = 'SMS';
			delete sms_field['options'];
			sms_field.dispatch_type = 'SMS';
			voice_field.field_id = voice_field.id;
			voice_field.field_label = voice_field.label;
			voice_field.label_plus_type = voice_field.label + ' (VOICE)';
			voice_field.field_type = 'PHONE(VOICE)';
			voice_field.heading = 'Voice Call';
			delete voice_field['options'];
			voice_field.dispatch_type = 'VOICE';
			if(store.getters.scenario_type != 'conference'){
				store.commit('setDevices', sms_field);
				if (store.getters.current_CG.root_customer_group_permissions.indexOf("Voice") >=1){
					store.commit('setDevices', voice_field);
				}
			}
			else if (store.getters.current_CG.root_customer_group_permissions.indexOf("Conference bridge") >=1){
				store.commit('setDevices', voice_field)
			}
		}
		else if(user.descendant_fields[field].field_type == 'NUMERIC'){
			delete user.descendant_fields[field]['options'];
			numeric_fields.push(user.descendant_fields[field]);
		}
		else if (
			user.descendant_fields[field].field_type != 'TEXT' &&
			user.descendant_fields[field].field_type != 'NUMERIC' &&
			user.descendant_fields[field].field_type != 'POSTCODE'
		) {
			if(user.descendant_fields[field].field_type == 'PHONE(SMS)' ){
				store.dispatch('updateAvailableSMSField', true);
				user.descendant_fields[field].field_label = user.descendant_fields[field].label;
				user.descendant_fields[field].field_id =   user.descendant_fields[field].id;
				user.descendant_fields[field].dispatch_type = 'SMS';
				delete user.descendant_fields[field]['options'];
				user.descendant_fields[field].heading = 'SMS';
				user.descendant_fields[field].label_plus_type = user.descendant_fields[field].label + ' (SMS)';
			}else if(user.descendant_fields[field].field_type == 'PHONE(VOICE)'){
				store.dispatch('updateAvailableVoiceField', true);
				user.descendant_fields[field].field_label = user.descendant_fields[field].label;
				user.descendant_fields[field].field_id =   user.descendant_fields[field].id;
				user.descendant_fields[field].dispatch_type = 'VOICE';
				user.descendant_fields[field].heading = 'Voice Call';
				delete user.descendant_fields[field]['options'];
				user.descendant_fields[field].label = user.descendant_fields[field].label;
				user.descendant_fields[field].label_plus_type = user.descendant_fields[field].label + ' (VOICE)';
			}else if (store.getters.scenario_type == 'broadcast' || store.getters.scenario_type =='sequence'){
				user.descendant_fields[field].field_label = user.descendant_fields[field].label;
				user.descendant_fields[field].field_id =   user.descendant_fields[field].id;
				user.descendant_fields[field].dispatch_type = 'EMAIL';
				delete user.descendant_fields[field]['options'];
				user.descendant_fields[field].heading = 'Email';
				user.descendant_fields[field].label = user.descendant_fields[field].label;
				user.descendant_fields[field].label_plus_type = user.descendant_fields[field].label+" (" +user.descendant_fields[field].field_type+")";
			}
			if(store.getters.scenario_type == 'conference' && user.descendant_fields[field].field_type == 'PHONE(VOICE)'){
				store.commit('setDevices', user.descendant_fields[field]);
			}else if (store.getters.scenario_type == 'broadcast' || store.getters.scenario_type == 'sequence'){
				if(user.descendant_fields[field].field_type == 'PHONE(VOICE)' && store.getters.current_CG.root_customer_group_permissions.indexOf("Voice") <1){
					//pass
				}else{
					store.commit('setDevices', user.descendant_fields[field]);
				}
			}
		}
	}
}

export default {state, getters, actions, mutations}
