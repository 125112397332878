import DatasetsAPI from '@/services/api/Datasets.js'
import store from '@/store/store.js'

const state = {
	conference_settings: null,
}

const getters = {
	conference_settings: (state) => state.conference_settings,
}

const actions = {
	updateConferenceSettings({ commit }, val) {
		commit('setConferenceSettings', val)
	},
	async saveConferenceSettings({ commit }, customer_group_id) {
		const response = await DatasetsAPI.editCustomerGroupConferenceSettings(
			customer_group_id,
			store.getters.conference_settings
		)
		return response
	},
}

const mutations = {
	setConferenceSettings: (state, conference_settings) =>
		(state.conference_settings = conference_settings),
}

export default { state, getters, actions, mutations }
