import axios from 'axios'

export default {
	async getCustomerGroups(){
		try{
			const response = await axios.get('/api/customer_group/');
			return response.data;
		}catch(error){
			const response = {
				error: true,
				error_message: error.response.data
			}
			return response;
		}
	},

	async getData(){
		try{
			let response = await axios.get(`/api/media_library/`);
			return response.data;
		}catch(error){
			const response = {
				error: true,
				error_message: error.response.data
			}
			return response;
		}
	},

	async getFile(id){
		try{
			const response = await axios.get(`/api/media_library/file/${id}/`);
			return response.data;
		}catch(error){
			const response = {
				error: true,
				error_message: error.response.data
			}
			return response;
		}
	},

	async addFileInMediaLibrary(data){
		try{
			const response = await axios.post(
				`/api/media_library/`,
					data,
					{
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					}
			)
			return response;
		}catch(error){
			const response = {
				error: true,
				error_message: error.response.data
			}
			return response;
		}
	},

	async editMediaLibraryFile(data){

		try{
			const response = await axios.post(
				`/api/media_library/file/${data.media_file_id}/`,
				data.data,
				{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
			)
		}catch(error){
			const response = {
				error: true,
				error_message: error.response.data
			}
			return response;
		}
	},

	async deleteMediaLibraryFile(id){
		try{
			const response = await axios.delete(`/api/media_library/file/${id}/`);
		}catch(error){
			const response = {
				error: true,
				error_message: error.response.data
			}
			return response;
		}
	},
	async downloadAllFiles(cg_id) {
		try{
			const response = await axios.get(`/api/media_library/${cg_id}/download_all/`);
			return response
		}catch(error){
			const response = {
				error: true,
				error_message: error.response.data
			}
			return response;
		}
	},
	async deleteAllFiles(cg_id) {
		try{
			const response = await axios.delete(`/api/media_library/${cg_id}/delete_all/`);
			return response
		}catch(error){
			const response = {
				error: true,
				error_message: error.response.data
			}
			return response;
		}
	}
}
