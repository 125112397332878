import DatasetsAPI from '@/services/api/Datasets.js'
import store from '@/store/store.js'

const state = {
	rt_instances: [],
	available_numbers: [],
	rti_error_message: '',
	rti_validation_errors: {},
}

const getters = {
	registration_template_instances: (state) => state.rt_instances,
	available_numbers: (state) => state.available_numbers,
	rti_error_message: (state) => state.rti_error_message,
	rti_validation_errors: (state) => state.rti_validation_errors,
}

const actions = {
	async getRegistrationTemplateInstances({ commit }) {
		const rcg_id = store.getters.current_CG.customer_group.root_parent_id
		const response = await DatasetsAPI.getRegistrationTemplateInstances(rcg_id)
		if (response.error) {
			return
		}
		const instances = response.registration_template_instances
		commit('setRegistrationTemplateInstances', instances)
		commit('setAvailableNumbers', response.available_numbers)
		commit('updateErrorMessage', '')
	},
	async getRTIMappings({ commit }, index) {
		const response = await DatasetsAPI.getRegistrationTemplateMappings(
			store.getters.registration_template_instances[index].dataset
		)
		if (response.error) {
			commit('updateErrorMessage', response.error_message)
			return
		}
		commit('updateErrorMessage', '')
		commit('setMappings', { index, fields: response })
	},
	async saveInstance({ commit }, index) {
		const response = await DatasetsAPI.saveRegistrationTemplateInstance(
			store.getters.registration_template_instances[index]
		)
		if (response.error) {
			commit('setErrorMessage', response.error_message)
			return
		}
		commit('updateErrorMessage', '')
	},
	async deleteInstance({ commit }, index) {
		const response = await DatasetsAPI.deleteRegistrationTemplateInstance(
			store.getters.registration_template_instances[index].id
		)
		if (response.error) {
			commit('updateErrorMessage', response.error_message)
			return
		}
		commit('updateErrorMessage', '')
		commit('deleteInstance', index)
	},
	setFields({ commit }, index) {
		const instance = JSON.parse(
			JSON.stringify(store.getters.registration_template_instances[index])
		)
		instance.fields
			.sort((a, b) => a.mapping.id > b.mapping.id)
			.forEach((field) => {
				field.prepopulated_value = null
			})

		if (instance.registration_type === 'SMS') {
			let is_first_number_field = false
			instance.fields.forEach((field) => {
				if (
					field.mapping.field_type.includes('SMS') &&
					!is_first_number_field
				) {
					is_first_number_field = true
					field.required = true
					field.enabled = true
					field.disabled = true
					return
				}
				field.enabled = false
				field.required = false
				field.disabled = true
			})
		} else {
			instance.fields.forEach(
				(field) => (field.disabled = field.enabled = field.required = false)
			)
		}
		commit('setFields', { index, fields: instance.fields })
	},
	updateRTI({ commit }, obj) {
		const rt_instances = JSON.parse(
			JSON.stringify(store.getters.registration_template_instances)
		)
		rt_instances[obj.index][obj.property] = obj.value
		commit('setRegistrationTemplateInstances', rt_instances)
	},
}

const mutations = {
	updateErrorMessage: (state, value) => (state.rti_error_message = value),
	setRegistrationTemplateInstances: (state, instances) =>
		(state.rt_instances = instances),
	setAvailableNumbers: (state, numbers) => (state.available_numbers = numbers),
	updateInstance: (state, { value, index, prop }) =>
		(state.rt_instances[index][prop] = value),
	deleteInstance: (state, index) => {
		state.rt_instances.splice(index, 1)
	},
	updateRTIField: (state, { value, field_index, instance_index, prop }) => {
		state.rt_instances[instance_index].fields[field_index][prop] = value
		if (prop === 'enabled' && value === false) {
			state.rt_instances[instance_index].fields[field_index]['required'] = false
		}
	},
	setMappings: (state, { index, fields }) =>
		(state.rt_instances[index].fields = fields),
	setFields: (state, { index, fields }) =>
		(state.rt_instances[index].fields = fields),
	updateRTIPhoneNumbers: (state, { index, value, operation }) => {
		const selected_numbers = state.rt_instances[index].selected_numbers
		switch (operation) {
			case 'clear': {
				state.available_numbers = [
					...state.available_numbers,
					...selected_numbers.filter(
						(it) => !state.available_numbers.map((it) => it.id).includes(it.id)
					),
				]
				state.rt_instances[index].selected_numbers = []
				return
			}
			case 'add': {
				selected_numbers.push(value)
				state.available_numbers.splice(
					state.available_numbers.findIndex((it) => it.id === value.id),
					1
				)
				return
			}
			case 'remove': {
				state.rt_instances[index].selected_numbers = selected_numbers.filter(
					(it) => it.id !== value.id
				)
				if (!state.available_numbers.some((it) => it.id === value.id)) {
					state.available_numbers.push(value)
				}
				return
			}
		}
	},
	addRTI: (state) => {
		if (state.rt_instances.filter((instance) => instance.id == 0).length) {
			return
		}
		const instance = {
			id: 0,
			friendly_name: null,
			dataset: null,
			timezone: null,
			registration_type: null,
			fields: [],
			selected_numbers: [],
		}
		state.rt_instances.unshift(instance)
	},
	setErrorMessage: (state, rti_validation_errors) =>
		(state.rti_validation_errors = rti_validation_errors),
}

export default { state, getters, actions, mutations }
