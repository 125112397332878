import axios from 'axios';

export default {
	getLoggedUser: async () => await axios.get('/api/user/self/?descendant_fields=true'),
	getCurrentCustomerGroup: async () => await axios.get('/api/customer_group/self/'),
	getIMSettingsForRCG: async (rcg_id) => await axios.get(`/api/customer_group_im_settings/${rcg_id}/`),
	getVersionNumber: async () => await axios.get('/api/get_company_info/'),
	keepAlive: async () => await axios.get('/api/users/keepalive/'),
	logout: async (inactivity) => await axios.post(`/api/rest-auth/logout/?inactivity=${inactivity}`),
	getCustomerGroups: async () => await axios.get('/api/customer_group/'),
	getTokenExpiryTime: async () => await axios.get('/api/get_token_expiry_time/'),
	getUsersIds: async() => await axios.get(`/api/customer_group/get_users_id/?descendants=true`),
	getCustomerGroupIds: async() => await axios.get(`/api/customer_group_ids/`),
	retrieveUserWithValues: async(id) => await axios.get(`/api/user/${id}/?custom_fields=true`)
}
