<template>
	<div v-if="(incident_template_subdomain || registration_template_subdomain) && !show_main_layout"
		:style="custom_branding_colors">
		<router-view class="router-container" />
	</div>
	<div v-else-if="!logged_user.id && !show_main_layout">
		<router-view class="router-container" />
	</div>
	<div v-else-if="logged_user.id && current_CG.customer_group.id && show_main_layout"
		id="app" :style="custom_branding_colors" class="root_el_class_list">
		<main-layout></main-layout>
	</div>

</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue'
import MainHeader from "@/components/utils/MainHeader.vue";
import SideBar from "@/components/utils/SideBar.vue";
import Breadcrumbs from "@/components/utils/Breadcrumbs.vue";
import BaseDialog from "@/components/utils/dialogs/Base.vue";
import BaseSnackbar from "@/components/utils/snackbars/Base.vue";
import IncidentTemplateAPI from "@/services/api/IncidentTemplate.js";
import { mapActions, mapGetters } from "vuex";
import { colors } from 'quasar'

export default {
	name: "app",
	components: {
		MainHeader,
		SideBar,
		Breadcrumbs,
		BaseDialog,
		BaseSnackbar,
		MainLayout
	},
	data(){
		return{
			is_media_library_modal: "",
			root_el_class_list: [],
			incident_template_colors: "",
		}
	},
	computed: {
		...mapGetters([
			"is_menu_collapsed",
			"logged_user",
			"current_CG",
			"incident_template_subdomain",
			"dialog_options",
			"snackbar_options",
			"registration_template_subdomain",
			"show_main_layout",
		]),


		custom_branding_colors: function () {
			let cb_colors = {};
			if (this.current_CG.customer_group.enable_custom_branding) {
				if (this.current_CG.customer_group.highlight_incident_color) {
					cb_colors['--ac-incident-highlight-color'] = this.current_CG.customer_group.highlight_incident_color;
				} else if (this.current_CG.customer_group.highlight_color && !this.current_CG.customer_group.highlight_incident_color) {
					cb_colors['--ac-incident-highlight-color'] = this.current_CG.customer_group.highlight_color;
				} else {
					cb_colors['--ac-incident-highlight-color'] = "#1976d2";
				}
				if (this.current_CG.customer_group.highlight_color) {
					cb_colors['--ac-highlight-color'] = this.current_CG.customer_group.highlight_color;
					cb_colors['--ac-new-highlight-color'] = this.current_CG.customer_group.highlight_color;
				} else {
					cb_colors['--ac-highlight-color'] = "#1976d2";
					cb_colors['--ac-new-highlight-color'] = "#b83b22"
				}
				if (this.current_CG.customer_group.header_icons_color) {
					cb_colors['--ac-header-icons-color'] = this.current_CG.customer_group.header_icons_color;
				}
				if (this.current_CG.customer_group.header_color) {
					cb_colors['--ac-incident-header-color'] = this.current_CG.customer_group.header_color;
					cb_colors['--ac-header-color'] = this.current_CG.customer_group.header_color;
				}
			} else {
				cb_colors['--ac-incident-highlight-color'] = "#1976d2"
				cb_colors['--ac-new-highlight-color'] = "#b83b22"

			}
			cb_colors['--ac-highlight-pale-color'] = this.pale_color(this.current_CG.customer_group.highlight_color);
			cb_colors['--ac-incident-pale-color'] = this.pale_color(this.current_CG.customer_group.highlight_incident_color);
			cb_colors['--ac-incident-hover-color'] = this.hover_color(this.current_CG.customer_group.highlight_incident_color);
			cb_colors['--ac-highlight-hover-color'] = this.hover_color(this.current_CG.customer_group.highlight_color);
			cb_colors['--card-background'] = this.current_CG.customer_group.highlight_color
			if (cb_colors['--ac-highlight-color']) {
				colors.setBrand('primary', cb_colors['--ac-highlight-color'])
			} else if (this.incident_template_colors){
				colors.setBrand('primary', this.incident_template_colors)
			} else{
				colors.setBrand('primary', '#1976d2')
			}
			return cb_colors;
		},
	},

	methods: {

		pale_color: function (color) {
			let color_picked = color
			if (color_picked) {
				if (color_picked.length === 7) {
					return color_picked.concat("11");
				}
				else if (color_picked.length === 4) {
					return color_picked.concat("1");
				}
			}	else {
				return "#f0fcfd";
			}
		},

		hover_color: function (color) {
			let color_picked = color
			if (color_picked) {
				if (color_picked.length === 7) {
					return color_picked.concat("99");
				}
				else if (color_picked.length === 4) {
					return color_picked.concat("9");
				}
			} else {
				return "#f6fdfe";
			}
		},

	},
	async created(){
		this.is_media_library_modal = parent.document.getElementById('media-library');
		if (
			this.incident_template_subdomain &&
			this.incident_template_subdomain != 'www' && this.incident_template_subdomain != 'localhost:8000'
		){
			let template = await IncidentTemplateAPI.retrieveIncidentTemplate(this.incident_template_subdomain);
			if (template.template_branding && template.template_branding.label_color){
				this.incident_template_colors = template.template_branding.label_color
			}
		}
	},
	watch: {
		dialog_options: {
			deep: true,
			handler() {
				if (this.dialog_options.show_dialog) {
					this.root_el_class_list.push('overflow-hidden');
				}
			},
		}
	}
};
</script>


<style lang="scss">
:root {
	--ac-highlight-color: #1976d2;
	--ac-header-color: #ffffff;
	--ac-header-icons-color: #1976d2;
	--ac-incident-highlight-color: #1976d2;
	--ac-new-highlight-color: #b83b22;

	--ac-grey-dark: #192320;
	--ac-grey-darker: #333;
	--ac-grey-light: #f0f0f2;
	--ac-white: #ffffff;

	--ac-header-gray: #e5e5e7;
	--ac-content-gray: #fbfbfd;
	--ac-content-l2-gray: #f0f0f2;

	--ac-incident-hamburger-button-color:var(--ac-incident-highlight-color);
	--ac-incident-pale-color:#f0fcfd;

	--ac-incident-header-color: #ffffff;
	--ac-incident-profile-icon-container-color: #1976d2;
	--ac-incident-profile-icon-color: #fff;
	--ac-incident-hover-color:#1976d2;

	--ac-grey: #555;
	--ac-background-alert: #1976d2;
	--ac-border-alert: #1976d2;
	--ac-alert-color:#fff;
	--ac-border-grey: #ddd;
	--ac-font-family: 'Poppins', sans-serif;
	--card-background: var(--ac-highlight-color);
	--card-text: var(--ac-white);

	--q-color-register-button: #1976d2;
	--q-color-links-color: #1976d2;
	--q-color-deregister-button: #1976d2;
	--q-color-bullet-points: #1976d2;

}

.text-register-button {
	color: var(--q-color-register-button) !important
}

.bg-register-button {
	background: var(--q-color-register-button) !important
}

.text-links-color {
	color: var(--q-color-links-color) !important
}

.bg-links-color {
	background: var(--q-color-links-color) !important
}

.text-deregister-button {
	color: var(--q-color-deregister-button) !important
}

.bg-deregister-button {
	background: var(--q-color-deregister-button) !important
}

.bullet-points-color li::before {
	color: var(--q-color-bullet-points) !important;
}

/* --------- MODAL STYLE START --------- */

.modal-mask {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	vertical-align: middle;
}

.modal-container {
	width: 600px;
	margin: 30px auto;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
}

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.scenario-button-label {
	font-weight: 400;
	margin-bottom: 0;
	display: block;
}

.scenario-header-label{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 600;
}

 .size-16{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 400;
}

label{
	font-size: 14px;
	font-weight: 400px;
}

.pr-sm{
	padding-right: 5px;
}

.label-circle {
	margin-top: 10px;
	cursor: pointer;
}

.modal.in .modal-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 10px;
}

@media (min-width: 768px) {
	.modal-dialog {
		width: 600px;
		margin: 30px auto;
	}
}

.modal-header {
	font-family: 'Poppins', sans-serif;
	min-height: 16.43px;
	padding: 15px;
	border-bottom: 1px solid #e5e5e5;
}

.modal-header h4, .modal-header h3 {
	font-weight: 600;
	font-size: 14px;
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: left;
}

.h4,
h4 {
	font-size: 14px;
}


.modal-body {
	position: relative;
	padding: 15px;
}

.footer-modal {
	padding: 15px;
	text-align: center;
}

.footer-modal .row {
	display: flex;
	justify-content: center;
}

// Removed because of quasar framework (generic class name)
// .row {
// 	margin-right: -15px;
// 	margin-left: -15px;
// }

.center-menu {
	text-align: center;
}

.idle-icons {
	padding-top: 13px;
	padding-left: 5px;
}

.idle-icons > i {
	font-size: 23px;
}

.scenario-shortcut-icon {
	background: #192320;
	height: 48px;
	width: 48px;
	margin-left: auto;
	margin-right: auto;
	color: #ffffff;
	border-radius: 100px;
	cursor: pointer;
}

p {
	margin: 0 0 10px;
}

.float-left {
	text-align: left;
}

.container {
	text-align: right;
	// font-size: 8rem;
	color: #555;
}

.scenario-shortcut-icon:hover {
	background: #1976d2;
}


.padding-icons {
	padding-right: 15px;
	padding-left: 15px;
}

/* ---------  MODAL STYLE END ---------*/

body {
	margin: 0;
}

* {
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
	// scroll-behavior: smooth; removed because qtabs scroll won't work
}

#app {
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: var(--ac-grey-darker);
	background-color: #fff;
}

.header,
i {
	cursor: pointer;
}

hr {
	color: var(--ac-grey-light);
}

.legacy-input{
	appearance: none;
	outline-color: var(--ac-incident-highlight-color);
	border: 1px solid #aaa;
	background: white;
	height: 2.2rem;
	padding: 5px;
	min-width: 8rem;
	&:-moz-focusring {
		border: 1px solid var(--ac-incident-highlight-color);
	}
}
.main-content-container {
	margin-left: 250px;
	-webkit-transition: all 0.8s ease;
	-moz-transition: all 0.8s ease;
	-o-transition: all 0.8s ease;
	transition: all 0.8s ease;
}

.main-content-container-collapsed {
	margin-left: 60px;
	-webkit-transition: all 0.8s ease;
	-moz-transition: all 0.8s ease;
	-o-transition: all 0.8s ease;
	transition: all 0.5s ease;
}

.router-container {
	min-height: calc(100vh - 200px);
	background: #fff;
}

.list-container {
	position: relative;
	margin: auto;
	max-width: 1024px;
}

.card-container {
	background: #eee;
	border-radius: 5px;
	padding: 10px;
	display: flex;
}

.handle {
	padding: 0.5rem 1rem;
	/* background: #dedede; */
	/* border-radius: 5px; */
}

.fal:hover {
	font-weight: 600;
}

.shadow {
	box-shadow: 0 0 63px rgb(240, 240, 242), 0 0 35px rgba(0, 0, 0, 0.4);
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
				-moz-user-select: none; /* Old versions of Firefox */
				-ms-user-select: none; /* Internet Explorer/Edge */
						user-select: none; /* Non-prefixed version, currently
																	supported by Chrome, Opera and Firefox */
}

.fields-tab-header {
	border-bottom: 1px solid $grey-4;
	cursor: default;
}

@media (max-width: 768px) {
	/* #app {
		font-size: 14px;
	} */

	.main-content-container {
		/* min-height: 100vh; */
		padding-bottom: 25px;
		margin-left: 0;
	}
}
@media (max-width: 425px) {
	.card-container {
		flex-wrap: wrap;
	}
}

/* Calendar style */
.theme-red .vdatetime-popup__header,
.theme-red .vdatetime-calendar__month__day--selected > span > span,
.theme-red .vdatetime-calendar__month__day--selected:hover > span > span {
	background: var(--ac-incident-highlight-color);
}

.theme-red .vdatetime-year-picker__item--selected,
.theme-red .vdatetime-time-picker__item--selected,
.theme-red .vdatetime-popup__actions__button {
	color: var(--ac-incident-highlight-color);
}
</style>

<style>

.ac-btn{
	background: none;
	border: none;
	display: inline-block;
	padding: 5px;
	cursor: pointer;
	min-width: 90px;
}
.ac-btn.btn-large .iwrap{
	display: block;
	margin: auto;
	position: relative;
	background-color: #192320;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	transition: 0.1s ease background-color;
}
.ac-btn.btn-large i{
	color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.ac-btn i{
	font-size: 18px;
}
.ac-btn:focus{
	outline: none;
}
.ac-btn.btn-large:focus .iwrap{
	animation-name: ac-btn-large-press;
	animation-duration: 0.3s;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
}
.ac-btn.btn-small:focus i{
	animation-name: ac-btn-small-press;
	animation-duration: 0.3s;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
}
.ac-btn.btn-large .btn-label{
	font-size: 14px;
	display: block;
	color: #192320;
	padding-top: 5px;
	font-weight: 400;
	font-family: 'Poppins', sans-serif;
}
.ac-btn.btn-small i{
	color: #192320;
	transition: 0.1s ease color;
}
.nav {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	display: flex;
}
.ac-btn.btn-small:hover i{
	color: var(--ac-incident-highlight-color);
}
.ac-btn.btn-large:hover .iwrap{
	background-color: var(--ac-incident-highlight-color);
}
.report-btn.btn-small:hover i{
	color: var(--ac-highlight-color);
}
.tab {
	font-size: 14px;
	background: var(--ac-grey-darker);
	color: #fff;
	height: 35px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	padding: 8px;
	margin-left: 5px;
	cursor: pointer;
	border-top: var(--ac-grey-darker) 1px solid;
	border-left: var(--ac-grey-darker) 1px solid;
	border-right: var(--ac-grey-darker) 1px solid;
}

.tab:hover,
.active-tab .tablinks,
.active-tab{
	background: #fff;
	color: var(--ac-highlight-color);
}

.tab:hover .tablinks{
	color: var(--ac-highlight-color);
}

.tablinks{
	color: #fff;
}

.tablinks span{
	font-family: 'Poppins', sans-serif;
}

.bold {
	font-weight: 600;
}

.size-16{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 400;
}

.size-16-bold{
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 600;
}

.size-18-bold{
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 600;
}

.size-18{
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 400;
}

.uppercase{
	text-transform: uppercase;
}

.hover-element-background:hover{
	background-color: var(--ac-highlight-color);
	cursor: pointer;
	color: #fff;
}

.hover-element-border-color:hover .name,
.hover-element-border-color:hover .message,
.hover-element-border-color:hover .phone-number{
	color: var(--ac-new-highlight-color);
}

.hover-element-border-color:hover{
	background: #fff!important;
	color: var(--ac-highlight-color);
	border: 1px var(--ac-highlight-color) solid;
}

.hover-element-border:hover{
	border: 1px solid var(--ac-highlight-color);
}

.add-text i{
	color: var(--ac-highlight-color);
}

.hover-element-color:hover .extra-text{
	color: var(--ac-new-highlight-color);
	text-decoration: none;
}

.element-color{
	color: var(--ac-highlight-color);
}

.hover-background-gray:hover {
	background-color: #f0f0f2;
}
a {
	color: #000;
	text-decoration: none;
}
.active-element {
	background: #fff;
	color: var(--ac-highlight-color);
}
.summary i {
	color: var(--ac-highlight-color);
}
.summary:hover i, .summary:focus i{
	color: var(--ac-highlight-color);
}
.summary:hover span, .summary:focus span{
	color: var(--ac-new-highlight-color);
}
.content-wrapper{
	padding: 30px;
}
@media (max-width: 425px){
	.content-wrapper{
		padding: 20px;
	}
}
</style>
