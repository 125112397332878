
import BrandingAPI from '@/services/api/Branding.js';

const state = {
	branding_errors: "",
	custom_branding: {},
	brand_logo: null,
	email_top_image: null,
	email_bottom_image: null
}
const getters = {
	branding_errors: (state) => state.branding_errors,
	custom_branding: (state) => state.custom_branding,
	brand_logo: (state) => state.brand_logo,
	email_top_image: (state) => state.email_top_image,
	email_bottom_image: (state) => state.email_bottom_image
}
const actions = {
	updateBrandingErrors( {commit}, error) {
		commit('setBrandingErrors', error)
	},
	async getCustomBranding( {commit}) {
		let response = await BrandingAPI.getBranding()
		commit('setCustomBranding', response)
	},
	updateCustomBranding( {commit}, custom_branding) {
		commit('setCustomBranding', custom_branding)
	},
	updateCustomBrandLogo( {commit}, brand_logo) {
		commit('setCustomBrandLogo', brand_logo)
	},
	updateCustomBrandEmailTopImage ( {commit}, email_top_image) {
		commit('setCustomBrandEmailTopImage', email_top_image)
	},
	updateCustomBrandEmailBottomImage( {commit}, email_bottom_image) {
		commit('setCustomBrandEmailBottomImage', email_bottom_image)
	}
}
const mutations = {
	setBrandingErrors:(state, new_val) => state.branding_errors = new_val,
	setCustomBranding: (state, custom_branding) => state.custom_branding = custom_branding,
	setCustomBrandLogo: (state, brand_logo) => state.brand_logo = brand_logo,
	setCustomBrandEmailTopImage: (state, email_top_image) => state.email_top_image = email_top_image,
	setCustomBrandEmailBottomImage: (state, email_bottom_image) => state.email_bottom_image = email_bottom_image,
}
export default { state, getters, actions, mutations };
