import axios from 'axios';
import store from '@/store/store.js'

export default {
	async addForm(name, order) {
		try {
			const response = await axios.post('/api/incidents/form/', { name, order });
			return response;
		} catch (err) {
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data,
			};
			if(response.error_status==500){
				response.error_message = "An error occured. Please contact your administrator."
			}
			return response;
		}
	},

	async getForms() {
		try{
			const response = await axios.get('/api/incidents/form/');
			return response.data;
		}catch(err){
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data,
			};
			if(response.error_status==500){
				response.error_message = "An error occured. Please contact your administrator."
			}
			return response;
		}
	},

	async retrieveForm(id) {
		try{
			const response = await axios.get(`/api/incidents/form/${id}/`);
			store.dispatch('set_forms_errors', "");
			return response;
		}catch(err){
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data,
			};
			if(response.error_status==500){
				response.error_message = "An error occured. Please contact your administrator."
			}
			return response;
		}
	},

	async updateForm(id, name) {
		try {
			const response = await axios.put(`/api/incidents/form/${id}/`, { name });
			store.dispatch('set_forms_errors', "");
			return response;
		} catch (error) {
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			};
			return response;
		}
	},

	async patchForm(id, data) {
		try {
			const response = await axios.patch(`/api/incidents/form/${id}/`, data);
			store.dispatch('set_forms_errors', "");
			return response;
		} catch (error) {
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			};
			if(response.error_status==500){
				response.error_message = "An error occured. Please contact your administrator."
			}
			return response;
		}
	},

	async deleteForm(id) {
		try{
			const response = await axios.delete(`/api/incidents/form/${id}/`);
			store.dispatch('set_forms_errors', "");
			return response;
		}catch(err){
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data,
			};
			if(response.error_status==500){
				response.error_message = "An error occured. Please contact your administrator."
			}
			return response;
		}
	},
	async getFieldType(id){
		try{
			const response = await axios.get(`/api/incidents/form/field/${id}/`);
			store.dispatch('set_forms_errors', "");
			return response.data;
		}catch(err){
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data,
			};
			if(response.error_status==500){
				response.error_message = "An error occured. Please contact your administrator."
			}
			return response;
		}
	},
	async getTemplateFieldType(field_id){
		try{
			const response = await axios.get(`/api/incidents/get_condition_field_type/${field_id}/`);
			store.dispatch('set_forms_errors', "");
			return response.data;
		}catch(err){
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data,
			};
			if(response.error_status==500){
				response.error_message = "An error occured. Please contact your administrator."
			}
			return response;
		}
	}
};