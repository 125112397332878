import DatasetsAPI from '@/services/api/Datasets.js'
import store from '@/store/store.js'

const state = {
	on_call_settings: null,
	on_call_data_fields: null,
}

const getters = {
	on_call_settings: (state) => state.on_call_settings,
	on_call_data_fields: (state) => state.on_call_data_fields,
}

const actions = {
	async getOnCallSettings({ commit }, customer_group_id) {
		const response = await DatasetsAPI.getOnCallSettings(customer_group_id)
		commit('setOnCallSettings', response)
	},
	getOnCallCgFields({ commit }) {
		let cg_fields = store.getters.cg_fields
		let on_call_data_fields = []
		let counter_data = 0
		for (let field in cg_fields) {
			if (
				![
					'PHONE(SMS)',
					'PHONE(SMS&VOICE)',
					'PHONE(VOICE)',
					'EMAIL',
					'PAGER',
				].includes(cg_fields[field]['field_type'])
			) {
				if (cg_fields[field].reports_only_field == true) {
					on_call_data_fields.unshift(cg_fields[field])
				} else {
					on_call_data_fields.push(cg_fields[field])
				}

				on_call_data_fields[counter_data]['all_options_disabled'] = false
				for (let mapping in cg_fields[field].mapping) {
					if (
						cg_fields[field].mapping[mapping].is_inherited &&
						store.getters.current_CG.id ==
							cg_fields[field].mapping[mapping].customer_group
					) {
						on_call_data_fields[counter_data].is_inherited = true
					}
					if (
						cg_fields[field].mapping[mapping]['customer_group'] ==
						store.getters.current_CG.id
					) {
						if (
							cg_fields[field].mapping[mapping].excluded_options_by_group !=
								null &&
							cg_fields[field].mapping[mapping].excluded_options_by_group[
								'options'
							].length != 0
						) {
							let a =
								cg_fields[field].mapping[mapping].excluded_options_by_group
							if (a['options'].length == cg_fields[field]['options'].length) {
								on_call_data_fields[counter_data]['all_options_disabled'] = true
							}
						}
					}
				}
				counter_data += 1
			}
		}
		commit('setDataFields', on_call_data_fields)
	},
	async retrieveOnCallStaffing({ commit }, customer_group_id) {
		const response = await DatasetsAPI.retrieveOnCallStaffing(customer_group_id)
		let on_call_settings = JSON.parse(
			JSON.stringify(store.getters.on_call_settings)
		)
		on_call_settings['options_staffing'] = {}
		for (let i in response) {
			on_call_settings['options_staffing'][response[i]['option']] = {
				staffing: response[i]['value'],
			}
		}
		commit('setOnCallSettings', on_call_settings)
	},
	async updateOptionsStaffing({ commit }, obj) {
		let on_call_settings = JSON.parse(
			JSON.stringify(store.getters.on_call_settings)
		)
		on_call_settings.options_staffing[obj.option_id].staffing = obj.val
		commit('setOnCallSettings', on_call_settings)
	},
	async saveOnCallSettings({ commit }, customer_group_id) {
		let data = JSON.parse(
			JSON.stringify(store.getters.on_call_settings.on_call_settings)
		)
		data['options_staffing'] = store.getters.on_call_settings.options_staffing
		const response = await DatasetsAPI.saveOnCallSettings(
			customer_group_id,
			data
		)
		return response
	},
	async updateOnCallSettings({ commit }, obj) {
		let on_call_settings = JSON.parse(
			JSON.stringify(store.getters.on_call_settings)
		)
		on_call_settings.on_call_settings[obj.attr] = obj.val
		commit('setOnCallSettings', on_call_settings)
	},
}

const mutations = {
	setOnCallSettings: (state, new_val) => (state.on_call_settings = new_val),
	setDataFields: (state, on_call_data_fields) =>
		(state.on_call_data_fields = on_call_data_fields),
}

export default { state, getters, actions, mutations }
