import axios from 'axios';
import store from '@/store/store.js'
export default {
	async addField(label, field_type, is_pii, order) {
		try {
			const response = await axios.post('/api/incidents/field/', { label, field_type, is_pii, order });
			return response;
		}
		catch (err) {
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}
			return response
		}
	},

	async getFields() {
		try{
			const response = await axios.get('/api/incidents/field/');
			store.dispatch('set_fields_errors', "");
			return response.data;
		}catch(err){
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_fields_errors', response.error_message)
			}
			return response
		}
	},

	async getFieldChoices() {
		try{
			const response = await axios.get('/api/incidents/field_choice/');
			store.dispatch('set_fields_errors', "");
			return response.data;
		}catch(err){
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_fields_errors', response.error_message)
			}
			return response
		}
	},

	async getFieldAppearances(id) {
		try{
			const response = await axios.get(`/api/incidents/field_appearances/${id}/`);
			response.data.conditions = response.data.conditions.map(condition => ({
				id: condition.id,
				title: condition.title,
				display_conditions: condition.display_conditions,
				content: condition.content,
				field_mappings: condition.form_field_mappings,
				form_id: condition.form_id
			}))
			store.dispatch('set_fields_errors', "");
			return response.data;
		}catch(err){
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_fields_errors', response.error_message)
			}
			return response
		}
	},

	async updateField(id, label, field_type, is_pii) {
		try {
			const response = await axios.put(`/api/incidents/field/${id}/`, {id, label, field_type, is_pii});
			store.dispatch('set_fields_errors', "");
			return response
		}
		catch(err) {
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}
			return response
		}
	},

	async patchField(id, data) {
	try {
		const response = await axios.patch(`/api/incidents/field/${id}/`, data);
		store.dispatch('set_fields_errors', "");
		return response;
	} catch (error) {
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_fields_errors', response.error_message)
			}
			return response;
		}
	},

	async deleteField(id) {
		try{
			const response = await axios.delete(`/api/incidents/field/${id}/`);
			store.dispatch('set_fields_errors', "");
			return response
		}catch(err){
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_fields_errors', response.error_message)
			}
			return response;
		}
	},
	
	async createOption(field, value) {
		let response = ""
		try{
			response = await axios.post('/api/incidents/option/', {field, value} );
			store.dispatch('set_fields_errors', "");
			return response.data;
		}
		catch(error){
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}
			return response;
		}
	},

	async deleteOption(id) {
		try{
			const response = await axios.delete(`/api/incidents/option/${id}/`);
			store.dispatch('set_fields_errors', "");
			return response
		}catch(err){
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_fields_errors', response.error_message)
			}
			return response;
		}
	},
	
	async updateOption(id, field, value) {
		try {
			const response = await axios.put(`/api/incidents/option/${id}/`, {field, value});
			store.dispatch('set_fields_errors', "");
			return response.data;
		}
		catch(err) {
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_fields_errors', response.error_message)
			}
			return response;
		}
	},
	async getRulesForId(id){
		try{
			const response = await axios.get(`/api/incidents/rules/field/${id}/`);
			store.dispatch('set_fields_errors', "");
			return response.data;
		}catch(error){
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_fields_errors', response.error_message)
			}
			return response;
		}
	},
	async delete_rules(id, rules){
		try{
			const response = await axios.delete(`/api/incidents/rules/field/${id}/`, { data: { rules } });
			store.dispatch('set_fields_errors', "");
			return response;
		}catch(error){
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_fields_errors', response.error_message)
			}
			return response;
		}
	},
	async delete_conditions(id, field_group_ids){
		try{
			const response = await axios.delete(`/api/incidents/conditions/field/${id}/`, { data: { field_group_ids } });
			store.dispatch('set_fields_errors', "");
			return response;
		}catch(error){
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_fields_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_fields_errors', response.error_message)
			}
			return response;
		}
	}
}