import axios from 'axios';
import Utils from '@/services/utils.js'
import store from '@/store/store.js'

export default {
	async addFieldGroup(field_group) {
		try {
			const response = await axios.post('/api/incidents/field_group/', field_group);
			if(response.data['form_field_mappings']){
				response.data['field_mappings'] = Utils.deepCopy(response.data['form_field_mappings']);
				delete response.data['form_field_mappings'];
			}
			store.dispatch('set_field_groups_errors', "");
			return response;
		}
		catch(err) {
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data
			}
			if(response.error_status==500){
				store.dispatch('set_field_groups_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_field_groups_errors', response.error_message)
			}
			return response
		}
	},
	async updateFieldGroup(id, data){
		try {
			const response = await axios.put(`/api/incidents/field_group/${id}/`, data);
			if(response.data['form_field_mappings']){
				response.data['field_mappings'] = Utils.deepCopy(response.data['form_field_mappings']);
				delete response.data['form_field_mappings'];
			}
			store.dispatch('set_field_groups_errors', "");
			return response;
		}
		catch(error) {
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data.message,
				error_field: error.response.data.for_field,
				error_field_value: error.response.data.value
			}
			if(response.error_status==500){
				store.dispatch('set_field_groups_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_field_groups_errors', response.error_message)
			}
			return response
		}
	},
	async patchFieldGroup(id, data) {
		try {
			const response = await axios.patch(`/api/incidents/field_group/${id}/`, data);
			store.dispatch('set_field_groups_errors', "");
			return response;
		} catch (error) {
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_field_groups_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_field_groups_errors', response.error_message)
			}
			return response;
		}
	},
	async retrieveFieldGroup(id){
		try {
			const response = await axios.get(`/api/incidents/field_group/${id}/`);
			if(response.data['form_field_mappings']){
				response.data['field_mappings'] = Utils.deepCopy(response.data['form_field_mappings']);
				delete response.data['form_field_mappings'];
			}
			store.dispatch('set_field_groups_errors', "");
			return response.data;
		}
		catch(err) {
			const response = {
				error: true,
				error_status: err.response.status,
				error_message: err.response.data
			}
			if(response.error_status==500){
				store.dispatch('set_field_groups_errors', "An error occured. Please contact your administrator.");
			}else{
				store.dispatch('set_field_groups_errors', response.error_message)
			}
			return response.errors
		}
	},
	async deleteFieldGroup(id) {
		try{
			const response = await axios.delete(`/api/incidents/field_group/${id}/`)
			return response.data
		}catch(error){
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data
			}
			return response;
		}
	},

}