import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import IdleVue from 'idle-vue';
import Vuelidate from 'vuelidate';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueTelInput from 'vue-tel-input';
import VueCountryCode from "vue-country-code";


import '@/services/api/interceptor.js'; // Interceptors are initialized with this import
import 'vue-tel-input/dist/vue-tel-input.css';

// Quasar framework import
import './styles/quasar.scss'
import iconSet from 'quasar/icon-set/material-icons-outlined.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Meta from 'quasar/src/plugins/Meta.js';

Vue.config.productionTip = false;

const eventsHub = new Vue();

Vue.use(VueMoment, {
	moment,
});

Vue.use(IdleVue, {
	eventEmitter: eventsHub,
	idleTime: 10000,
	store: store,
});

Vue.use(VueTelInput);
Vue.use(Vuelidate);
Vue.use(VueTelInput);
Vue.use(VueCountryCode);

Vue.use(Quasar, {
	config: {},
	plugins: {
		Notify,
		Loading,
		Dialog,
		Meta
	},
	iconSet: iconSet
})

new Vue({
	router,
	store,
	onIdle() {
		store.dispatch('setInactive');
	},
	onActive() {},
	render: h => h(App),
}).$mount('#app');
