<template>
	<div>
		<div :class="base_class_list">
			<div
				@click="closeDialog"
				class="bg-black bg-opacity-50 absolute top-0 right-0 bottom-0 left-0 z-0"
			></div>
			<div :class="dialog_class_list">
				<Alert
					v-if="dialog_options.dialog_type == 'alert'"
					@closeDialog='closeDialog'
				/>
				<SingleInput
					v-if="dialog_options.dialog_type == 'single_input'"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Alert from "@/components/utils/dialogs/types/Alert.vue";
import SingleInput from "@/components/utils/dialogs/types/SingleInput.vue";
import { mapActions, mapGetters } from "vuex";
export default {
	name: "Base",
	components: {
		Alert,
		SingleInput
	},
	data() {
		return {
			base_class_list: ["base-element"],
			dialog_class_list: ["dialog"],
		};
	},
	computed: {
		...mapGetters(["dialog_options"]),
	},
	methods: {
		...mapActions(["updateDialogOptions"]),
		closeDialog() {
			this.base_class_list = this.base_class_list.filter(
				(c) => c !== "full-opacity"
			);
			this.dialog_class_list = this.dialog_class_list.filter(
				(c) => c !== "full-scale"
			);
			setTimeout(() => {
				this.updateDialogOptions({ dialog_type: "alert", show_dialog: false });
			}, 300);
		},
	},

	mounted() {
		requestAnimationFrame(() => {
			this.base_class_list.push("full-opacity");
			this.dialog_class_list.push("full-scale");
		});
	},
};
</script>

<style lang="scss" scoped>
.base-element {
	@apply fixed;
	@apply top-0;
	@apply right-0;
	@apply bottom-0;
	@apply left-0;
	@apply flex;
	@apply justify-center;
	@apply transition-all;
	@apply duration-300;
	@apply ease-in-out;
	@apply items-center;
	@apply md:items-start;
	@apply opacity-0;
	@apply p-4;
	z-index: 6;
}
.dialog {
	@apply transition-all;
	@apply duration-300;
	@apply ease-in-out;
	@apply transform;
	@apply scale-0;
	@apply p-6;
	@apply bg-white;
	@apply drop-shadow;
	@apply rounded;
	@apply sm:mx-6;
	@apply md:mt-12;
	@apply max-w-xl;
	@apply text-base;
	@apply sm:mx-0;
}
.full-opacity {
	@apply opacity-100;
}
.full-scale {
	@apply scale-100;
}
</style>
