<template>
	<div>
		<div
			class="text-left"
			v-html="dialog_options.alert_dialog_options.dialog_message"
		>
		</div>
		<div class="flex justify-end mt-4 space-x-4">
			<button
				class="btn"
				@click="$emit('closeDialog')"
			>
				{{ dialog_options.alert_dialog_options.dialog_decline_button_text }}
			</button>
			<button
				class="btn"
				@click="dialog_options.alert_dialog_options.dialog_accept_callback"
			>
				{{ dialog_options.alert_dialog_options.dialog_accept_button_text }}
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "Alert",
	computed: {
		...mapGetters(["dialog_options"]),
	},
};
</script>

<style lang="scss" scoped>
.btn {
	@apply p-2;
	@apply hover:bg-coolGray-200;
	@apply rounded;
	@apply transition-all;
	@apply duration-300;
	@apply ease-in-out;
	@apply text-red-400;
	@apply font-semibold;
}
</style>