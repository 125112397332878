
import DatasetsAPI from '@/services/api/Datasets.js';
import store from '@/store/store.js';

const state = {
	merge_fields: null
};

const getters = {
	merge_fields: (state) => state.merge_fields
};

const actions = {
	async getMergeFields({commit}, cg_id) {
		const response = await DatasetsAPI.getMergeFields(cg_id)
		commit('setMergeFields', response)
	},
	updateMergeField({commit}, obj) {
		let merge_fields = JSON.parse(JSON.stringify(store.getters.merge_fields))
		merge_fields[obj.index]['merge_field_enabled'] = obj.val
		commit('setMergeFields', merge_fields)
	},
	async saveMergeFields({commit}, obj) {
		const response = await DatasetsAPI.saveMergeFields(obj)
		return response
	}
};

const mutations = {
	setMergeFields: (state, new_val) => (state.merge_fields = new_val),
};

export default {state, getters, actions, mutations}
