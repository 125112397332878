<template>
	<div :class="class_list">
		<span>
			{{ snackbar_options.error.text }}
		</span>
		<button class="dismiss-btn" @click="dismiss">Dismiss</button>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'Default',
	data() {
		return {
			class_list: {
				'flex': true,
				'flex-col': false,
				'justify-between': true,
				'transitioned': true
			}
		}
	},
	computed: {
		...mapGetters(['snackbar_options'])
	},
	methods: {
		...mapActions(['updateSnackbarOptions']),
		dismiss() {
			this.$emit('dismiss');
		}
	},
	mounted() {
		if (this.$el.offsetHeight > 24) {
			this.class_list['flex-col'] = true;
			this.class_list['justify-between'] = false;
		}
	}
}
</script>

<style scoped lang="scss">
	.dismiss-btn {
		@apply text-right;
		@apply ml-2;
		@apply text-red-400;
		@apply uppercase;
		@apply font-semibold;
		@apply cursor-pointer;
	}

	.transitioned {
		transform: translateY(0);
	}
</style>>
