import ActivityLogApi from '@/services/api/activityLog.js';

const state = {
	activity_log_list: {},
	activity_log_filters: {},
	all_customer_groups: {},
	num_of_al_pages: 1,
};

const getters = {
	activity_log_list: state => state.activity_log_list,
	activity_log_filters: state => state.activity_log_filters,
	all_customer_groups: state => state.all_customer_groups,
	num_of_al_pages: state => state.num_of_al_pages,
};


const actions = {
	async getActivityLogs({commit}, data){
		const response = await ActivityLogApi.getActivityLogs(data);
		let activity_logs = response.data.activity_logs;
		commit('setActivityLogs', activity_logs);
	},
	async listCustomerGroups({commit}){
		const response = await ActivityLogApi.getCustomerGroups();
		commit('setCustomerGroups', response.data)
	},
	async updateActivityLogFilters({commit}, activity_log_filters){
		commit('setActivityLogFilters', activity_log_filters)
	},
	clearActivityLogsList({commit}){
		commit('setActivityLogs', [])
	}
};

const mutations = {
	setActivityLogs: (state, data) => {state.activity_log_list = data},
	setActivityLogFilters: (state, data) => {state.activity_log_filters = data},
	setCustomerGroups: (state, data) => {state.all_customer_groups = data},
};

export default {state, getters, actions, mutations}