<template>
	<q-layout view="hHh lpR fFf">
		<q-header bordered class="layout-header text-white">
			<q-toolbar class="flex justify-between">
				<q-btn
					dense
					flat
					round
					icon="menu"
					class="layout-header__btn"
					@click="is_drawer_open = !is_drawer_open"
				/>
				<img class="layout-header__logo" :src="getLogoImage()" alt="Logo image"/>
				<main-layout-header-profile-menu />
			</q-toolbar>
		</q-header>
		<main-layout-drawer
			:is_drawer_open="is_drawer_open"
			@toggleDrawer="toggleDrawer"
		/>
		<q-page-container>
			<router-view />
		</q-page-container>
	</q-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import MainLayoutDrawer from '@/components/utils/MainLayoutDrawer'
import MainLayoutHeaderProfileMenu from '@/components/utils/MainLayoutHeaderProfileMenu'

export default {
	name: 'MainLayout',
	components: {
		MainLayoutDrawer,
		MainLayoutHeaderProfileMenu,
	},
	data() {
		return {
			is_drawer_open: true,
		}
	},
	computed: {
		...mapGetters(['current_CG']),
	},
	methods: {
		getLogoImage() {
			if (this.current_CG.customer_group.brand_logo) {
				const logo = this.current_CG.customer_group.brand_logo
				const has_logo = logo.length > 61
				if (has_logo) {
					return this.current_CG.customer_group.brand_logo
				}
			}
			return require('../assets/logo.png')
		},
		toggleDrawer(val) {
			this.is_drawer_open = val
		},
	},
}
</script>

<style lang="scss">
.layout-header {
	background-color: var(--ac-header-color) !important;
	&__logo {
		max-width: 100px;
	}
	&__btn {
		color: var(--ac-header-icons-color) !important;
	}
}
</style>
