var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:{
			'black-hide': _vm.is_menu_collapsed,
			'black-show': !_vm.is_menu_collapsed,
		},attrs:{"id":"mobile-dark-div"}}),_c('div',{staticClass:"column justify-between",class:{
			'sidebar-collapsed': _vm.is_menu_collapsed,
			'sidebar-full': !_vm.is_menu_collapsed,
		},attrs:{"id":"sidebar-wrapper"}},[_c('div',{staticClass:"sidebar-list"},[_c('ul',{attrs:{"id":"sidebar-nav"}},_vm._l((_vm.menu_items),function(item,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(item.visible),expression:"item.visible"}],key:item.id},[_c('SidebarItem',{class:_vm.getSidebarClasses(item.id),attrs:{"index":index,"item":item},on:{"itemClicked":_vm.itemClicked}}),_c('transition',{attrs:{"name":"slideDown"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(item.isOpen),expression:"item.isOpen"}]},_vm._l((item.children),function(child){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(child.visible),expression:"child.visible"}],key:child.id},[_c('SidebarItem',{class:_vm.getSidebarClasses(item.id, child.id),attrs:{"item":child},on:{"itemClicked":_vm.itemClicked,"itemChildClicked":_vm.itemChildClicked}})],1)}),0)])],1)}),0)]),_c('div',{staticClass:"q-pl-xs"},[_vm._v(" Version "+_vm._s(_vm.version_number)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }