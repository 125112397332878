import { render, staticRenderFns } from "./MainLayoutHeaderProfileMenu.vue?vue&type=template&id=f0bfbbe2&"
import script from "./MainLayoutHeaderProfileMenu.vue?vue&type=script&lang=js&"
export * from "./MainLayoutHeaderProfileMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QMenu,QList,QItem,QItemSection});qInstall(component, 'directives', {ClosePopup});
