import UploadAPI from '@/services/api/uploadFile.js';
import IncidentFormAPI from "@/services/api/IncidentForm.js";
import store from '@/store/store.js';
import { CHARSETS } from '@/services/constants.js';

export default {
	deepCopy(obj) {
		return JSON.parse(JSON.stringify(obj));
	},

	async areConditionsMet(conditions_list, payload_list, incident_state, customer_permission, is_template) {
		/*Check if a list of objects (conditions_list) is sublist of
			other list of objects (payload_list)*/
		let count = 0;
		for (let i in conditions_list) {
			if (conditions_list[i].type == 'state') {
				if (incident_state == conditions_list[i].values.toLowerCase()) {
					count++;
				}
			} else if (conditions_list[i].type == 'permission') {
				if (customer_permission == conditions_list[i].values.toUpperCase()) {
					count++;
				}
			}
			else {
				if (!conditions_list[i].field_type){
					if (is_template){
						conditions_list[i].field_type = await IncidentFormAPI.getTemplateFieldType(conditions_list[i].field)
					}else{
						conditions_list[i].field_type = await IncidentFormAPI.getFieldType(conditions_list[i].field)
					}
				}
				if (payload_list.length == 0){
					if (!conditions_list[i].values && ['ATTACHMENT', 'CHECKBOX'].includes(conditions_list[i].field_type)){
						count++;
						continue;
					}
				}else{
					if (conditions_list[i].field_type == 'ATTACHMENT' || conditions_list[i].field_type == 'CHECKBOX'){
						let condition_item = payload_list.filter(item =>
							item.field == conditions_list[i].field && item.field_group == conditions_list[i].field_group)
						if (!condition_item.length){
							if (!conditions_list[i].values && ['ATTACHMENT', 'CHECKBOX'].includes(conditions_list[i].field_type)){
								count++;
								continue;
							}
						}else if (condition_item.length){
							if (conditions_list[i].field_type == 'ATTACHMENT'){
								if (conditions_list[i].values && condition_item[0].values && condition_item[0].values.length){
									count++
									continue
								}else if (!conditions_list[i].values == false && !condition_item[0].values){
									count++
									continue
								}
							}else if (conditions_list[i].field_type == 'CHECKBOX' && conditions_list[i].values == condition_item[0].values){
								count++
								continue
							}
						}
					}else{
						for (let j = 0; j < payload_list.length; j++) {
							if (this.areObjectsEqual(conditions_list[i], payload_list[j])) {
								count++;
								continue;
							}
						}
					}
				}
			}
		}
		return count === conditions_list.length;
	},

	areObjectsEqual(obj1, obj2) {
		if (obj1 === undefined || obj2 == undefined) {
			return false;
		}

		const obj1_keys = Object.keys(obj1).sort();
		const obj2_keys = Object.keys(obj2).sort();
		let index_obj1_type = obj1_keys.indexOf('type');
		if (index_obj1_type !== -1) { obj1_keys.splice(index_obj1_type, 1) }
		let index_obj1_field_type = obj1_keys.indexOf('field_type');
		if (index_obj1_field_type !== -1) { obj1_keys.splice(index_obj1_field_type, 1) }
		let index_obj2 = obj1_keys.indexOf('type');
		if (index_obj2 !== -1) { obj2_keys.splice(index_obj2, 1) }

		if (obj1_keys.length !== obj2_keys.length) { return false }

		for (let i = 0; i < obj1_keys.length; i++) {
			let key = obj1_keys[i];

			if (JSON.stringify(obj1[key]) != JSON.stringify(obj2[key])) {
				return false;
			}
		}
		return true;
	},

	getValue(values, field_group, field) {
		return values
			.filter(val => val.field_id == field && val.field_group_id == field_group)
			.map(val => (val = val.values))[0];
	},

	copyToClipboard(text) {
		const el = document.createElement('textarea');
		el.value = text;
		document.body.appendChild(el);
		el.select();
		try {
			document.execCommand('copy');
		} catch (error) {
			return false;
		}
		document.body.removeChild(el);
		return true;
	},

	async uploadAttachment(file_data, file, is_template) {
		let incoming_data = []
		for (let i = 0; i < file.length; i++){
			let current_file_data = file_data[i]
			let current_file = file[i]
			let incoming_data1 = ""
			if (is_template) {
				incoming_data1 = await UploadAPI.uploadFileTemplate(current_file_data, current_file);
			} else {
				incoming_data1 = await UploadAPI.uploadFile(current_file_data, current_file);
			}
			incoming_data.push(incoming_data1)
		}
		let response = [];
		for (let file1 in file_data) {
			let obj = {
				url: incoming_data[file1]['url'],
				key: incoming_data[file1]['key'],
				name: file_data[file1]['name'],
				created_at: incoming_data[file1]['created_at']
			};

			response.push(obj);
		}

		return response;
	},

	copyText(element_id) {
		let testingCodeToCopy = document.querySelector(element_id);
		testingCodeToCopy.setAttribute('type', 'text');
		testingCodeToCopy.select();

		try {
			document.execCommand('copy');
		} catch (err) {
			alert('Oops, unable to copy');
		}
		store.dispatch('updateCopiedText', testingCodeToCopy.value);
		testingCodeToCopy.setAttribute('type', 'hidden');
		window.getSelection().removeAllRanges();
	},

	pasteText(destination, action) {
		let new_text_value = (document.getElementById(destination).value += ' ' + store.getters.copied_text);
		store.dispatch(action, new_text_value);
	},

	/** Calculate how many SMS segments does the message require. */
	calculateMessageSegments(text) {
		let threshold_data = {}

		const parsed_text = this.parseGSM(text);

		const [threshold_one, threshold_many] = isGSM(parsed_text) ? [160, 153] : [70, 67];
		const threshold = parsed_text.length <= threshold_one ? threshold_one : threshold_many;

		threshold_data = {
			'threshold' : threshold_many * 5,
			'number_of_segments' : Math.ceil(parsed_text.length / threshold),
		}

		return threshold_data
	},

	/** Replace the keywords with n-character word in order to
	 * better approximate the message content length.
	 * @param  {Number} word_length  Approximated keyword length*/
	approximateMessageContent(message_content, word_length = 10) {
		const regex = /#.+#/g;
		return message_content.replace(regex, '*'.repeat(word_length));
	},

	/* Try to convert unicode characters to GSM equivalents where possible */
	parseGSM(text) {
		const parsed_text = [...text]
			.map(char => char.codePointAt(0))
			.map(c => {
				switch (c) {
					case CHARSETS.whitespace.has(c) ? c : null:
						return 0x0020;
					case CHARSETS.comma.has(c) ? c : null:
						return 0x002c;
					case CHARSETS.dash.has(c) ? c : null:
						return 0x002d;
					case CHARSETS.period.has(c) ? c : null:
						return 0x002e;
					case CHARSETS.colon.has(c) ? c : null:
						return 0x003a;
					case CHARSETS.semicolon.has(c) ? c : null:
						return 0x003b;
					case CHARSETS.apostrophe.has(c) ? c : null:
						return 0x0027;
					case CHARSETS.quote.has(c) ? c : null:
						return 0x0022;
					case CHARSETS.left_parenthesis.has(c) ? c : null:
						return 0x0028;
					case CHARSETS.right_parenthesis.has(c) ? c : null:
						return 0x0029;
					case CHARSETS.exclamation_mark.has(c) ? c : null:
						return 0x0021;
					case CHARSETS.question_mark.has(c) ? c : null:
						return 0x003f;
					case CHARSETS.ellipsis.has(c) ? c : null:
						return '*'; //A workaround
					default:
						return c;
				}
			})
			.map(c => (typeof c == 'number' ? String.fromCharCode(c) : '...'))
			.join('');
		return isGSM(parsed_text) ? parsed_text : text;
	},

	getError(error) {
		throw {
			error: true,
			data: error.response.data,
			statusText: error.response.statusText,
			statusCode: error.response.status,
		};
	},
	directDownloadFile(file_name, file_type, linkSource) {
		let download_link = ""
		if (file_name.includes(file_type)){
			download_link = file_name
		} else{
			download_link = file_name + "." + file_type
		}
		const downloadLink = document.createElement('a');
		document.body.appendChild(downloadLink);
		downloadLink.href = linkSource;
		downloadLink.target = '_self';
		downloadLink.download = download_link;
		downloadLink.click();
	}
};

/* Check if the text can be GSM encoded */
const isGSM = text => [...text].map(char => char.codePointAt(0)).every(c => c && CHARSETS.gsm.has(c));
