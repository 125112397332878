<template>
<div @click='menuItemClicked()' class="sidebar-div">
	<component
		:is="item.type || 'a'"
		:href="item.href"
		:to="item.link"
		class="component-container"
		:target="item.target">
			<div class="main-menu-icon" >
				<i :class="item.icon"></i>
			</div>
			<div class="main-menu-text" v-if="!is_menu_collapsed" >
				<span class="menu-text"> {{item.name}} </span>
			</div>
			<div class="main-menu-plus" > 
				<i v-if="item.children && !is_menu_collapsed"
				:class="{'fa-plus-circle':!item_is_open, 'fa-minus-circle':item_is_open}" 
				class="fa fa-2x plus-minus"></i>
			</div>
	</component>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: "SidebarItem",
	props: ['item', 'index'],
	computed: {
		...mapGetters(['is_menu_collapsed']),
		item_is_open: function(){
			return this.item.isOpen;
		}
	},
	methods: {
		menuItemClicked() {
			if(this.item.isOpen != undefined){
				this.$emit('itemClicked', this.item, this.index);
			}
			else{
				this.$emit('itemChildClicked', this.item.id);
			}
		},
	},
}
</script>

<style scoped>
.sidebar-div {
	border-bottom: 1px solid #192320;
	height: 46px;
}
.sidebar-div a {
	text-decoration: none;
	color: rgb(56, 56, 56);
}

.sidebar-div:hover,
.active {
	background-color: var(--ac-highlight-color);
	color: white !important;
}

.active a {
	color: white !important;
}

.sidebar-div .active a {
	color: white;
}

.sidebar-div:hover a {
	color: white;
}

.component-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
}

.main-menu-icon {
	width: 45px;
	padding: 0 15px;
	font-size: 0.8rem;
	text-align: center;
	float: 1;
	margin-top: 10px;
}

.main-menu-text {
	margin: 0 15px;
	text-align: left;
	flex: 3;
	white-space: nowrap;
	margin-top: 10px;
}

.main-menu-plus {
	padding: 0 5px;
	font-size: 0.8rem;
	text-align: right;
	flex: 1;
	margin-top: 10px;
}

.menu-text {
	vertical-align: middle;
	vertical-align: -webkit-baseline-middle;
	vertical-align: -moz-middle-with-baseline;
}

.sidebar-active-item a {
	color: white;
}

.sidebar-item {
	background: var(--ac-highlight-pale-color);
}
.parent-incident-sidebar-item:hover {
	background-color: var(--ac-incident-highlight-color);
	color: white;
}

.sidebar-active-item {
	background-color: var(--ac-highlight-color);
	color: white ;
}

.incident-sidebar-item {
	background-color: var(--ac-incident-pale-color);
	color: white ;
}

.active-incident-sidebar-item {
	background-color: var(--ac-incident-highlight-color);
	color: white ;
}

.active-incident-sidebar-item a{
	color: white ;
}

.incident-sidebar-item:hover {
	background-color: var(--ac-incident-highlight-color);
	color: white ;
}
</style>
