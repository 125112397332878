import store from '@/store/store.js'

const state = {
	active_item: 1,
	menu_items: [],
};

const getters = {
	active_item: (state) => state.active_item,
	menu_items: (state) => state.menu_items
};

const actions = {
	set_active_item({commit}, new_val){
		commit('setActiveItem', new_val);
	},
	init_menu_items({commit}) {
		commit('initMenuItems')
	},
	collapse_sidebar_item({commit}, new_val){
		commit('collapseSidebarItem', new_val)
	}
};

const mutations = {
	setActiveItem: (state, new_val) => {
		state.active_item = new_val;
	},
	initMenuItems: (state) => {
		state.menu_items = createMenuItems()
		let active_item = false
		state.menu_items.forEach(element => {
			if(element.children){
				element.visible = false;
				element.children.forEach(child => {
					child.visible = checkPermissions(
						child.required_customer_permissions,
						child.required_root_cg_permissions,
						child.is_temporary_user,
						child.extra_functions,
						child.required_root_cg_permissions_noticeboard,
					)
					if(child.visible) {
						element.visible = true;
					}
					if(!active_item){
						active_item = setActiveItem(child)
					}
				})
			}
			if(!active_item){
				active_item = setActiveItem(element);
			}
			element.visible = checkPermissions(
				element.required_customer_permissions,
				element.required_root_cg_permissions,
				element.is_temporary_user,
				element.extra_functions
			)

			element.isOpen = window.location.pathname.includes(element.url_name) ? true : false;
		});
		store.dispatch('set_active_item', active_item);
	},
	collapseSidebarItem:(state, new_val) => {
		for(let i in state.menu_items){
			if(state.menu_items[i].id == new_val.sidebar_parent_id){
				state.menu_items[i].isOpen = true;
			}
		}
		store.dispatch('set_active_item', new_val.sidebar_child_id);
	}
};

//  ---------------------------------HELPER FUNCTIONS-----------------------------------------

function createMenuItems(){
	return [
		{
			id: 1,
			name: "Dashboard",
			icon: "fa fa-home fa-2x",
			link: "/",
			type: "router-link",
			visible: true,
			required_customer_permissions: ['SENDER', 'OPERATOR', 'DATA', 'CONTACT']
		},
		{
			id: 2,
			name: "Messages",
			url_name: "message",
			icon: "fa fa-bullhorn fa-2x",
			visible: true,
			isOpen: false,
			required_customer_permissions: ['SENDER', 'OPERATOR'],
			children: [
				{
					id: 201,
					name: "Create Message",
					icon: "fa fa-edit fa-2x",
					type: "router-link",
					link: "/message/scenario/add",
					visible: true,
					required_customer_permissions: ['OPERATOR'],
					parent_id: 2,
				},
				{
					id: 202,
					name: "Send Message",
					icon: "far fa-comment fa-2x",
					type: "router-link",
					link: "/message_sending",
					visible: true,
					required_customer_permissions: ['SENDER', 'OPERATOR'],
					parent_id: 2,
				},
				{
					id: 203,
					name: "Audio Noticeboard",
					icon: "fa fa-volume-up fa-2x",
					type: "router-link",
					link: "/audio_noticeboard",
					visible: true,
					required_customer_permissions: ['SENDER', 'OPERATOR'],
					required_root_cg_permissions: ['Audio noticeboard'],
					required_root_cg_permissions_noticeboard: 'Allow Senders to edit content',
					temporary_user_check: true,
					extra_functions: [checkNoticeboardNumbers],
					parent_id: 2,
				},
				{
					id: 204,
					name: "Message Calendar",
					icon: "far fa-calendar fa-2x",
					type: "router-link",
					link: "/message/calendar",
					visible: true,
					required_customer_permissions: ['SENDER', 'OPERATOR'],
					temporary_user_check: true,
					parent_id: 2,
				},
				{
					id: 205,
					name: "Message Reports",
					icon: "fa fa-chart-pie fa-2x",
					type: "router-link",
					link: "/message/sessions",
					visible: true,
					required_customer_permissions: ['SENDER', 'OPERATOR'],
					temporary_user_check: true,
					parent_id: 2
				},
				{
					id: 206,
					name: "Message Templates",
					icon: "fa fa-comment-dots fa-2x",
					type: "router-link",
					link: "/message_managing",
					visible: true,
					temporary_user_check: true,
					required_customer_permissions: ['OPERATOR'],
					parent_id: 2
				},
			]
		},
		{
			id: 3,
			name: store.getters.module_name_plural,
			url_name: "incident",
			icon: "fa fa-exclamation-triangle fa-2x",
			visible: true,
			isOpen: false,
			required_root_cg_permissions: [
				'Show Incident Manager dashboard only',
				'Show Alert Cascade + Incident Manager dashboard'
			],
			required_customer_permissions: ['OPERATOR', 'SENDER'],
			children: [
				{
					id: 301,
					name: "Start " + store.getters.module_name,
					icon: "fa fa-bolt fa-2x",
					type: "router-link",
					link: "/incidents/start",
					visible: true,
					required_root_cg_permissions: [
						'Show Incident Manager dashboard only',
						'Show Alert Cascade + Incident Manager dashboard'
					],
					required_customer_permissions: ['OPERATOR', 'SENDER'],
				},
				{
					id: 302,
					name: store.getters.module_name + " Reports",
					icon: "fa fa-chart-bar fa-2x",
					type: "router-link",
					link: "/incidents/reports",
					visible: true,
					required_root_cg_permissions: [
						'Show Incident Manager dashboard only',
						'Show Alert Cascade + Incident Manager dashboard'
					],
					required_customer_permissions: ['OPERATOR', 'SENDER'],
				},
				{
					id: 303,
					name: store.getters.module_name + " Settings",
					icon: "fa fa-wrench fa-2x",
					type: "router-link",
					link: "/incidents/settings",
					visible: true,
					required_root_cg_permissions: [
						'Show Incident Manager dashboard only',
						'Show Alert Cascade + Incident Manager dashboard'
					],
					required_customer_permissions: ['OPERATOR'],
				}
			]
		},
		{
			id: 4,
			name: 'Manage Integrations',
			url_name: "integration",
			icon: "fa fa-puzzle-piece fa-2x",
			visible: true,
			isOpen: false,
			required_root_cg_permissions: ['Use Microsoft Teams'],
			children: [
				{
					id: 401,
					name: "Microsoft Teams",
					icon: "fa fa-users fa-2x",
					type: "router-link",
					link: "/integration/microsoft_teams",
					visible: true,
					required_root_cg_permissions: ['Use Microsoft Teams'],
				},
			]
		},
		{
			id: 5,
			name: "Manage Users",
			icon: "fa fa-users fa-2x",
			link: "/users",
			type: "router-link",
			visible: true,
			required_customer_permissions: ['DATA']
		},
		{
			id: 6,
			name: "Manage Datasets",
			icon: "fa fa-cogs fa-2x",
			link: "/datasets",
			type: "router-link",
			visible: true
		},
		{
			id: 7,
			name: "Media Library",
			icon: "far fa-clipboard fa-2x",
			link: "/media_library/",
			type: "router-link",
			visible: true,
			required_customer_permissions: ['CONTACT', 'OPERATOR', 'DATA'],
			required_root_cg_permissions: ['Media library'],
			temporary_user_check: true,
		},
		{
			id: 8,
			name: "Help and Support",
			icon: "fa fa-question fa-2x",
			link: "/help/",
			type:"router-link",
			visible: true,
			required_customer_permissions: ['SENDER', 'OPERATOR', 'DATA'],
		},
		{
			id: 9,
			name: "Contact Support",
			icon: "fas fa-user-headset fa-2x",
			link: "/contact-support/",
			type: "router-link",
			visible: true,
			required_customer_permissions: ['SENDER', 'OPERATOR', 'DATA', 'CONTACT']
		},
		{
			id: 10,
			name: "Activity Log",
			icon: "fas fa-clipboard-list fa-2x",
			link: "/activity_log/",
			type: "router-link",
			visible: true,
		},
		{
			id: 11,
			name: "Custom Branding",
			icon: "fas fa-eye fa-2x",
			link: "/custom_branding/",
			type: "router-link",
			visible: true,
			required_root_cg_permissions: ['Custom dashboard branding'],
		},
	]
}

function checkNoticeboardNumbers(){
	const customer_group_settings = JSON.parse(store.getters.current_CG.customer_group.settings)
	return customer_group_settings.noticeboard_numbers && customer_group_settings.noticeboard_numbers.length > 0;
}

function checkPermissions(
	required_customer_permissions = [],
	required_root_cg_permissions = [],
	temporary_user_check, extra_functions = [],
	required_root_cg_permissions_noticeboard = ''
){
	let has_root_cg_permissions = true;
	let has_required_cg_permissions = false;

	if(temporary_user_check && store.getters.logged_user.is_temporary){
		return false;
	}

	required_customer_permissions = required_customer_permissions.concat(['ADMIN'])

	for(let i in required_customer_permissions){
		if(store.getters.logged_user.customer_permission == required_customer_permissions[i]){
			has_required_cg_permissions = true; break
		}
	}

	for(let i in required_root_cg_permissions){
		has_root_cg_permissions = store.getters.current_CG.root_customer_group_permissions.includes(required_root_cg_permissions[i])
		if(has_root_cg_permissions) break;
	}

	for(let i in extra_functions){
		if(!extra_functions[i]()) {return false};
	}

	if (store.getters.logged_user.customer_permission == 'SENDER' && required_root_cg_permissions_noticeboard){
		has_root_cg_permissions = store.getters.current_CG.root_customer_group_permissions.includes(required_root_cg_permissions_noticeboard)
		if (!has_root_cg_permissions) {return false}
	}
	return has_required_cg_permissions && has_root_cg_permissions;
}

function setActiveItem(element){
	// If we are coming from angular to the dashboard, set Dashboard item as active
	if(element.id == 1 && window.location.pathname == '/ui/'){
		return 1;
	}
	// If we are coming from angular to the other items (not the dashboard) set the corresponding item as active
	if(element.link == '/'){
		return false;
	}
	let text = element.href ? element.href : element.link;
	return window.location.pathname.includes(text) ? element.id : false;
}

export default {state, getters, actions, mutations}
