<template>
	<div>
		<div
			id="mobile-dark-div"
			:class="{
				'black-hide': is_menu_collapsed,
				'black-show': !is_menu_collapsed,
			}"
		></div>
		<div
			id="sidebar-wrapper"
			:class="{
				'sidebar-collapsed': is_menu_collapsed,
				'sidebar-full': !is_menu_collapsed,
			}"
			class="column justify-between"
		>
			<div class="sidebar-list">
				<ul id="sidebar-nav">
					<li
						v-for="(item, index) in menu_items"
						:key="item.id"
						v-show="item.visible"
					>
						<SidebarItem
							:class="getSidebarClasses(item.id)"
							:index="index"
							:item="item"
							@itemClicked="itemClicked"
						/>
						<transition name="slideDown">
							<ul v-show="item.isOpen">
								<li
									v-for="child in item.children"
									:key="child.id"
									v-show="child.visible"
								>
									<SidebarItem
										:class="getSidebarClasses(item.id, child.id)"
										:item="child"
										@itemClicked="itemClicked"
										@itemChildClicked="itemChildClicked"
									/>
								</li>
							</ul>
						</transition>
					</li>
				</ul>
			</div>
		<div class="q-pl-xs">
			Version {{ version_number }}
		</div>
		</div>
	</div>
</template>

<script>
import SidebarItem from "@/components/utils/SidebarItem.vue";

import { mapGetters, mapActions } from "vuex";

export default {
	name: "SideBar",
	components: {
		SidebarItem,
	},
	data() {
		return {
			collapsed: false,
			sidebar_classes: {
				"active-sidebar-item": ["sidebar-active-item"],
				"active-sidebar-incident-item": ["active-incident-sidebar-item", "incident-sidebar-item",],
				"sidebar-incident-item": ["incident-sidebar-item"],
				"active-sidebar-parent-item": ["active"],
				"sidebar-item": ["sidebar-item"],
				"parent-sidebar-item": null,
				"parent-incident-sidebar-item": ["parent-incident-sidebar-item"],
				"active-parent-sidebar-item": ["active"],
			},
		};
	},
	methods: {
		...mapActions(["set_active_item", "init_menu_items"]),
		itemClicked(item, index) {
			let current_active_item = this.active_item
			if(!item.children){
				this.hideSidebar();
			}
			item.isOpen = !item.isOpen;
			this.$forceUpdate();
			this.$set(this.menu_items, index, item);
			if (!item.children) {
				this.set_active_item(item.id);
			}

			if(item.keep_current_active_item){
				this.set_active_item(current_active_item);
			}
		},
		itemChildClicked(child_id) {
			this.hideSidebar();
			this.set_active_item(child_id);
		},
		hideSidebar() {
			if (window.innerWidth < 769) {
				this.$store.commit("toggleMenu", true);
			}
		},
		getSidebarClasses(parent_id, id) {
			if (!id && parent_id === this.active_item) {
				return this.sidebar_classes["active-parent-sidebar-item"];
			} else if (!id && parent_id === 3) {
				return this.sidebar_classes["parent-incident-sidebar-item"];
			} else if (!id) {
				return this.sidebar_classes["parent-sidebar-item"];
			} else if (id === this.active_item && ![301, 302, 303].includes(this.active_item)) {
				return this.sidebar_classes["active-sidebar-item"];
			} else if (id === this.active_item) {
				return this.sidebar_classes["active-sidebar-incident-item"];
			} else if (parent_id === 3) {
				return this.sidebar_classes["sidebar-incident-item"];
			} else {
				return this.sidebar_classes["sidebar-item"];
			}
		},
	},
	computed: {
		...mapGetters([
			"is_menu_collapsed",
			"version_number",
			"logged_user",
			"current_CG",
			"active_item",
			"menu_items",
		]),
	},
	created() {
		this.init_menu_items();
		this.hideSidebar();
	},
};
</script>

<style lang="scss" scoped>
#sidebar-wrapper {
	position: fixed;
	left: 0;
	text-align: left;
	padding-left: 0px;
	height: calc(100vh - 80px);
	overflow-y: auto;
	background: #fff;
	z-index: 7;
	border-right: 1px solid $grey-4;
}

.sidebar-list{
	max-height: 750px;
	overflow-y: auto;
}
#sidebar-wrapper,
#sidebar-wrapper ul,
#sidebar-wrapper li,
#sidebar-wrapper a {
	margin: 0;
	padding: 0;
}

#sidebar-wrapper li {
	cursor: pointer;

	/* workaround to make it work for MS browsers */
	width: 100%;
	display: inline-block;
}

.sidebar-full {
	width: 250px;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.8s ease;
}

.sidebar-collapsed {
	width: 60px;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

#sidebar-nav li a {
	color: rgb(56, 56, 56);
	text-decoration: none;
	border-bottom: 1px solid #192320;
	padding: 10px 0px;
}

#sidebar-nav li ul li a:hover {
	color: white;
}

@media (max-width: 768px) {
	.sidebar-full {
		background: #192320;

		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.8s ease;
	}

	.sidebar-collapsed {
		width: 0px;

		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
	}

	.plus-minus {
		position: fixed;
		margin-left: 85px;
	}

	#mobile-dark-div {
		background: rgba(0, 0, 0, 0.5);
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 6;
	}

	.black-show {
		width: 100%;
		transition: all 1.4s ease;
	}

	.black-hide {
		width: 0%;
		transition: all 0.6s ease;
	}

	.sidebar-list{
		max-height: 600px;
	}
}

/* TRANSITIONS */
.slideDown-enter-active,
.slideDown-leave-active {
	transition: all 0.5s ease-in-out;
}

.slideDown-enter-to,
.slideDown-leave {
	overflow: hidden;
	max-height: 300px;
}

.slideDown-enter,
.slideDown-leave-to {
	max-height: 0px;
	overflow: hidden;
}
</style>
