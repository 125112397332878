const state = {
	message_reports_errors: ""
};

const getters = {
	message_reports_errors: (state) => state.message_reports_errors
};

const actions = {
	set_message_reports_errors({commit}, error){
		commit('setMessageReportsErrors', error)
	}
};

const mutations = {
	setMessageReportsErrors: (state, new_val) => (state.message_reports_errors = new_val),
};

export default {state, getters, actions, mutations}
