import authAPI from '@/services/api/auth.js';
import store from '@/store/store.js';

const state = {
	logged_user: {},
	customer_group: {
		customer_group: {},
		root_customer_group_permissions: []
	},
	settings: {
		module_name: 'Incident',
		module_name_plural: 'Incidents',
	},
	version_number: 0,
	customer_groups: [],
	descendant_users: [],
	token_expiry: '',
	descendant_user_ids: [],
	customer_group_ids: [],
	has_children: false,
	show_main_layout: true,
};

const getters = {
	logged_user: (state) => state.logged_user,
	current_CG: (state) => state.customer_group,
	module_name_settings: (state) => state.settings,
	module_name: (state) => state.settings.module_name,
	module_name_plural: (state) => state.settings.module_name_plural,
	version_number: (state) => state.version_number,
	customer_groups: (state) => state.customer_groups,
	descendant_users: (state) => state.descendant_users,
	token_expiry: (state) => state.token_expiry,
	descendant_user_ids: (state) => state.descendant_user_ids,
	customer_group_ids: (state) => state.customer_group_ids,
	has_children: (state) => state.has_children,
	is_root_cg: (state) => state.logged_user.customer_group === state.customer_group.customer_group.root_parent_id,
	root_cg_id: (state) => state.customer_group.customer_group.root_parent_id,
	show_main_layout: state => state.show_main_layout
};

const actions = {
	updateShowMainLayout( {commit}, show_main_layout) {
		commit('setShowMainLayout', show_main_layout)
	},
	async fetchUser( {commit} ) {
		const response = await authAPI.getLoggedUser();
		let user = response.data;
		if(user) {
			if(!user.preferred_timezone){
				user.preferred_timezone = 'Europe/London';
			}
			user.preferred_timezone_preview = "Timezone: " + user.preferred_timezone;
		}

		commit('updateUser', user);
	},

	async fetchCustomerGroup({ commit }) {
		const response = await authAPI.getCurrentCustomerGroup();
		commit('updateCG', response.data);
	},

	async fetchCustomerGroupIMSettings({ commit }) {
		const rcg = store.getters.root_cg_id;
		const settings = await authAPI.getIMSettingsForRCG(rcg);
		commit('updateSettings', settings.data);
	},

	async fetchVersionNumber( {commit} ) {
		const response = await authAPI.getVersionNumber();
		commit('updateVersionNumber', response.data['version_number'])
	},

	async fetchTokenExpiry( {commit} ) {
		const response = await authAPI.getTokenExpiryTime();
		commit('updateTokenExpiryTime', response.data['token_expiry'])
	},

	async logout( {commit}, inactivity=false ) {
		await authAPI.logout(inactivity);
		localStorage.removeItem("auth-token");
		if (this.getters.current_CG.root_customer_group_permissions.indexOf("Custom dashboard branding") >= 0) {
			window.location.href = "/ui/" + this.getters.current_CG.customer_group.brand_login_url
		} else {
			window.location.href = "/ui/login"
		}
		commit('cleartUser')
		commit('clearCG')
	},
	async getCustomerGroups( {commit} ) {
		let response = await authAPI.getCustomerGroups()
		response.data.user = state.logged_user
		commit('updateCustomerGroups', response.data)
		store.dispatch('buildTree');
		for (let element in response.data) {
			if (store.getters.current_CG.customer_group.id == response.data[element]['parent']) {
				commit('setHasChildren', true)
				break
			}
		}
	},


	async getUsersIds( {commit} ) {
		const response = await authAPI.getUsersIds();

		commit('updateUsersIds', response.data);
	},

	async getCustomerGroupIds( {commit} ) {
		const response = await authAPI.getCustomerGroupIds();
		commit('updateCustomerGroupIds', response.data);
	}
};

const mutations = {
	updateUser: ( state, logged_user ) => state.logged_user = logged_user,
	updateCG: ( state, current_CG ) => state.customer_group = current_CG,
	updateSettings: (state, settings) => (state.settings = settings),
	updateVersionNumber: ( state, versionNumber ) => state.version_number = versionNumber,
	cleartUser: (state) => state.logged_user = {},
	clearCG: (state) => state.customer_group = {customer_group: {}},
	updateCustomerGroups: (state, customer_groups) => state.customer_groups = customer_groups,
	updateDescendantUsers: (state, descendant_users) => state.descendant_users = descendant_users,
	updateTokenExpiryTime: (state, tokenExpiryTime) => state.token_expiry =  tokenExpiryTime,
	updateUsersIds: (state, descendant_user_ids) => state.descendant_user_ids =descendant_user_ids,
	updateCustomerGroupIds: (state, customer_group_ids) => state.customer_group_ids = customer_group_ids,
	setHasChildren: (state, has_children) => state.has_children = has_children,
	setShowMainLayout: (state, val) => state.show_main_layout = val
};

export default {
	state,
	getters,
	actions,
	mutations
}
