import axios from 'axios';
export default {
	async uploadFile(file_data, file) {
		let form_data = new FormData()
		form_data.append("file", file);
		form_data.append('file_data', JSON.stringify(file_data));
		let response = await axios.post('/api/incidents/incident/upload/', form_data,{
			headers: {
				'Content-Type': undefined
			}
		});
		return response.data;
	},
	async uploadFileTemplate(file_data, file) {

		let form_data = new FormData()
		form_data.append("file", file);
		form_data.append('file_data', JSON.stringify(file_data));
		let response = await axios.post(`/api/incidents/upload_attachment_from_template/`, form_data)
		return response.data;
	},

	uploadFileS3(url, fields, file) {

		for(let field in fields){
			const xhr = new XMLHttpRequest();
			xhr.open('POST', url, true);
			let postData = new FormData();
			for (let key in fields[field]) {
				postData.append(key, fields[field][key]);
			}

			postData.append('file', file[field]);
			xhr.send(postData);
		}
	},
};
