import lexorder from "@/services/lexorder.js";
import RulesAPI from '@/services/api/Rules.js';
import axios from 'axios';

const state = {
	rules: [],
};

const getters = {
	rules: state => state.rules,
};

const actions = {
	async listRules({ commit }, id) {
		const rules = await RulesAPI.getRules(id);
		commit('getRules', rules);
	},

	async createRule({ commit }, rule) {
		try {
			rule.order = lexorder.generate(state.rules);
			const response = await axios.post(`/api/incidents/form/${rule.form}/rules/`, rule);
			commit('createRule', new_rule);
		}catch (error) {
			const response = {
				error: true,
				error_message: error.response.data.message,
				error_field: error.response.data.for_field,
				error_field_type: error.response.data.type,
				error_field_value: error.response.data.value
			};
			return response;
		}
	},

	async updateRules({ commit }, rule) {
		try {
			let response = await axios.put(`/api/incidents/form/${rule.form}/rules/${rule.id}/`, rule);
			commit('updateRules', response);
			return response;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data.message,
				error_field: error.response.data.for_field,
				error_field_type: error.response.data.type,
				error_field_value: error.response.data.value
			};
			return response;
		}
	},

	async deleteRule({ commit }, rule) {
		await RulesAPI.deleteRule(rule)
		commit('deleteRule', rule.id);
	},

	async resetRule({ commit }){
		commit('resetRule');
	},

	async patchRule({ commit }, { rule, order }) {
		try {
			const response = await RulesAPI.patchRule(rule, order);
			rule.order = order
			commit('updateRules', rule)
			return response;
		} catch (error) {
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data.message,
				error_field: error.response.data.for_field,
				error_field_value: error.response.data.value
			};
			return response;
		}
	},
};

const mutations = {
	getRules: (state, rules) => (state.rules = rules),
	createRule: (state, rule) => state.rules.push(rule),
	updateRules: (state, rule) => {
		const index = state.rules.findIndex(r => r.id == rule.id);
		if (index !== -1) {
			state.rules.splice(index, 1, rule);
		}
	},
	deleteRule: (state, id) => (state.rules = state.rules.filter(rule => rule.id != id)),
	resetRule: state => (state.rules = []),
};

export default {
	state,
	getters,
	actions,
	mutations,
};
