import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/store.js';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path:'/',
			name: 'Dashboard',
			component: () => import('./views/Dashboard.vue'),
			meta: {
				requires_auth: true, // All Logged in users
			},
			children: [
				{
					path: 'incident-manager',
					name: 'Dashboard Incident Manager',
					component: () => import('./views/dashboard/DashboardIncidentManager.vue')
				},
				{
					path: 'messages',
					name: 'Dashboard Messages',
					component: () => import('./views/dashboard/DashboardMessages.vue')
				},
				{
					path: 'profile',
					name: 'Dashboard Profile',
					component: () => import('./views/dashboard/DashboardProfile.vue')
				}
			]
		},
		{
			path:'/impersonate_login/:token',
			name: 'Impersonate Login',
			component: () => import('./views/ImpersonateLogin.vue'),
			meta: {
				requires_auth: false
			},
		},
		{
			path: '/change_password',
			name: 'Change Password',
			component: () => import('./views/ChangePassword.vue'),
			meta: {
				requires_auth: true, // All Logged in users
			},
		},
		{
			path: '/message/scenario/add',
			name: 'Create Message',
			component: () => import('./views/CreateMessage.vue'),
			meta: {
				requires_admin_operator: true, //ADMIN, OPERATOR
			},
			redirect: { name: 'Message Settings' },
			children: [
				{
					path: 'settings',
					name: 'Message Settings',
					component: () => import('./components/message_forms/settings_tab/MessageFormSettings.vue')
				},
				{
					path: 'recipients',
					name: 'Message Recipients',
					component: () => import('./components/message_forms/recipients_tab/MessageFormRecipients.vue')
				},
				{
					path: 'devices',
					name: 'Message Devices',
					component: () => import('./components/message_forms/devices_tab/MessageFormDevices.vue')
				},
				{
					path: 'sequence',
					name: 'Message Sequence',
					component: () => import('./components/message_forms/sequence_tab/MessageFormSequence.vue')
				},
				{
					path: 'content',
					name: 'Message Content',
					component: () => import('./components/message_forms/content_tab/MessageFormContent.vue')
				},
				{
					path: 'noticeboard',
					name: 'Message Noticeboard',
					component: () => import('./components/message_forms/audio_noticeboard_tab/MessageFormNoticeboard.vue')
				},
				{
					path: 'trigger',
					name: 'Message Trigger',
					component: () => import('./components/message_forms/trigger_tab/MessageFormTrigger.vue')
				},
				{
					path: 'finish',
					name: 'Message Finish',
					component: () => import('./components/message_forms/finish_tab/MessageFormFinish.vue')
				}
			]
		},
		{
			path: '/message_sending/scenario/edit/:id',
			name: 'Edit Message',
			component: () => import('./views/EditMessage.vue'),
			meta: {
				requires_admin_operator_sender: true, //ADMIN, OPERATOR, SENDER
			},
			children: [
				{
					path: 'settings',
					name: 'Edit Message Settings',
					component: () => import('./components/message_forms/settings_tab/MessageFormSettings.vue')
				},
				{
					path: 'recipients',
					name: 'Edit Message Recipients',
					component: () => import('./components/message_forms/recipients_tab/MessageFormRecipients.vue')
				},
				{
					path: 'devices',
					name: 'Edit Message Devices',
					component: () => import('./components/message_forms/devices_tab/MessageFormDevices.vue')
				},
				{
					path: 'sequence',
					name: 'Edit Message Sequence',
					component: () => import('./components/message_forms/sequence_tab/MessageFormSequence.vue')
				},
				{
					path: 'content',
					name: 'Edit Message Content',
					component: () => import('./components/message_forms/content_tab/MessageFormContent.vue')
				},
				{
					path: 'noticeboard',
					name: 'Edit Message Noticeboard',
					component: () => import('./components/message_forms/audio_noticeboard_tab/MessageFormNoticeboard.vue')
				},
				{
					path: 'trigger',
					name: 'Edit Message Trigger',
					component: () => import('./components/message_forms/trigger_tab/MessageFormTrigger.vue')
				},
				{
					path: 'finish',
					name: 'Edit Message Finish',
					component: () => import('./components/message_forms/finish_tab/MessageFormFinish.vue')
				}
			]
		},
		{
			path: '/message_sending',
			name: 'Send Message',
			component: () => import('./views/SendMessage.vue'),
			meta: {
				requires_admin_operator_sender: true, //ADMIN, OPERATOR, SENDER
			},
		},
		{
			path: '/audio_noticeboard',
			name: 'Audio Noticeboard',
			component: () => import('./views/AudioNoticeboard.vue'),
			meta: {
				requires_audio_noticeboard_sender_permission: true, //ADMIN, OPERATOR, SENDER + Audio Noticeboard permission
			},
		},
		{
			path: '/audio_noticeboard/add',
			name: 'Create Audio Noticeboard',
			component: () => import('./views/AudioNoticeboardCreateOrUpdate.vue'),
			meta: {
				requires_audio_noticeboard_permission: true, //ADMIN, OPERATOR + Audio Noticeboard permission
			},
		},
		{
			path: '/audio_noticeboard/:id',
			name: 'Update Audio Noticeboard',
			component: () => import('./views/AudioNoticeboardCreateOrUpdate.vue'),
			meta: {
				requires_audio_noticeboard_sender_permission: true, //ADMIN, OPERATOR, SENDER + Audio Noticeboard permission
			},
		},
		{
			path: '/message/calendar',
			name: 'Message Calendar',
			component: () => import('./views/MessageCalendar.vue'),
			meta: {
				requires_admin_operator_sender: true, //ADMIN, OPERATOR, SENDER
			},
		},
		{
			path: '/message/calendar/schedule_message',
			name: 'Schedule Message',
			component: () => import('./views/ScheduleCalendarMessage.vue'),
			meta: {
				requires_admin_operator: true, //ADMIN, OPERATOR, SENDER
			}
		},
		{
			path: '/message/sessions',
			name: 'Message Reports',
			component: () => import('./views/MessageReports.vue'),
			meta: {
				requires_admin_operator_sender: true, //ADMIN, OPERATOR, SENDER
			},
		},
		{
			path: '/message/sessions/:id/dispatch_details',
			name: 'Dispatch Details',
			component: () => import('./views/DispatchDetails.vue'),
			meta: {
				requires_session_report_permission: true, //ADMIN, OPERATOR if Operator Extended Access or Operator Extended Access Address String
			},
		},
		{
			path: '/message/sessions/:id/dispatches_overview',
			name: 'Dispatch Overview',
			component: () => import('./views/DispatchOverview.vue'),
			meta: {
				requires_admin_operator_sender: true, //ADMIN, OPERATOR, SENDER
			},
		},
		{
			path: '/message_managing',
			name: 'Message Templates',
			component: () => import('./views/MessageTemplates.vue'),
			meta: {
				requires_admin_operator: true, //ADMIN, OPERATOR
			},
		},
		{
			path: '/incident-template',
			name: 'incident-template',
			component: () => import('./views/IncidentTemplates.vue'),
			meta: {
				requires_auth: false, // public
			},
		},
		{
			path: '/incidents/start',
			name: 'Start',
			component: () => import('./views/incidents/start/Start.vue'),
			meta: {
				requires_incident_permissions: true, // ADMIN, OPERATOR, SENDER + Incident Permissions
			},
		},
		{
			path: '/incidents/start/create',
			name: 'Create Incident',
			component: () => import('./views/incidents/start/IncidentCreate.vue'),
			meta: {
				requires_incident_permissions: true, // ADMIN, OPERATOR, SENDER + Incident Permissions
			},
		},
		{
			path: '/incidents/start/:id/edit',
			name: 'Edit Incident',
			component: () => import('./views/incidents/start/IncidentEdit.vue'),
			meta: {
				requires_incident_permissions: true, // ADMIN, OPERATOR, SENDER + Incident Permissions
			},
		},
		{
			path: '/incidents/reports',
			name: 'Reports',
			component: () => import('./views/Reports.vue'),
			meta: {
				requires_incident_permissions: true, // ADMIN, OPERATOR, SENDER + Incident Permissions
			},
		},
		{
			path: '/incidents/settings',
			name: 'Incident Settings',
			component: () => import('./views/Settings.vue'),
			meta: {
				requires_incident_permissions: true, // ADMIN, OPERATOR, SENDER + Incident Permissions
			},
			children: [
				{
					path: 'fields',
					name: 'Incident Fields',
					component: () => import('./components/incidents/fields/FieldsList.vue'),
				},
				{
					path: 'forms',
					name: 'Incident Forms',
					component: () => import('./components/incidents/forms/FormsList.vue'),
				},
				{
					path: 'rules',
					name: 'Incident Rules',
					component: () => import('./components/incidents/rules/RulesList.vue'),
				},
				{
					path: 'templates',
					name: 'Incident Templates',
					component: () => import('./components/incidents/templates/IncidentTemplateList.vue'),
				},
			]
		},
		{
			path: '/incidents/settings/forms/:id/edit',
			name: 'Edit Form',
			component: () => import('./views/incidents/settings/EditForm.vue'),
			meta: {
				requires_incident_permissions: true, // ADMIN, OPERATOR, SENDER + Incident Permissions
			},
		},
		{
			path: '/incidents/settings/rules/:id/edit',
			name: 'Edit Rule',
			component: () => import('./components/incidents/rules/RuleEdit.vue'),
			meta: {
				requires_incident_permissions: true, // ADMIN, OPERATOR, SENDER + Incident Permissions
			},
		},
		{
			path: '/user_import_on_call',
			name: 'User On Call Import',
			component: () => import('./components/manage_users/ImportOnCallUser.vue'),
			meta: {
				requires_admin_operator: true, //ADMIN, OPERATOR
			},
		},
		{
			path: '/user_import',
			name: 'User Import',
			component: () => import('./components/manage_users/ImportUser.vue'),
			meta: {
				requires_admin_data: true, //ADMIN, DATA
			},
		},
		{
			path:'/media_library',
			name: 'Media Library',
			component: () => import('./views/MediaLibrary.vue'),
			meta: {
				requires_media_library_permission: true, // ADMIN, OPERATOR, SENDER + Media library permission
			},
		},
		{
			path: '/integration/microsoft_teams',
			name: 'Microsoft Teams',
			component: () => import('./views/MicrosoftTeams.vue'),
			redirect: { name: 'Teams Webhooks List' },
			meta: {
				admins_only: true, //Only admins
			},
			children: [
				{
					path: 'list',
					name: 'Teams Webhooks List',
					component: () => import('./views/integrations/teams/WebhookTeamsList.vue')
				},
				{
					path: 'reports',
					name: 'Teams Webhooks Reports',
					component: () => import('./views/integrations/teams/WebhookTeamsReports.vue')
				},
			]
		},
		{
			path: '/integration/slack',
			name: 'Slack',
			component: () => import('./views/Slack.vue'),
			redirect: {name: 'Slack Webhooks List'},
			meta: {
				admins_only: true,
			},
			children: [
				{
					path: 'list',
					name: 'Slack Webhooks List',
					component: () => import('./views/integrations/slack/WebhookSlackList.vue')
				},
				{
					path: 'reports',
					name: 'Slack Webhooks Reports',
					component: () => import('./views/integrations/slack/WebhookSlackReports.vue')
				},
			]
		},
		{
			path: '/help',
			name: 'Help & Support',
			component: () => import('./views/HelpAndSupport.vue'),
			meta: {
				requires_admin_operator_sender_data: true, //ADMIN, OPERATOR, SENDER, DATA
			},
		},
		{
			path: '/help/:id/details/:story?',
			name: 'Help & Support / Details',
			component: () => import('./components/help_and_support/HelpItemDetails.vue'),
			meta: {
				requires_admin_operator_sender_data: true, //ADMIN, OPERATOR, SENDER, DATA
			},
		},
		{
			path: '/contact-support',
			name: 'Contact Support',
			component: () => import('./views/ContactSupport.vue'),
			meta: {
				requires_auth: true, // All Logged in users
			},
		},
		{
			path:'/activity_log',
			name: 'Activity Log',
			component: () => import('./views/ActivityLog.vue'),
			meta: {
				admins_only: true, // Only admins
			},
		},
		{
			path: '/public-registration/:id/:subdomain',
			name: 'landing',
			component: () => import('./views/PublicRegistration.vue'),
			meta: {
				requires_auth: false, // public
			},
		},
		{
			path: '/public-registration/:id/:subdomain/register',
			name: 'register',
			component: () => import('./views/PublicRegistration.vue'),
			meta: {
				requires_auth: false, // public
			},
		},
		{
			path: '/public-registration/:id/:subdomain/deregister',
			name: 'deregister',
			component: () => import('./views/PublicRegistration.vue'),
			meta: {
				requires_auth: false, // public
			}
		},
		{
			path: '/custom_branding',
			name: 'Custom Branding',
			component: () => import('./views/custom_branding/CustomBranding.vue'),
			redirect: { name: 'Custom Branding Logo' },
			meta: {
				admins_only: true, // Only admins
			},
			children: [
				{
					path: 'logo',
					name: 'Custom Branding Logo',
					component: () => import('./views/custom_branding/tabs/CustomBrandingLogo.vue')
				},
				{
					path: 'colours',
					name: 'Custom Branding Colours',
					component: () => import('./views/custom_branding/tabs/CustomBrandingColours.vue')
				},
				{
					path: 'contact',
					name: 'Custom Branding Contact',
					component: () => import('./views/custom_branding/tabs/CustomBrandingContact.vue')
				},
				{
					path: 'settings',
					name: 'Custom Branding Settings',
					component: () => import('./views/custom_branding/tabs/CustomBrandingSettings.vue')
				}
			]
		},
		{
			path: '/datasets',
			name: 'Manage Datasets',
			component: () => import('./views/datasets/List.vue'),
			meta: {
				admins_only: true, // Only admins
			},
		},
		{
			path: '/datasets/:id/edit',
			name: 'Edit Dataset',
			redirect: { name: 'Dataset Devices' },
			component: () => import('./views/datasets/edit/Base.vue'),
			meta: {
				admins_only: true, // Only admins
			},
			children: [
				{
					path: 'devices',
					name: 'Dataset Devices',
					component: () => import('./views/datasets/edit/tabs/Devices.vue'),
				},
				{
					path: 'data',
					name: 'Dataset Data',
					component: () => import('./views/datasets/edit/tabs/Data.vue'),
				},
				{
					path: 'settings',
					name: 'Dataset Settings',
					redirect: { name: 'Dataset Settings Routing' },
					component: () => import('./views/datasets/edit/tabs/Settings.vue'),
					children: [
						{
							path: 'outbound-and-inbound-routing',
							name: 'Dataset Settings Routing',
							component: () => import('./views/datasets/edit/tabs/settings/OutboundAndInboundRoutingContent.vue')
						},
						{
							path: 'user-and-maintenance-and-access',
							name: 'Dataset Settings Maintenance',
							component: () => import('./views/datasets/edit/tabs/settings/UserMaintenanceAndAccess.vue')
						},
						{
							path: 'message-sending',
							name: 'Dataset Settings Message Sending',
							component: () => import('./views/datasets/edit/tabs/settings/MessageSending.vue')
						}
					]
				},
				{
					path: 'audit',
					name: 'Dataset Audit',
					component: () => import('./views/datasets/edit/tabs/Audit.vue'),
				},
				{
					path: 'sftp',
					name: 'Dataset Sftp',
					redirect: { name: 'Sftp List' },
					component: () => import('./views/datasets/edit/tabs/Sftp.vue'),
					children: [
						{
							path: 'list',
							name: 'Sftp List',
							component: () => import('./views/datasets/edit/tabs/sftp/List.vue')
						},
						{
							path: 'add/:charIndex',
							name: 'Sftp Add',
							component: () => import('./views/datasets/edit/tabs/sftp/Add.vue')
						}
					]
				},
				{
					path: 'conference',
					name: 'Dataset Conference',
					component: () => import('./views/datasets/edit/tabs/Conference.vue'),
				},
				{
					path: 'on-call',
					name: 'Dataset On Call',
					component: () => import('./views/datasets/edit/tabs/OnCall.vue'),
				},
				{
					path: 'registration',
					name: 'Dataset Registration',
					component: () => import('./views/datasets/edit/tabs/Registration.vue'),
				},
				{
					path: 'registration/:rti_id/edit/:instance_index',
					name: 'Edit RTI',
					redirect: { name: 'Edit RTI Settings' },
					component: () => import('./views/datasets/edit/tabs/registration/RegistrationTemplateInstance.vue'),
					children: [
						{
							path: 'settings',
							name: 'Edit RTI Settings',
							component: () => import('./views/datasets/edit/tabs/registration/RegistrationSettings.vue')
						},
						{
							path: 'fields',
							name: 'Edit RTI Fields',
							component: () => import('./views/datasets/edit/tabs/registration/RegistrationFields.vue')
						},
					]
				},
				{
					path: 'merge-fields',
					name: 'Dataset Merge Fields',
					component: () => import('./views/datasets/edit/tabs/MergeFields.vue'),
				},
			]
		},
		{
			path: '/users',
			name: 'Manage Users',
			component: () => import('./views/users/List.vue'),
			meta: {
				requires_admin_operator_sender_data: true, //ADMIN, OPERATOR, SENDER, DATA
			},
		},
		{
			path: '/users/:id/edit',
			name: 'Edit User',
			redirect: { name: 'User Devices' },
			component: () => import('./views/users/edit/Base.vue'),
			meta: {
				requires_edit_profile: true,
			},
			children: [
				{
					path: 'devices',
					name: 'User Devices',
					component: () => import('./views/users/edit/tabs/Devices.vue'),
				},
				{
					path: 'data',
					name: 'User Data',
					component: () => import('./views/users/edit/tabs/Data.vue'),
				},
				{
					path: 'settings',
					name: 'User Settings',
					component: () => import('./views/users/edit/tabs/Settings.vue'),
				},
				{
					path: 'on_call',
					name: 'User OnCall',
					component: () => import('./views/users/edit/tabs/OnCall.vue'),
				},
			]
		},
		{
			path:'*',
			name: 'Redirect',
			component: () => import('./views/Redirect.vue'),
			meta: {
				requires_auth: true, // All Logged in users
			},
		},
		{
			path: '/reset/:uid/:token',
			name: 'Set Password',
			component: () => import('./views/LoginSetPassword.vue'),
			meta: {
				requires_auth: false, // public
			},
		},
		{
			path: '/login',
			name: 'Login User',
			component: () => import('./views/Login.vue'),
			meta: {
				guest: true,
				requires_auth: false
			},
		},
		{
			path: '/login_2fa',
			name: 'Login User 2FA',
			component: () => import('./views/Login_2FA.vue'),
			meta: {
				requires_auth: false
			},
		},
		{
			path: '/login/reset',
			name: 'Reset Password',
			component: () => import('./views/LoginResetPassword.vue'),
			meta: {
				requires_auth: false, // public
			},
		},
		{
			path: '/login/faq',
			name: 'Can`t Log In',
			component: () => import('./views/CantLogIn.vue'),
			meta: {
				requires_auth: false, // public
			},
		},
		{
			path: '/:brand',
			name: 'Login User Brand',
			component: () => import('./views/CustomLogin.vue'),
			meta: {
				guest: true,
				requires_auth: false
			},
		},
	],
});


async function getLoggedUser() {
	let logged_user = store.getters.logged_user;
	if (!logged_user.id) {
		await store.dispatch('fetchUser');
		logged_user = store.getters.logged_user;
		await store.dispatch('fetchVersionNumber');
		await store.dispatch('fetchTokenExpiry');
		await store.dispatch('fetchCustomerGroupIMSettings')
	}
	return logged_user
}


router.beforeEach(async (to, from, next) => {
	if (to.matched.some(record => record.meta.requires_auth)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()

		if (['ADMIN','OPERATOR','SENDER','CONTACT','DATA'].includes(logged_user.customer_permission)) {
			store.dispatch('updateShowMainLayout', true);
			next();
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}
	} else if (to.matched.some(record => record.meta.requires_admin_operator_sender)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()

		if (['ADMIN','OPERATOR','SENDER'].includes(logged_user.customer_permission)) {
			next();
			store.dispatch('updateShowMainLayout', true);
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}
	} else if (to.matched.some(record => record.meta.requires_admin_operator)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()

		if (['ADMIN','OPERATOR'].includes(logged_user.customer_permission)) {
			next();
			store.dispatch('updateShowMainLayout', true);
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}
	} else if (to.matched.some(record => record.meta.requires_session_report_permission)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()
		const root_cg_permissions = store.getters.current_CG.root_customer_group_permissions;

		if (
			logged_user.customer_permission=='ADMIN' ||
				((logged_user.customer_permission=='OPERATOR') && (
					root_cg_permissions.includes('Allow Operators to view address string (detailed reports)') ||
					root_cg_permissions.includes('Allow Operators to view all info (detailed reports)')
				))
		) {
			store.dispatch('updateShowMainLayout', true);
			next();
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}
	} else if (to.matched.some(record => record.meta.requires_incident_permissions)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()
		const root_cg_permissions = store.getters.current_CG.root_customer_group_permissions;
		if (root_cg_permissions.includes('Show Incident Manager dashboard only') ||
			root_cg_permissions.includes('Show Alert Cascade + Incident Manager dashboard')) {
			if (['Start', 'Reports', 'Create Incident', 'Edit Incident', 'Edit Form'].includes(to.name) &&
				['ADMIN', 'OPERATOR', 'SENDER'].includes(logged_user.customer_permission)){
				store.dispatch('updateShowMainLayout', true);
				next();
			} else if (
				['Incident Settings', 'Incident Fields', 'Incident Forms', 'Incident Rules', 'Edit Rule', 'Incident Templates'].includes(to.name) &&
				['ADMIN', 'OPERATOR'].includes(logged_user.customer_permission)
			) {
				store.dispatch('updateShowMainLayout', true);
				next();
			}
			else {
				store.dispatch('updateShowMainLayout', false);
				store.dispatch('logout');
			}
		}
		else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}
	} else if (to.matched.some(record => record.meta.admins_only)){
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()

		if (['ADMIN'].includes(logged_user.customer_permission)) {
			store.dispatch('updateShowMainLayout', true);
			next();
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}
	} else if(to.matched.some(record => record.meta.requires_media_library_permission)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()
		const root_cg_permissions = store.getters.current_CG.root_customer_group_permissions;

		if (['ADMIN', 'OPERATOR', 'CONTACT', 'DATA'].includes(logged_user.customer_permission) && root_cg_permissions.includes('Media library')) {
			store.dispatch('updateShowMainLayout', true);
			next();
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}

	} else if(to.matched.some(record => record.meta.requires_admin_operator_sender_data)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()

		if (['ADMIN', 'OPERATOR', 'SENDER', 'DATA'].includes(logged_user.customer_permission)) {
			store.dispatch('updateShowMainLayout', true);
			next();
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}

	} else if(to.matched.some(record => record.meta.requires_edit_profile)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()
		const customer_group_settings = JSON.parse(store.getters.current_CG.customer_group.settings)
		if (['ADMIN', 'DATA'].includes(logged_user.customer_permission) ||
			(customer_group_settings.send_verification_email &&
				logged_user.id == to.params.id)
			) {
			store.dispatch('updateShowMainLayout', true);
			next();
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}
	} else if(to.matched.some(record => record.meta.requires_admin_data)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()

		if (['ADMIN', 'DATA'].includes(logged_user.customer_permission)) {
			store.dispatch('updateShowMainLayout', true);
			next();
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}

	} else if(to.matched.some(record => record.meta.requires_admin_data_contact)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()

		if (['ADMIN', 'DATA', 'CONTACT'].includes(logged_user.customer_permission)) {
			store.dispatch('updateShowMainLayout', true);
			next();
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}

	} else if (to.matched.some(record => record.meta.requires_audio_noticeboard_permission)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()
		const root_cg_permissions = store.getters.current_CG.root_customer_group_permissions;

		if (
			['ADMIN', 'OPERATOR'].includes(logged_user.customer_permission) &&
			root_cg_permissions.includes('Audio noticeboard') &&
			!store.getters.current_CG.no_noticeboard_numbers
		) {
			store.dispatch('updateShowMainLayout', true);
			next();
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}
	} else if(to.matched.some(record => record.meta.requires_audio_noticeboard_sender_permission)) {
		await store.dispatch('fetchCustomerGroup');
		const logged_user = await getLoggedUser()
		const root_cg_permissions = store.getters.current_CG.root_customer_group_permissions;

		if (
			(
				['ADMIN', 'OPERATOR'].includes(logged_user.customer_permission) ||
				(
					logged_user.customer_permission == 'SENDER' &&
					store.getters.current_CG.root_customer_group_permissions.includes('Allow Senders to edit content')
				)
			) &&
			root_cg_permissions.includes('Audio noticeboard') &&
			!store.getters.current_CG.no_noticeboard_numbers
		) {
			store.dispatch('updateShowMainLayout', true);
			next();
		} else {
			store.dispatch('updateShowMainLayout', false);
			store.dispatch('logout');
		}
	}else if(to.matched.some(record => record.meta.guest)){
		store.dispatch('updateShowMainLayout', false);
		next();
	}
	else {
		if (to.fullPath.includes('public-registration')) {
			store.dispatch('changePublicRegistrationTemplate', location.host.split('.')[0])
		} else {
			store.dispatch('changeIncidentTemplate', location.host.split('.')[0]);
		}
		store.dispatch('updateShowMainLayout', false);
		next();
	}
});

export default router;
