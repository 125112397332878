const CHARSETS = {
	gsm: new Set([
		0x000a, 0x000c, 0x000d, 0x0020, 0x0021, 0x0022, 0x0023, 0x0024, 0x0025, 0x0026,
		0x0027, 0x0028, 0x0029, 0x002a, 0x002b, 0x002c, 0x002d, 0x002e, 0x002f, 0x0030,
		0x0031, 0x0032, 0x0033, 0x0034, 0x0035, 0x0036, 0x0037, 0x0038, 0x0039, 0x003a,
		0x003b, 0x003c, 0x003d, 0x003e, 0x003f, 0x0040, 0x0041, 0x0042, 0x0043, 0x0044,
		0x0045, 0x0046, 0x0047, 0x0048, 0x0049, 0x004a, 0x004b, 0x004c, 0x004d, 0x004e,
		0x004f, 0x0050, 0x0051, 0x0052, 0x0053, 0x0054, 0x0055, 0x0056, 0x0057, 0x0058,
		0x0059, 0x005a, 0x005b, 0x005c, 0x005d, 0x005e, 0x005f, 0x0061, 0x0062, 0x0063,
		0x0064, 0x0065, 0x0066, 0x0067, 0x0068, 0x0069, 0x006a, 0x006b, 0x006c, 0x006d,
		0x006e, 0x006f, 0x0070, 0x0071, 0x0072, 0x0073, 0x0074, 0x0075, 0x0076, 0x0077,
		0x0078, 0x0079, 0x007a, 0x007b, 0x007c, 0x007d, 0x007e, 0x00a1, 0x00a3, 0x00a4,
		0x00a5, 0x00a7, 0x00bf, 0x00c4, 0x00c5, 0x00c6, 0x00c7, 0x00c9, 0x00d1, 0x00d6,
		0x00d8, 0x00dc, 0x00df, 0x00e0, 0x00e4, 0x00e5, 0x00e6, 0x00e8, 0x00e9, 0x00ec,
		0x00f1, 0x00f2, 0x00f6, 0x00f8, 0x00f9, 0x00fc, 0x0393, 0x0394, 0x0398, 0x039b,
		0x039e, 0x03a0, 0x03a3, 0x03a6, 0x03a8, 0x03a9, 0x20ac,
	]),
	whitespace: new Set([
		0xa0, 0x180e, 0x2000, 0x2001, 0x2002, 0x2003, 0x2004, 0x2005, 0x2006, 0x2007,
		0x2008, 0x2009, 0x200a, 0x200b, 0x200c, 0x200d, 0x200e, 0x200f, 0x202f, 0x205f,
		0x2060, 0x3000,
	]),
	comma: new Set([0xfe50, 0xff0c, 0x2e34, 0x30fc, 0x60c, 0x3001]),
	dash: new Set([
		0x2010, 0x2011, 0x2012, 0x2013, 0x2014, 0x2015, 0x00ad, 0x058a, 0x1400,
		0x1806, 0x2027, 0x2043, 0x2e17, 0x2e1a, 0x2e40, 0x30a0, 0xfe63, 0xff0d,
		0xe002d, 0x30fc, 0x203e, 0xfe32, 0x5bf, 0x207b, 0x05be, 0x05be,
	]),
	period: new Set([0x06d4, 0x0701, 0x0702, 0xfe52, 0xff0e, 0xff61, 0xe002e, 0x2024,]),
	colon: new Set([
		0x02d0, 0x02f8, 0x0703, 0x0704, 0x1804, 0xa789, 0xfe13, 0xfe55, 0xff1a, 0xe003a,
		0x205a, 0x16ec, 0x05c3,
	]),
	semicolon: new Set([0x061b, 0x2e35, 0xfe14, 0xfe54, 0xff1b, 0x1da89, 0xe003b, 0x204f,]),
	apostrophe: new Set([
		0x02bc, 0x07f4, 0x07f5, 0xff07, 0x055a, 0xe0027, 0x2018, 0x2019, 0x20ab, 0x201a,
		0x201b, 0x2039, 0x203a, 0x275b, 0x275c, 0x275f, 0x276e, 0x276f, 0x00b4, 0x0060,
		0x2032, 0x2db, 0x2035, 0xfe10, 0xfe11, 0x05f3,
	]),
	quote: new Set([
		0x00ab, 0x00bb, 0x201c, 0x201d, 0x201e, 0x201f, 0x275d, 0x275e, 0x2760, 0x2e42,
		0x301d, 0x301e, 0x301f, 0xff02, 0x1f676, 0x1f677, 0xe0022, 0x2dd, 0x2033, 0x2036,
		0x05f4,
	]),
	left_parenthesis: new Set([0x207d, 0x208d, 0x2768, 0x276a, 0x27ee, 0x2985, 0xfe59, 0xff08, 0xff5f, 0xe0028,]),
	right_parenthesis: new Set([0x207e, 0x208e, 0x2769, 0x276b, 0x27ef, 0x2986, 0xfe5a, 0xff09, 0xff60, 0xe0029,]),
	exclamation_mark: new Set([0xfe15, 0x00A1, 0x203C, 0xFE57, 0xFF01,]),
	question_mark: new Set([0xfe16, 0x00bf, 0xfe56, 0xff1f, 0x2048, 0x2049,]),
	ellipsis: new Set([0x2026, 0x22ef,]),
};

export { CHARSETS };
