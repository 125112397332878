import axios from "axios";
import store from '@/store/store.js'

export default {
	async getIncidents(filters) {
		try {
			let response = ""
			if(filters){
				response = await axios.get(`/api/incidents/incident/filters/`, {params: {filters}});
			}else{
				response = await axios.get("/api/incidents/incident/");
			}
			store.dispatch('set_incidents_errors', "");
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_incidents_errors', "An error occurred. Please contact your administrator.");
			}else{
				store.dispatch('set_incidents_errors', response.data)
			}
			return response;
		}
	},
	async retrieveIncident(id) {
		try {
			const response = await axios.get(`/api/incidents/incident/${ id }/`);
			store.dispatch('set_incidents_errors', "");
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_incidents_errors', "An error occurred. Please contact your administrator.");
			}else{
				store.dispatch('set_incidents_errors', response.data)
			}
			return response;
		}
	},
	async createIncident(data, order) {
		try {
			var form = data['id']
			var incident_title = data['title']
			const response = await axios.post("/api/incidents/incident/", { form, order, incident_title });	
			store.dispatch('set_incidents_errors', "");
			return response.data
		} catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_incidents_errors', "An error occurred. Please contact your administrator.");
			}else{
				store.dispatch('set_incidents_errors', response.data)
			}
			return response;
		}
	},
	async createIncidentFromTemplate(form) {
		try {
			const response = await axios.get(`/api/incidents/create_incident_from_template/${form}/`);
			store.dispatch('set_incidents_errors', "");
			return response.data
		} catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_incidents_errors', "An error occurred. Please contact your administrator.");
			}else{
				store.dispatch('set_incidents_errors', response.data)
			}
			return response;
		}
	},
	async startIncident(form, data) {
		try {
			const response = await axios.post(`/api/incidents/incident/start/${form}/`, data);
			store.dispatch('set_incidents_errors', "");
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_incidents_errors', "An error occurred. Please contact your administrator.");
			}else{
				store.dispatch('set_incidents_errors', response.data)
			}
			return response;
		}
	},
	async updateIncident(id, data) {
		try {
			for (let item of data.payload) {
				if (Array.isArray(item.values)) {
					for (let val of item.values) {
						if (val.is_expired) {
							delete val.is_expired
						}
					}
				}
			}
			const response = await axios.put(`/api/incidents/incident/${ id }/`, data)
			store.dispatch('set_incidents_errors', "");
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_incidents_errors', "An error occurred. Please contact your administrator.");
			}else{
				store.dispatch('set_incidents_errors', response.data)
			}
			return response;
		}
	},
	async startIncidentFromTemplate(id, data) {
		try {
			const response = await axios.put(`/api/incidents/start_incident_from_template/${id}/`, data)
			store.dispatch('set_incidents_errors', "");
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			if(response.statusCode==500){
				store.dispatch('set_incidents_errors', "An error occurred. Please contact your administrator.");
			}else{
				store.dispatch('set_incidents_errors', response.data)
			}
			return response;
		}
	},
	async patchIncident(id, data) {
		try {
			const response = await axios.patch(`/api/incidents/incident/${id}/`, data);
			store.dispatch('set_incidents_errors', "");
			return response;
		} catch (error) {
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_incidents_errors', "An error occurred. Please contact your administrator.");
			}else{
				store.dispatch('set_incidents_errors', response.error_message)
			}
			return response;
		}
	},
	async incidentReportSummary(data){
		try {
			const response = await axios.post(`/api/incidents/incident_report_summary/`, data);
			store.dispatch('set_incidents_errors', "");
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_incidents_errors', "An error occurred. Please contact your administrator.");
			}else{
				store.dispatch('set_incidents_errors', response.error_message)
			}
			return response;
		}
	},
	async incidentGeneratePdf(id, data){
		try {
			const response = await axios.post(`/api/incidents/incident/${id}/generate_pdf/`, data);
			store.dispatch('set_incidents_errors', "");
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_status: error.response.status,
				error_message: error.response.data,
			}
			if(response.error_status==500){
				store.dispatch('set_incidents_errors', "An error occurred. Please contact your administrator.");
			}else{
				store.dispatch('set_incidents_errors', response.error_message)
			}
			return response;
		}
	},
};
