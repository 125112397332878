import DatasetsAPI from '@/services/api/Datasets.js'
import store from '@/store/store.js'

const state = {
	contact_fields: [],
}

const getters = {
	contact_fields: (state) => state.contact_fields,
}

const actions = {
	getContactFields({ commit }, cg_id) {
		let contact_types = [
			'PHONE(SMS)',
			'PHONE(SMS&VOICE)',
			'PHONE(VOICE)',
			'EMAIL',
			'PAGER',
		]
		let contact_fields = store.getters.cg_fields
			.filter((field) => {
				return contact_types.includes(field.field_type)
			})
			.map((field) => {
				let is_inherited = false
				field.mapping.forEach((mapped_field) => {
					// Find the mapping for the specified cg_id
					if (cg_id != mapped_field.customer_group) {
						return
					}
					is_inherited = mapped_field.is_inherited
				})
				field.is_inherited = is_inherited
				return field
			})
			.sort((a, b) => {
				if (a.is_inherited && !b.is_inherited) {
					return -1
				} else if (!a.is_inherited && b.is_inherited) {
					return 1
				} else {
					return a.id - b.id
				}
			})
		commit('setContactFields', contact_fields)
	},
}

const mutations = {
	setContactFields: (state, fields) => (state.contact_fields = fields),
}

export default { state, getters, actions, mutations }
