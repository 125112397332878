<template>
	<div class="sm:w-80">
		<h3 class="font-semibold cursor-default text-xl mb text-left">
			{{ dialog_options.single_input_dialog_options.dialog_title }}
		</h3>
		<div class="flex flex-col items-end mt-5 space-y-3">
			<material-input-filled
				:label="label"
				v-model="value"
				:transparent="true"
				class="w-full"
			/>
			<button
				class="btn"
				@click="buttonHandler"
				v-html="dialog_options.single_input_dialog_options.dialog_button_html"
			></button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MaterialInputFilled from '@/components/utils/forms/MaterialInputFilled'
import MaterialInputOutlined from '@/components/utils/forms/MaterialInputOutlined.vue'
export default {
	name: 'SingleInput',
	components: {
		MaterialInputFilled,
		MaterialInputOutlined
	},
	computed: {
		...mapGetters(['dialog_options']),
		value: {
			get() {
				return this.dialog_options.single_input_dialog_options.input_value
			},
			set(val) {
				this.updateSingleInputDialogValue(val)
			},
		},
		label() {
			let label = this.dialog_options.single_input_dialog_options.input_label
			if (label) {
				return label
			}
			return ''
		},
	},
	methods: {
		...mapActions(['updateSingleInputDialogValue']),
		buttonHandler() {
			this.dialog_options.single_input_dialog_options.dialog_button_callback(
				this.value
			)
		},
	},
}
</script>

<style lang="scss" scoped>
.btn {
	@apply p-2;
	@apply hover:bg-gray-100;
	@apply rounded;
	@apply transition-all;
	@apply duration-150;
	@apply ease-in-out;
	@apply font-semibold;
	@apply flex;
	@apply justify-center;
	@apply items-center;
	color: var(--ac-highlight-color);
	border: 1px solid var(--ac-highlight-color);
}
</style>
