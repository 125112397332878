import RegistrationTemplateAPI from '@/services/api/RegistrationTemplate.js';
import store from '@/store/store.js'

const state = {
	registration_template_settings: {},
	multi_branding: [],
	links_color: {},
	register_button: {},
	deregister_button: {},
	input_fields: [],
	bullet_points_color: '',
	customer_name: '',
	service_name: '',
	leaflet: '',
	noticeboard_phone_number: '',
	address_1: '',
	zones_field: [],
	has_map: false,
	map_api_key: '',
	sectors_json: {},
	custom_login_url: '',
	menu_item_footer: ''
};

const getters = {
	registration_template_settings: state => state.registration_template_settings,
	multi_branding: state => state.multi_branding,
	links_color: state => {
		return state.registration_template_settings.template.links_color
	},
	register_button: state => {
		return state.registration_template_settings.template.register_button_color
				? state.registration_template_settings.template.register_button_color
				: ''
	},
	deregister_button: state => {
		return state.registration_template_settings.template.delete_button_color
			? state.registration_template_settings.template.delete_button_color
			: ''
	},
	input_fields: state => state.input_fields,
	bullet_points_color: state =>{
		return state.registration_template_settings.template.bullet_points_color
			? state.registration_template_settings.template.bullet_points_color
			: ''
	},
	customer_name: state => state.registration_template_settings.template.customer_name,
	service_name: state => state.registration_template_settings.template.service_name,
	leaflet: state => state.registration_template_settings.template.leaflet,
	noticeboard_phone_number: state => state.registration_template_settings.template.noticeboard_phone_number,
	address_1: state => state.registration_template_settings.template.address_1,
	zones_field: state => state.zones_field,
	has_map: state => state.has_map,
	map_api_key: state => state.map_api_key,
	sectors_json: state => state.sectors_json,
	custom_login_url: state => state.custom_login_url,
	leaflet_name: state => state.registration_template_settings.template.leaflet_name,
	site_name: state => state.registration_template_settings.template.site_name,
	reason: state => state.registration_template_settings.template.reason,
	menu_item_footer: state => state.menu_item_footer
};

const actions = {
	async getRegistrationTemplateSettings({ commit }, subdomain) {
		const registration_template_settings = await RegistrationTemplateAPI.getRegistrationTemplateSettings(subdomain);
		const cg_settings = JSON.parse(registration_template_settings.template.customer_group.settings);
		if (cg_settings.map_settings) {
			commit('setHasMapValue', cg_settings.map_settings.has_map);
			commit('setMapAPIKey', cg_settings.map_settings.api_key);
		}
		commit('setRegistrationTemplateSettings', registration_template_settings);
	},
	async getRegistrationTemplateMultiBranding({ commit }, customer_group_id) {
		const multi_branding = await RegistrationTemplateAPI.getRegistrationTemplateMultiBranding(customer_group_id);
		commit('setRegistrationTemplateMultiBranding', multi_branding);
	},
	async getPublicRegistrationFields({ commit }, customer_group_id) {
		const data = await RegistrationTemplateAPI.getPublicRegistrationFields(customer_group_id);
		const cg_settings = JSON.parse(state.registration_template_settings.template.customer_group.settings);
		for (let element of data) {
			element.is_hidden = !element.is_hidden;
		}
		let zones_field = [];
		if (cg_settings.map_settings) {
			zones_field = data.filter(it => it.label == cg_settings.map_settings.zone_area_field)[0];

			for (let field of data) {
				if (zones_field && field.label == zones_field.label) {
					field.is_map_field = true;
				}
			}
		}
		commit('setPublicRegistrationFields', data);
		commit('setZonesField', zones_field);
	},
	async getMapCoordinates({ commit }, customer_group_id) {
		const data = await RegistrationTemplateAPI.getMapCoordinates(customer_group_id);
		if (data.error) {
			return
		}
		commit('setMapCoordinates', JSON.parse(data));
	},
	async getCustomLoginUrl({ commit }, customer_group_id){
		const data = await RegistrationTemplateAPI.getCustomLoginUrl(customer_group_id)
		if (data.error){
			return
		}
		commit('setCustomLoginUrl', data)
	},
	updateInputFields({commit}, data) {
		let input_fields = JSON.parse(JSON.stringify(store.getters.input_fields))
		let copy_data_val = JSON.parse(JSON.stringify(data.val))
		if (data.val && Array.isArray(data.val)) {
			copy_data_val = data.val.map((option) => {
					return {
					value: option.label,
					id: option.id,
					field: option.field_id
				}
			})
		}
		input_fields[data.index]['new_input'] = copy_data_val
		commit('setPublicRegistrationFields', input_fields)
	},
	updateActiveMenuItem({commit}, data) {
		commit('setActiveMenuItem', data)
	}
};

const mutations = {
	setRegistrationTemplateSettings: (state, rt_settings) => state.registration_template_settings = rt_settings,
	setRegistrationTemplateMultiBranding: (state, multi_branding) => state.multi_branding = multi_branding,
	setPublicRegistrationFields: (state, input_fields) => state.input_fields = input_fields,
	setZonesField: (state, zones_field) => state.zones_field = zones_field,
	setHasMapValue: (state, has_map) => state.has_map = has_map,
	setMapAPIKey: (state, map_api_key) => state.map_api_key = map_api_key,
	setMapCoordinates: (state, sectors_json) => state.sectors_json = sectors_json,
	setCustomLoginUrl: (state, custom_login_url) => state.custom_login_url = custom_login_url,
	setActiveMenuItem: (state, menu_item_footer) => state.menu_item_footer = menu_item_footer
};

export default { state, getters, actions, mutations };
