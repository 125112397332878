<template>
	<q-drawer
		class="flex justify-between"
		show-if-above
		v-model="open"
		side="left"
		bordered
	>
		<div class="main-layout-drawer-content">
			<q-list separator dense>
				<component
					v-for="item in permitted_drawer_list_items"
					:key="item.label"
					:is="
						item.children
							? 'layout-drawer-expansion-item'
							: 'layout-drawer-item'
					"
					:label="item.label"
					:icon="item.icon"
					:route_name="item.route_name || ''"
					:items="item.children || []"
					:match_exact_for_active="item.match_exact_for_active || false"
					:default_open="getIsExpanded(item.is_open_computed_prop_name)"
					:required_permissions="item.required_permissions"
				></component>
			</q-list>
			<div class="text-left q-mt-lg q-pa-sm">Version {{ version_number }}</div>
		</div>
	</q-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import LayoutDrawerExpansionItem from '@/components/utils/LayoutDrawerExpansionItem'
import LayoutDrawerItem from '@/components/utils/LayoutDrawerItem'

export default {
	name: 'MainLayoutDrawer',
	components: {
		LayoutDrawerExpansionItem,
		LayoutDrawerItem,
	},
	props: {
		is_drawer_open: Boolean,
	},
	computed: {
		...mapGetters([
			'current_CG',
			'logged_user',
			'version_number',
			'module_name_settings',
		]),
		drawer_list_items() {
			return [
				{
					label: 'Dashboard',
					icon: 'home',
					route_name: 'Dashboard',
					match_exact_for_active: true,
					required_permissions: {
						user_permissions: ['SENDER', 'OPERATOR', 'DATA', 'CONTACT'],
						root_cg_permissions: [],
					},
				},
				{
					label: 'Messages',
					icon: 'campaign',
					is_open_computed_prop_name: 'is_messages_open',
					required_permissions: {
						user_permissions: ['SENDER', 'OPERATOR'],
						root_cg_permissions: [],
					},
					children: [
						{
							label: 'Create Message',
							icon: 'edit_square',
							route_name: 'Create Message',
							required_permissions: {
								user_permissions: ['OPERATOR'],
								root_cg_permissions: [],
							},
						},
						{
							label: 'Send Message',
							icon: 'chat_bubble',
							route_name: 'Send Message',
							required_permissions: {
								user_permissions: ['SENDER', 'OPERATOR'],
								root_cg_permissions: [],
							},
						},
						{
							label: 'Audio Noticeboard',
							icon: 'volume_up',
							route_name: 'Audio Noticeboard',
							required_permissions: {
								user_permissions: ['OPERATOR', 'SENDER'],
								root_cg_permissions: ['Audio noticeboard'],
							},
							extra_validators: ['checkNoticeboardNumbers'],
						},
						{
							label: 'Message Calendar',
							icon: 'calendar_today',
							route_name: 'Message Calendar',
							required_permissions: {
								user_permissions: ['OPERATOR', 'SENDER'],
								root_cg_permissions: [],
							},
						},
						{
							label: 'Message Reports',
							icon: 'pie_chart',
							route_name: 'Message Reports',
							required_permissions: {
								user_permissions: ['SENDER', 'OPERATOR'],
								root_cg_permissions: [],
							},
						},
						{
							label: 'Message Templates',
							icon: 'speaker_notes',
							route_name: 'Message Templates',
							required_permissions: {
								user_permissions: ['OPERATOR'],
								root_cg_permissions: [],
							},
						},
					],
				},
				{
					label: this.module_name_settings.module_name_plural,
					icon: 'warning',
					is_open_computed_prop_name: 'is_incidents_open',
					required_permissions: {
						user_permissions: ['OPERATOR', 'SENDER'],
						root_cg_permissions: [
							'Show Incident Manager dashboard only',
							'Show Alert Cascade + Incident Manager dashboard',
						],
					},
					children: [
						{
							label: `Start ${this.module_name_settings.module_name}`,
							icon: 'bolt',
							route_name: 'Start',
							required_permissions: {
								user_permissions: ['OPERATOR', 'SENDER'],
								root_cg_permissions: [
									'Show Incident Manager dashboard only',
									'Show Alert Cascade + Incident Manager dashboard',
								],
							},
						},
						{
							label: `${this.module_name_settings.module_name} Reports`,
							icon: 'bar_chart',
							route_name: 'Reports',
							required_permissions: {
								user_permissions: ['OPERATOR', 'SENDER'],
								root_cg_permissions: [
									'Show Incident Manager dashboard only',
									'Show Alert Cascade + Incident Manager dashboard',
								],
							},
						},
						{
							label: `${this.module_name_settings.module_name} Settings`,
							icon: 'build',
							route_name: 'Incident Settings',
							required_permissions: {
								user_permissions: ['OPERATOR'],
								root_cg_permissions: [
									'Show Incident Manager dashboard only',
									'Show Alert Cascade + Incident Manager dashboard',
								],
							},
						},
					],
				},
				{
					label: 'Manage Integrations',
					icon: 'integration_instructions',
					is_open_computed_prop_name: 'is_integrations_open',
					required_permissions: {
						user_permissions: [],
						root_cg_permissions: ['Use Microsoft Teams'],
					},
					children: [
						{
							label: 'Microsoft Teams',
							icon: 'mdiMicrosoftTeams',
							route_name: 'Microsoft Teams',
							required_permissions: {
								user_permissions: [],
								root_cg_permissions: ['Use Microsoft Teams'],
							},
						},
						{
							label: 'Slack',
							icon: 'fabSlack',
							route_name: 'Slack',
							required_permissions: {
								user_permissions: [],
								root_cg_permissions: ['Use Slack'],
							},
						},
					],
				},
				{
					label: 'Manage Users',
					icon: 'group',
					route_name: 'Manage Users',
					required_permissions: {
						user_permissions: ['DATA'],
						root_cg_permissions: [],
					},
				},
				{
					label: 'Manage Datasets',
					icon: 'manage_accounts',
					route_name: 'Manage Datasets',
					required_permissions: {
						user_permissions: [],
						root_cg_permissions: [],
					},
				},
				{
					label: 'Media Library',
					icon: 'note_alt',
					route_name: 'Media Library',
					required_permissions: {
						user_permissions: ['CONTACT', 'OPERATOR'],
						root_cg_permissions: ['Media library'],
					},
				},
				{
					label: 'Help and Support',
					icon: 'question_mark',
					route_name: 'Help & Support',
					required_permissions: {
						user_permissions: ['SENDER', 'OPERATOR', 'DATA'],
						root_cg_permissions: [],
					},
				},
				{
					label: 'Contact Support',
					icon: 'contact_support',
					route_name: 'Contact Support',
					required_permissions: {
						user_permissions: ['SENDER', 'OPERATOR', 'DATA', 'CONTACT'],
						root_cg_permissions: [],
					},
				},
				{
					label: 'Activity Log',
					icon: 'web_stories',
					route_name: 'Activity Log',
					required_permissions: {
						user_permissions: [],
						root_cg_permissions: [],
					},
				},
				{
					label: 'Custom Branding',
					icon: 'branding_watermark',
					route_name: 'Custom Branding',
					required_permissions: {
						user_permissions: [],
						root_cg_permissions: ['Custom dashboard branding'],
					},
				},
			]
		},
		open: {
			get() {
				return this.is_drawer_open
			},
			set(val) {
				this.$emit('toggleDrawer', val)
			},
		},
		is_messages_open() {
			let messages_route_names = this.extractRouteNamesWithChildren('Messages')
			// These two are not children but subroutes so we have to add them manually
			messages_route_names.push('Dispatch Details', 'Dispatch Overview')
			return this.$route.name && messages_route_names.includes(this.$route.name)
		},
		is_incidents_open() {
			let incidents_route_names = this.extractRouteNamesWithChildren(
				this.module_name_settings.module_name_plural
			)
			return (
				this.$route.name && incidents_route_names.includes(this.$route.name)
			)
		},
		is_integrations_open() {
			let integrations_route_names = this.extractRouteNamesWithChildren(
				'Manage Integrations'
			)
			return (
				this.$route.name && integrations_route_names.includes(this.$route.name)
			)
		},
		permitted_drawer_list_items() {
			return this.drawer_list_items
				.filter((item) => this.hasPermission(item))
				.map((item) => {
					if (!item.children) {
						return item
					}
					item.children = item.children.filter((child) =>
						this.hasPermission(child)
					)
					return item
				})
		},
	},
	methods: {
		checkNoticeboardNumbers() {
			const customer_group_settings = JSON.parse(
				this.current_CG.customer_group.settings
			)
			return (
				customer_group_settings.noticeboard_numbers &&
				customer_group_settings.noticeboard_numbers.length > 0
			)
		},
		hasPermission(item) {
			// Check if the current root cg has any of the required root cg permissions
			let has_root_cg_permission = true
			if (item.required_permissions.root_cg_permissions.length) {
				has_root_cg_permission =
					this.current_CG.root_customer_group_permissions.reduce(
						(has_permission, cg_permission) => {
							if (
								item.required_permissions.root_cg_permissions.includes(
									cg_permission
								)
							) {
								has_permission = true
							}
							return has_permission
						},
						false
					)
			}
			if (!has_root_cg_permission) {
				return false
			}
			// Check if the user has a permission that's in the listed required user permissions
			item.required_permissions.user_permissions.push('ADMIN')
			if (
				!item.required_permissions.user_permissions.includes(
					this.logged_user.customer_permission
				)
			) {
				return false
			} else {
				if (
					item.route_name == 'Audio Noticeboard' &&
					this.logged_user.customer_permission === 'SENDER' &&
					!this.current_CG.root_customer_group_permissions.includes('Allow Senders to edit content')
				) {
					return false
				}
			}
			if (!item.extra_validators) {
				return true
			}
			// If there's extra validators, call each one and return true only if they all pass, otherwise false
			return item.extra_validators.reduce((passed_validation, validator) => {
				return passed_validation && this[validator]()
			}, true)
		},
		getIsExpanded(computed_prop_name = false) {
			if (!computed_prop_name) {
				return false
			}
			return this[computed_prop_name]
		},
		extractRouteNamesWithChildren(item_label) {
			// Find the right expansion item and extract all the route names from it's children
			let route_names = this.drawer_list_items
				.filter((item) => item.label === item_label)[0]
				.children.map((item) => item.route_name)
			let children_route_names = []
			// For each route name find the route instance and check if it has children,
			// if it does, include those too
			route_names.forEach((route_name) => {
				let route_instance = this.$router.options.routes.filter(
					(route) => route.name === route_name
				)[0]
				if (route_instance.children) {
					children_route_names.push(
						...route_instance.children.map((child) => child.name)
					)
				}
			})
			return [...route_names, ...children_route_names]
		},
	},
}
</script>
<style>
.main-layout-drawer-content {
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}
</style>
