import UserAPI from '@/services/api/Users.js'
import store from '@/store/store.js'
import moment from 'moment-timezone'

const state = {
	user_instance: {},
	user_field_values: [],
	device_fields: [],
	select_data_fields: [],
	input_data_fields: [],
	user_on_call_settings: {},
	data_field_error_messages: {}
}


const getters = {
	user_instance: (state) => state.user_instance,
	user_field_values: (state) => state.user_field_values,
	device_fields: (state) => state.device_fields,
	select_data_fields: (state) => state.select_data_fields,
	input_data_fields: (state) => state.input_data_fields,
	user_on_call_settings: (state) => state.user_on_call_settings,
	data_field_error_messages: (state) => state.data_field_error_messages
}


const actions = {
	createFieldValueInstance({ commit }, obj) {
		let user_field_values = JSON.parse(JSON.stringify(store.getters.user_field_values))
		let field_value = {
			'field': obj.field.id,
			'field_type': obj.field.field_type,
			'field_value_label': obj.field.label,
			'user': store.getters.user_instance.id,
			'values': ""
		}
		user_field_values.push(field_value)
		store.dispatch('updateFieldValues', user_field_values)
		let position = user_field_values.length - 1
		return position
	},

	async getUser({ commit }, user_id) {
		let response = await UserAPI.getUser(user_id)
		if (response.error) {
			return response
		}
		let field_values = response.data.custom_fields
		store.dispatch('updateFieldValues', field_values)
		commit('setUserInstance', response.data)
	},

	async getUserOnCallSettings({ commit }, user_id) {
		let response = await UserAPI.getUserOnCallSettings(user_id)
		if (response.error) {
			return response
		}
		let weeks = []
		for (let el of JSON.parse(JSON.stringify(response.data.weeks))) {
			let week = {...el}
			week.start_date = moment(week.start_date, "YYYY-MM-DDTHH:mm:ss").format('DD/MM/YYYY HH:mm:ss')
			week.end_date = moment(week.end_date, "YYYY-MM-DDTHH:mm:ss").format('DD/MM/YYYY HH:mm:ss')
			week.periods[0].start_date = moment(week.periods[0].start_date, "YYYY-MM-DDTHH:mm:ss").format('DD/MM/YYYY HH:mm:ss')
			week.periods[0].end_date = moment(week.periods[0].end_date, "YYYY-MM-DDTHH:mm:ss").format('DD/MM/YYYY HH:mm:ss')
			weeks.push(week)
		}
		commit('setUserOnCallSettings', { weeks })
		return {}
	},

	async deleteUserOnCallSettings({ commit }, on_call_period_id) {
		let response = await UserAPI.deleteUserOnCallSettings(on_call_period_id)
		if (response.error) {
			return response
		}

	},
	async createUserOnCallSettings({ commit }, data) {
		let response = await UserAPI.createUserOnCallSettings(data)
		if (response.error) {
			return response
		}
	},
	async editUserOnCallSettings({ commit }, data) {
		let response = await UserAPI.editUserOnCallSettings(data)
		if (response.error) {
			return response
		}
	},
	async exportOnCallSettings({ commit }, data) {
		let copy_data = JSON.parse(JSON.stringify(data))
		let weeks = []
		for (let el of copy_data.user_periods.weeks) {
			let week = {...el}
			week.start_date = moment(week.start_date, "DD/MM/YYYY HH:mm").format('YYYY-MM-DDTHH:mm:ss')
			week.end_date = moment(week.end_date, "DD/MM/YYYY HH:mm").format('YYYY-MM-DDTHH:mm:ss')
			week.periods[0].start_date = moment(week.periods[0].start_date, "DD/MM/YYYY HH:mm").format('YYYY-MM-DDTHH:mm:ss')
			week.periods[0].end_date = moment(week.periods[0].end_date, "DD/MM/YYYY HH:mm").format('YYYY-MM-DDTHH:mm:ss')
			weeks.push(week)
		}
		copy_data.user_periods.weeks = weeks
		let response = await UserAPI.exportOnCall(data.user_id, copy_data)
		return response
	},
	updateFieldValues({ commit }, values) {
		commit('setUserFieldValues', values)
	},

	updateUser({ commit }, obj) {
		commit('setUserInstance', obj)
	},

	updateUserFailedAttempts({ commit }, obj) {
		let user_instance = JSON.parse(JSON.stringify(store.getters.user_instance))
		user_instance.failed_attempts = { ...user_instance.failed_attempts, ...obj }
		commit('setUserInstance', user_instance)
	},

	updateDeviceFields({ commit }, device_fields) {
		commit('setDeviceFields', device_fields)
	},

	updateSelectDataFields({ commit }, select_data_fields) {
		commit('setSelectDataFields', select_data_fields)
	},

	updateInputDataFields({ commit }, input_data_fields) {
		commit('setInputDataFields', input_data_fields)
	},

	updateInputFieldValues({ commit }, obj) {
		let user_field_values = JSON.parse(JSON.stringify(store.getters.user_field_values))
		user_field_values[obj.position].values = obj.value
		store.dispatch('updateFieldValues', user_field_values)
	},

	updateSelectFieldValues({ commit }, obj) {
		let user_field_values = JSON.parse(JSON.stringify(store.getters.user_field_values))
		if (obj.field_type == 'SINGLESELECT') {
			user_field_values[obj.position].values = obj.selected_option && obj.selected_option.id ? obj.selected_option.id : ""
			user_field_values[obj.position].option_label = obj.selected_option && obj.selected_option.label ? obj.selected_option.label : ""
		}
		else {
			user_field_values[obj.position].values = obj.selected_option.length ? JSON.stringify(obj.selected_option) : ""
		}
		store.dispatch('updateFieldValues', user_field_values)
	},

	async updateUserData({ commit }, obj) {
		let response = await UserAPI.editUser(obj.user_id, obj.user_data)
		return response
	},
	updateDataFieldErrorMessage({commit}, obj) {
		let data_field_error_messages = JSON.parse(JSON.stringify(store.getters.data_field_error_messages))
		data_field_error_messages[obj.field] = obj.error_message
		commit('setDataFieldErrorMessage', data_field_error_messages)
	},
	clearDataFieldErrorMessage({commit}) {
		commit('setDataFieldErrorMessage', {})
	}

}


const mutations = {
	setUserInstance: (state, user_instance) => state.user_instance = { ...state.user_instance, ...user_instance },
	setUserFieldValues: (state, field_values) => state.user_field_values = field_values,
	setDeviceFields: (state, device_fields) => state.device_fields = device_fields,
	setSelectDataFields: (state, select_data_fields) => state.select_data_fields = select_data_fields,
	setInputDataFields: (state, input_data_fields) => state.input_data_fields = input_data_fields,
	setUserOnCallSettings: (state, user_on_call_settings) => state.user_on_call_settings = user_on_call_settings,
	setDataFieldErrorMessage: (state, data_field_error_messages) => state.data_field_error_messages = data_field_error_messages
}

export default { state, getters, actions, mutations }
