<template>
	<div :class="parent_el_class_list">
		<div :class="input_wrapper_class_list">
			<div class="icon-wrapper leading-icon-wrapper">
				<slot name="leading-icon"></slot>
			</div>
			<input
				:class="input_class_list"
				:name="name"
				:id="id"
				:disabled="disabled"
				:autocomplete="comp_autocomplete"
				v-if="!textarea"
				v-model="inputVal"
				:type="type"
				@focusout="inputFocusOut"
				@focus="inputFocus"
			/>
			<textarea
				:class="input_class_list"
				:name="name"
				:id="id"
				:disabled="disabled"
				:rows="rows || 5"
				v-if="textarea"
				v-model="inputVal"
				@focusout="inputFocusOut"
				@focus="inputFocus"
			></textarea>
			<label @click="labelClick" :class="label_class_list" :for="id">{{
				label
			}}</label>
			<div class="icon-wrapper trailing-icon-wrapper">
				<slot name="trailing-icon" v-if="!error"></slot>
			</div>
			<span v-if="error" class="material-icons-outlined error-icon">error</span>
			<div
				class="absolute bottom-0 h-1 w-full flex items-center justify-center"
			>
				<div :class="border_div_class_list"></div>
			</div>
		</div>
		<div v-if="helper_text && !error" class="helper-text">
			{{ helper_text }}
		</div>
		<div class="error-msg" v-if="error">{{ error_msg }}</div>
	</div>
</template>

<script>
export default {
	name: 'MaterialInputFilled',
	props: {
		value: [String, Number],
		label: String,
		name: String,
		error_msg: String,
		error: Boolean,
		helper_text: String,
		transparent: Boolean,
		focus: Boolean,
		disabled: Boolean,
		autocomplete: {
			type: Boolean,
			default: true,
		},
		textarea: Boolean,
		rows: Number,
		type: {
			validator: (val) => {
				return ['text', 'number'].includes(val)
			},
			default: 'text',
		},
	},
	data() {
		return {
			focused: false,
			trailing_icon_padding: false,
			label_class_list: {
				'material-input-label': true,
				'active-label': false,
			},
			border_div_class_list: {
				'bottom-border-div': true,
			},
			allowed_num_type_keys: ['Backspace', '-', '.', 'Tab', 'Enter'],
		}
	},
	computed: {
		id() {
			return `${this.name}-${Math.random().toString(16).slice(2)}`
		},
		inputVal: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
		comp_autocomplete() {
			if (this.autocomplete) {
				return 'on'
			}
			return 'off'
		},
		input_wrapper_class_list() {
			return {
				relative: true,
				'inline-block': true,
				'w-full': true,
				'textarea-wrapper': this.textarea,
				'transparent-input': this.transparent,
			}
		},
		input_class_list() {
			return {
				'material-input': true,
				'leading-icon-padding': this.$slots['leading-icon'],
				'trailing-icon-padding': this.$slots['trailing-icon'],
			}
		},
		parent_el_class_list() {
			return {
				'inline-block': true,
				'disabled-input': this.disabled,
				'w-full': true,
				error: this.error,
				focused: this.focused,
				'leading-icon-input-wrapper': this.$slots['leading-icon'],
			}
		},
		inputIsEmpty() {
			return this.value === '' || this.value === null
		},
	},
	methods: {
		labelClick() {
			this.label_class_list['active-label'] = true
			this.focused = true
		},
		inputFocusOut() {
			if (this.inputIsEmpty) {
				this.label_class_list['active-label'] = false
			}
			this.focused = false
		},
		inputFocus() {
			this.label_class_list['active-label'] = true
			this.focused = true
		},
	},
	created() {
		if (!this.inputIsEmpty) {
			this.label_class_list['active-label'] = true
		}
	},
	watch: {
		value(val) {
			if (val == 0 || val) {
				this.label_class_list['active-label'] = true
			}
		},
		focus() {
			if (this.focus) {
				this.inputFocus()
			} else {
				this.inputFocusOut()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.material-input {
	@apply border-t-0;
	@apply border-r-0;
	@apply border-l-0;
	@apply border-b;
	@apply rounded;
	@apply rounded-b-none;
	@apply transition-all;
	@apply duration-150;
	@apply ease-in-out;
	@apply bg-gray-100;
	@apply w-full;
	padding: 16px;
	padding-bottom: 6px;
	padding-top: 20px;
	height: 56px;
	&:focus,
	&:focus-visible {
		outline: none;
	}
	// Remove number input arrows
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&[type='number'] {
		-moz-appearance: textfield;
	}
}
.transparent-input {
	.material-input,
	&.textarea-wrapper {
		@apply bg-transparent;
	}
}
.material-input-label {
	@apply absolute;
	@apply transition-all;
	@apply duration-150;
	@apply ease-in-out;
	@apply cursor-text;
	@apply text-black;
	@apply opacity-60;
	@apply select-none;
	font-size: 16px;
	top: 50%;
	transform: translateY(-50%);
	left: 16px;
}
.leading-icon-input-wrapper {
	.material-input-label {
		left: 40px;
	}
}
.helper-text {
	@apply opacity-60;
	@apply text-black;
	@apply text-left;
	@apply mt-1;
	@apply text-xs;
	padding: 0 16px;
}
.bottom-border-div {
	@apply transition-all;
	@apply duration-150;
	@apply ease-out;
	background-color: var(--ac-highlight-color);
	height: 0px;
	width: 0px;
}
// Icon stylings
.icon-wrapper {
	@apply flex;
	@apply items-center;
	@apply justify-center;
	position: absolute;
	font-size: 24px;
	top: 50%;
	transform: translateY(-50%);
}
.leading-icon-wrapper {
	left: 6px;
}
.leading-icon-padding {
	padding-left: 40px;
}
.trailing-icon-wrapper {
	right: 6px;
}
.trailing-icon-padding {
	padding-right: 40px;
}
// Focused state styles
.focused {
	.material-input,
	.textarea-wrapper {
		@apply bg-gray-200;
		@apply border-none;
	}
	.transparent-input {
		.material-input,
		&.textarea-wrapper {
			@apply bg-transparent;
		}
	}
	.material-input-label {
		color: var(--ac-highlight-color);
		opacity: 0.87;
	}
	.bottom-border-div {
		height: 2px;
		width: 100%;
	}
}
.active-label {
	top: 16px;
	font-size: 12px;
}
// Textarea stylings
.textarea-wrapper {
	@apply bg-gray-100;
	@apply rounded;
	@apply rounded-b-none;
	@apply border-b;
	@apply border-solid;
	@apply border-gray-400;
	padding-top: 20px;
	padding-bottom: 6px;
	.material-input-label {
		top: 28px;
	}
	.active-label {
		top: 14px;
	}
	.material-input {
		@apply border-b-0;
		@apply rounded-none;
		@apply bg-transparent;
		@apply resize-none;
		@apply transition-none;
		@apply px-4;
		@apply py-0;
		@apply h-auto;
		// Textarea scrollbar
		/* width */
		&::-webkit-scrollbar {
			width: 7px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			@apply bg-transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			@apply bg-materialGray;
			@apply bg-opacity-70;
			border-radius: 10px;
		}
	}
}
// Disabled state styles
.disabled-input {
	@apply relative;
	@apply select-none;
	@apply opacity-60;
	&::before {
		@apply block;
		@apply absolute;
		@apply top-0;
		@apply left-0;
		@apply w-full;
		@apply h-full;
		content: '';
		z-index: 2;
	}
	& .material-input {
		@apply cursor-default;
	}
}
// Errors styling
.error {
	.material-input {
		padding-right: 40px;
	}
	.bottom-border-div {
		@apply bg-red-700;
		height: 2px;
		width: 100%;
	}
	.material-input-label,
	.error-icon,
	.error-msg {
		@apply opacity-100;
		@apply text-red-700;
	}
}
.error-msg {
	@apply text-xs;
	@apply text-left;
	@apply mt-1;
	padding: 0 16px;
}
.error-icon {
	font-size: 24px;
	position: absolute;
	right: 6px;
	top: 50%;
	transform: translateY(-50%);
}
</style>
