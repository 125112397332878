import DatasetsAPI from '@/services/api/Datasets.js'
import store from '@/store/store.js'

const state = {
	contact_fields: [],
}

const getters = {}

const actions = {
	async updateField({ commit }, { data, cg_id }) {
		let response = await DatasetsAPI.updateField(data)
		if (response.error) {
			return response
		}
		await store.dispatch('getCgFields', cg_id)
		store.dispatch('getContactFields', cg_id)
		store.dispatch('getDataFields', cg_id)
		return response
	},
	async deleteField({ commit }, { id, cg_id }) {
		let response = await DatasetsAPI.deleteField(id)
		if (response.error) {
			return response
		}
		await store.dispatch('getCgFields', cg_id)
		store.dispatch('getContactFields', cg_id)
		store.dispatch('getDataFields', cg_id)
		return response
	},
	async createField({ commit }, data) {
		let response = await DatasetsAPI.createField(data)
		if (response.error) {
			return response
		}
		await store.dispatch('getCgFields', data.customer_group_id)
		store.dispatch('getContactFields', data.customer_group_id)
		store.dispatch('getDataFields', data.customer_group_id)
		return response
	},
}

const mutations = {}

export default { state, getters, actions, mutations }
