import axios from 'axios';
import utils from '../utils.js';

export default {
	async getScenarios() {
		const response = await axios.get('/api/scenario/', {
			params: {
				page_size: 'all',
				active_tab: 'all', // retrieve both 'test' and 'real' scenarios
				flags: 'is_incident_manager', // retrieve only Incident Manager scenarios
			},
		});
		return response.data;
	},

	async checkScenarioName(name, scenario_id, is_real) {
		try {
			const response = await axios.get('/api/scenario/name/', {
				params: {
					name,
					scenario_id,
					is_real,
				},
			});
			return response.data;
		} catch (error) {
			return utils.getError(error);
		}
	},
};
