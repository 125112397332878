import DatasetsAPI from '@/services/api/Datasets.js'
import store from '@/store/store.js'

export default {
	searchTree(cg, id) {
		if (cg.id === id) {
			return cg
		}
		if (cg.children) {
			for (let i = 0; i < cg.children.length; i++) {
				let found = this.searchTree(cg.children[i], id)
				if (found) {
					return found
				}
			}
		}
	},
	countChildren(cg) {
		let count = 1
		if (!cg.children) {
			return count
		} else {
			for (let i = 0; i < cg.children.length; i++) {
				count += this.countChildren(cg.children[i])
			}
		}
		return count
	},
	getTimeoutChoices() {
		return timeout_choices
	},
	strToBool(string) {
		if (typeof string == 'boolean') {
			return string
		}
		if (string.toLowerCase() == 'true') {
			return true
		} else if (string.toLowerCase() == 'false') {
			return false
		}
		return null
	},
	buildTemplateFormData(data) {
		let form_data = new FormData()
		form_data.append('rules', JSON.stringify(data.rules))
		form_data.append(
			'customer_group',
			data.template_settings.customer_group
		)
		form_data.append(
			'import_by_column',
			data.template_settings.import_by_column
		)
		form_data.append(
			'import_type',
			data.template_settings.import_type
		)
		form_data.append(
			'external_id',
			data.template_settings.external_id
		)
		form_data.append(
			'template_name',
			data.template_settings.template_name.name
		)
		form_data.append('template_number', data.index)
		// If the file has name, that means new file is uploaded.
		form_data.append('file', data.file ? data.file : '')
		form_data.append(
			'gpg_encrypted',
			data.template_settings.gpg_encrypted
		)
		form_data.append(
			'keep_admins_from_demoting',
			data.template_settings.keep_admins_from_demoting
		)
		form_data.append(
			'allow_new_field_options_to_be_created',
			data.template_settings.allow_new_field_options_to_be_created
		)
		return form_data
	},
	async buildSelectionListOptions(obj) {
		let options = []
		options.push({
			options: [{ value: -1, label: 'Ignore' }],
		})
		let email_option = { value: -2, label: 'Work Email' }
		let external_id = { value: -3, label: 'External Id' }
		let user_permissions_option = {
			value: -4,
			label: 'Customer Permissions',
		}
		let user_is_active = {
			value: -5,
			label: 'Is_Active',
			group: 'System Fields',
		}
		let protected_option = {
			value: -6,
			label: 'Protection',
		}
		let is_quick_report_recipient_option = {
			value: -7,
			label: 'Quick Report Recipient',
		}
		let time_based_user_expiry_date = {
			value: -8,
			label: 'Time Based User Expiry Date',
		}
		let preferred_timezone = {
			value: -9,
			label: 'Preferred Timezone',
		}
		let gpg_encrypted = {
			value: -10,
			label: 'Gpg Encryption',
		}
		let is_message_recipient = {
			value: -11,
			label: 'Is Message Recipient',
		}

		let customer_field_options = []
		let system_field_options = []
		customer_field_options.push(email_option)
		system_field_options.push(external_id)
		if (obj.import_type != 'delete_only_import') {
			system_field_options.push(user_permissions_option)
			system_field_options.push(time_based_user_expiry_date)
			system_field_options.push(protected_option)
			system_field_options.push(user_is_active)
			system_field_options.push(is_quick_report_recipient_option)
			system_field_options.push(is_message_recipient)
			if (obj.cg) {
				const response = await DatasetsAPI.listImportFieldsByGroup(
					parseInt(obj.cg)
				)
				for (let item of response) {
					customer_field_options.push({
						value: item.id,
						label: item.label,
					})
				}
			} else if (obj.column) {
				const response = await DatasetsAPI.listDescendantFields(
					store.getters.logged_user.customer_group
				)
				for (let item of response) {
					customer_field_options.push({
						value: item.id,
						label: item.label,
					})
				}
			}
		}
		options.push({
			name: 'System Fields',
			options: system_field_options,
		})
		options.push({
			name: 'Customer Fields',
			options: customer_field_options,
		})
		return options
	},
	async setFields(customer_group_id) {
		let device_fields = []
		let select_data_fields = []
		let input_data_fields = []
		await store.dispatch('getCgFields', customer_group_id)
		for (let field of store.getters.cg_fields){
			if(field.field_type.includes('PHONE') || field.field_type == 'EMAIL'){
				device_fields.push(field)
			} else if(field.field_type.includes('SELECT')){
				select_data_fields.push(field)
			} else {
				input_data_fields.push(field)
			}
		}

		store.dispatch('updateDeviceFields', device_fields)
		store.dispatch('updateSelectDataFields', select_data_fields)
		store.dispatch('updateInputDataFields', input_data_fields)
	}
}

let timeout_choices = [
	{
		value: '900',
		label: '15 minutes',
	},
	{
		value: '1800',
		label: '30 minutes',
	},
	{
		value: '3600',
		label: '1 hour',
	},
	{
		value: '7200',
		label: '2 hours',
	},
	{
		value: '14400',
		label: '4 hours',
	},
	{
		value: '21600',
		label: '6 hours',
	},
	{
		value: '43200',
		label: '12 hours',
	},
	{
		value: '64800',
		label: '18 hours',
	},
	{
		value: '86400',
		label: '24 hours',
	},
	{
		value: '604800',
		label: '7 days'
	}
]
