import axios from 'axios'


export default {
	async getUser(user_id) {
		try {
			const response = await axios.get(`/api/user/${user_id}/?custom_fields=true`)
			return response
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async editUser(user_id, user_data) {
		try {
			const response = await axios.put(`/api/user/${user_id}/`, user_data)
			return response
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async createUser(data) {
		try {
			const response = await axios.post(`/api/user/`, data)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async deleteUser(user_id) {
		try {
			const response = await axios.delete(`/api/user/${user_id}/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},

	async exportUsers(data) {
		try {
			const response = await axios.post('/api/export_users', data)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getUserSuppressionStatus(user_id) {
		try {
			const response = await axios.get(`/api/mailgun_suppression/${user_id}/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async deleteUserSuppressions(user_id) {
		try {
			const response = await axios.delete(`/api/mailgun_suppression/${user_id}/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async deleteFailedPasswordResetAttempts(user_id) {
		try {
			const response = await axios.delete(`/api/user/${user_id}/delete_password_reset_attempts/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async deleteFailedLoginAttempts(user_id) {
		try {
			const response = await axios.delete(`/api/user/${user_id}/delete_failed_login_attempts/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getUserOnCallSettings(user_id) {
		try {
			const response = await axios.get(`/api/user_on_call/${user_id}/`)
			return response
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async deleteUserOnCallSettings(on_call_period_id) {
		try {
			const response = await axios.delete(`/api/on_call_period/${on_call_period_id}/`)
			return response
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async createUserOnCallSettings(data) {
		try {
			const response = await axios.post("/api/on_call_period/", data)
			return response
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async editUserOnCallSettings(data) {
		try {
			const response = await axios.put(`/api/on_call_period/${data.on_call_period_id}/`, data)
			return response
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getAvailableUsersForAssigningOnCall(on_call_period_id) {
		try {
			const response = await axios.get(`/api/assign_users_on_call/${on_call_period_id}/`)
			let data = response.data.map(el => {return {label: el.address_string, ...el}})
			return data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async assignOnCallPeriod(data, is_entire) {
		try {
			const response = await axios.post(
				`/api/on_call_period/assign/${is_entire ? 'entire' : 'parts'}/`,
				data
			)
			return response
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async exportOnCall(user_id, data) {
		try {
			const response = await axios.post(
				`/api/export_on_call_periods/${user_id}/`, data)
			return response
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getUsersForDataset(dataset_id, fetch_all, data, pagination, search_by_address_string) {
		let url = `/api/customer_group/${dataset_id}/users/?`
		if (fetch_all) {
			url += 'all=true'
		}
		if (data) {
			url += "&field=" + data.field
				+ "&value=" + encodeURIComponent(data.value) + "&search_by_missing_value=" + data.search_by_missing_value
		}
		if (search_by_address_string) {
			url += '&search_by_address_string=' + search_by_address_string
		}

		url += "&items_per_page=" + pagination.rowsPerPage + '&page_number=' + pagination.page
		+ '&sort_by=' + pagination.sortBy + '&descending='  + pagination.descending

		try {
			const response = await axios.get(url)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getUsersForExport(dataset_id, fetch_all, data, search_by_address_string) {
		let url = `/api/user/list_users_export/${dataset_id}/?`
		if (fetch_all) {
			url += 'all=true'
		}
		if (data) {
			url += "&field=" + data.field
				+ "&value=" + encodeURIComponent(data.value) + "&search_by_missing_value=" + data.search_by_missing_value
		}
		if (search_by_address_string) {
			url += '&search_by_address_string=' + search_by_address_string
		}
		try {
			const response = await axios.get(url)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async analyzeOnCallFile(data) {
		try {
			const response = await axios.post(
				`/api/user/analyze/import_on_call/`,
				data
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async analyzeOnCallXlsxFile(data) {
		try {
			const response = await axios.post(
				`/api/user/analyze/import_on_call_xlsx/`,
				data
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async exportCsv(data) {
		try {
			const response = await axios.post(
				`/api/user/export/on_call_csv/`,
				data
			);
			return response.data;
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},

}
