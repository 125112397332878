import axios from 'axios';
import store from '@/store/store.js'

export default {
	async getRegistrationTemplateSettings (subdomain) {
		try {
			const response = await axios.get(`/api/customer_group/registration_template/${subdomain}/`);
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},

	async getRegistrationTemplateMultiBranding (cg_id) {
		try {
			const response = await axios.get(`/api/customer_group/${cg_id}/registration_template_multi_branding/`);
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},

	async getCustomLoginUrl(cg_id) {
		try {
			const response = await axios.get(`/api/customer_group/${cg_id}/custom_login_url/`);
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},

	async getPublicRegistrationFields (customer_group_id) {
		try {
			const response = await axios.get(`/api/registration/public/get/${customer_group_id}/`);
			return response.data;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},

	async createSelfRegisteredUser (data) {
		try {
			const response = await axios.post(
				`/api/registration/public/create_user/`,
				data,
				{
					headers: {
						'Content-Type': 'application/json'
					}
				}
			);
			return response;
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			};
			return response;
		}
	},

	async getRecaptchaKey () {
		try {
			const response = await axios.get(`/api/registration/public/get_key/`);
			return response.data;
		}catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			return response;
		}
	},

	async deleteSelfRegisteredUser (data) {
		try {
			const response = await axios.post(
				`/api/registration/public/delete_user/`,
				data,
				{
					headers: {
						'Content-Type': 'application/json'
					}
				}
			);
			return response.data;
		}catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			return response;
		}
	},

	async getMapCoordinates (customer_group_id) {
		try {
			const response = await axios.get(`/api/customer_group/${customer_group_id}/map_coordinates/`);
			return response.data;
		}catch (error) {
			const response = {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
			return response;
		}
	}
}
