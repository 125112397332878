import axios from 'axios'
import store from '@/store/store.js';


export default {
	async deleteCustomerGroup(cg_id) {
		try {
			const response = await axios.delete(`/api/customer_group/${cg_id}/`)
			return response
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async createCustomerGroup(data) {
		try {
			const response = await axios.post(`/api/customer_group/`, data)
			return response
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},

	async getCustomerGroups() {
		try {
			const response = await axios.get(`/api/customer_group/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},

	async getCustomerGroupSettings(cg_id) {
		try {
			const response = await axios.get(
				`/api/customer_group/${cg_id}/?include_fields=true`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async listAudioNoticeboards() {
		try {
			const response = await axios.get(`/api/audio-noticeboards/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async updateCustomerGroup(data) {
		try {
			const response = await axios.put(`/api/customer_group/${data.id}/`, data)
			return response
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async reissueWelcomeEmail(customer_group_id, is_aproved = false) {
		try {
			let url = `/api/reissue_welcome_email/${customer_group_id}/`
			if (is_aproved) {
				url += '?is_aproved=' + true
			}
			const response = await axios.get(url)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async editCustomerGroupSettings(customer_group_id, settings) {
		try {
			const response = await axios.put(
				`/api/customer_group_settings/${customer_group_id}/`,
				settings
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getCgFields(id) {
		let url = `/api/customer_group/${id}/fields/?mapping=true`
		if (JSON.parse(store.getters.current_CG.customer_group.settings).send_verification_email) {
			url+='&has_operator_access=true'
		}
		try {
			const response = await axios.get(url)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async updateField(data) {
		try {
			const response = await axios.put(`/api/field/${data.id}/`, data)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async deleteField(id) {
		try {
			const response = await axios.delete(`/api/field/${id}/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async createField(data) {
		try {
			const response = await axios.post('/api/field/', data)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async createFieldOption(data) {
		try {
			const response = await axios.post('/api/option/', data)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getDataFieldOptionRelatedScenarios(field_option_id) {
		try {
			const response = await axios.get(
				`/api/field_option/scenarios/${field_option_id}/?page=1&page_size=10`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getDataFieldRelatedScenarios(field_id) {
		try {
			const response = await axios.get(
				`/api/field/scenarios/${field_id}/?page=1&page_size=10`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async excludeFieldOption(field_id, data) {
		try {
			const response = await axios.put(
				`/api/field/${field_id}/excluded_options_by_group/`,
				data
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async updateFieldOption(data) {
		try {
			const response = await axios.put(`/api/option/${data.id}/`, data)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async deleteFieldOption(cg_id, id) {
		try {
			const response = await axios.delete(
				`/api/customer_group/${cg_id}/option/${id}/`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async editCustomerGroupConferenceSettings(customer_group_id, data) {
		try {
			const response = await axios.put(
				`/api/customer_group_conference_settings/${customer_group_id}/`,
				data
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getAuditSettings(customer_group_id) {
		try {
			const response = await axios.get(
				`/api/customer_group_audit_settings/${customer_group_id}/`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getAuditRecipientsOptions(customer_group_id) {
		try {
			const response = await axios.get(
				`/api/get_audit_recipients/${customer_group_id}/`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async saveAuditSettings(customer_group_id, data) {
		try {
			const response = await axios.post(
				`/api/customer_group_audit_settings/${customer_group_id}/`,
				data
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getOnCallSettings(customer_group_id) {
		try {
			const response = await axios.get(
				`/api/customer_group_on_call_settings/${customer_group_id}/`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async retrieveOnCallStaffing(customer_group_id) {
		try {
			const response = await axios.get(
				`/api/retrieve_on_call_staffing/${customer_group_id}/`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async saveOnCallSettings(customer_group_id, data) {
		try {
			const response = await axios.post(
				`/api/customer_group_on_call_settings/${customer_group_id}/`,
				data
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async exportOnCallPeriods(data) {
		try {
			const response = await axios.post(
				`/api/customer_group_on_call_export/`,
				data
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getOnCallRecipients(cg_id) {
		try {
			const response = await axios.get(`/api/customer_group/on_call_recipients/${cg_id}/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getGeneratedOnCallFileUrl(id) {
		try {
			const response = await axios.get(`/api/export_reports/${id}/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getMergeFields(cg_id) {
		try {
			const response = await axios.get(`/api/merge_fields/${cg_id}/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async saveMergeFields(obj) {
		try {
			const response = await axios.post(`/api/merge_fields/${obj.cg_id}/`, {
				field_data: obj.data,
			})
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getSavedTemplate(cg_id) {
		try {
			const response = await axios.get(`/api/user/import_template/${cg_id}/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getGpgPublicKey() {
		try {
			const response = await axios.get(`/api/user/gpg_key/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async getSftpServerUrl() {
		try {
			const response = await axios.get(`/api/user/sftp-server-url/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async analyzeFile(data, calculatePercentage, is_sftp_template) {
		try {
			const response = await axios.post(
				`/api/user/analyze/?full_import_analyze=false`,
				data,
				!is_sftp_template ? {onUploadProgress: progress_event => calculatePercentage(progress_event, "Analyse the file ...")} : ''
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async listImportFieldsByGroup(id) {
		try {
			const response = await axios.get(
				`/api/customer_group/${id}/fields/?import_contacts=true&import_by_column=false`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async listDescendantFields(id) {
		try {
			const response = await axios.get(
				`/api/customer_group/${id}/fields/?import_contacts=true&import_by_column=true`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async deleteTemplate(index) {
		try {
			await axios.delete(`/api/user/delete_template/${index}/`)
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async saveTemplate(data) {
		try {
			const response = await axios.post(`/api/user/import_template/`, data)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async updateAudioNoticeboardByPhone(data) {
		try {
			const response = await axios.patch(`/api/audio-noticeboard/`, data)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	// Registration Template Instances
	async getRegistrationTemplateInstances(rcg_id) {
		try {
			const response = await axios.get(
				`/api/customer_group/${rcg_id}/registration-templates/`
			)
			return response.data
		} catch (error) {
			const response = {
				error: true,
				error_message: error.response.data,
			}
			return response
		}
	},

	async getRegistrationTemplateMappings(dataset) {
		try {
			const response = await axios.get(
				`/api/registration/fields?dataset=${dataset}`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},

	async saveRegistrationTemplateInstance(instance) {
		try {
			const response = await axios.put(
				`/api/registration/${instance.id}/`,
				instance
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},

	async deleteRegistrationTemplateInstance(instance_id) {
		try {
			const response = await axios.delete(`/api/registration/${instance_id}/`)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async generateRegisterQRCode(instance_id, registration_type) {
		try {
			const response = await axios.get(
				`/api/registration/${instance_id}/generate-qr-${registration_type.toLowerCase()}/`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
	async generateDeregisterQRCode(instance_id) {
		try {
			const response = await axios.get(
				`/api/registration/${instance_id}/generate-qr-deregister/`
			)
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
			}
		}
	},
}
