import axios from 'axios';

export default {
	async getRules(form_id) {
		const response = await axios.get(`/api/incidents/form/${form_id}/rules/`);
		return response.data
	},

	async deleteRule(rule) {
		await axios.delete(`/api/incidents/form/${rule.form}/rules/${rule.id}/`);
	},

	async patchRule(rule, order) {
		const url = `/api/incidents/form/${rule.form}/rules/${rule.id}/`;
		return await axios.patch(url, { order });
	}
}
