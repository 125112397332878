<template>
	<div :class="class_list">
		<Default
			v-if="snackbar_options.type == 'default'"
		/>
		<Error
			v-if="snackbar_options.type == 'error'"
			@dismiss='dismiss'
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Default from '@/components/utils/snackbars/types/Default.vue';
import Error from '@/components/utils/snackbars/types/Error.vue';

export default {
	name: 'Base',
	components: {
		Default,
		Error
	},
	computed: {
		...mapGetters(['snackbar_options'])
	},
	data() {
		return {
			class_list: {
				'base': true,
				'transitioned': false
			},
		}
	},
	methods: {
		...mapActions(['updateSnackbarOptions']),
		dismiss() {
			this.class_list.transitioned = false;
			setTimeout(() => {
				this.updateSnackbarOptions({'show': false});
			}, 300);
		}
	},
	mounted() {
		requestAnimationFrame(() => {
			this.class_list.transitioned = true;
		});
		if (this.snackbar_options.type === 'error') {
			return;
		}
		setTimeout(() => {
			this.dismiss();
		}, 5000);
	}
}
</script>

<style lang="scss" scoped>
.base {
	@apply fixed;
	@apply left-0;
	@apply right-0;
	@apply max-w-sm;
	@apply text-base;
	@apply text-left;
	@apply text-white;
	@apply p-2;
	@apply rounded-md;
	@apply drop-shadow;
	@apply transition-all;
	@apply duration-300;
	@apply ease-in-out;
	@apply bg-materialGray;
	@apply translate-y-full;
	@apply mx-4;
	@apply sm:mx-auto;
	width: fit-content;
	z-index: 5;
	bottom: 1rem;
	transform: translateY(calc(100% + 1rem));
}
.transitioned {
	transform: translateY(0);
}

</style>
