const state = {
	invoke_qr_errors: ''
}
const getters = {
	invoke_qr_errors:(state) => state.invoke_qr_errors
}
const actions = {
	set_invoke_qr_errors({commit}, error){
		commit('setInvokeQrErrors', error)
	}
}
const mutations = {
	setInvokeQrErrors:(state, new_val) => {
		state.invoke_qr_errors = new_val
	}
}
export default { state, getters, actions, mutations };