import DatasetsAPI from '@/services/api/Datasets.js'
import store from '@/store/store.js'

const state = {
	data_fields: [],
	remove_options: [],
}

const getters = {
	data_fields: (state) => state.data_fields,
	remove_options: (state) => state.remove_options,
}

const actions = {
	getDataFields({ commit }, cg_id) {
		let contact_types = [
			'PHONE(SMS)',
			'PHONE(SMS&VOICE)',
			'PHONE(VOICE)',
			'EMAIL',
			'PAGER',
		]
		let data_fields = store.getters.cg_fields
			.filter((field) => {
				return !contact_types.includes(field.field_type)
			})
			.map((field) => {
				let excluded_options = []
				let is_inherited = false
				field.mapping.forEach((mapped_field) => {
					// Find the mapping for the specified cg_id
					if (cg_id != mapped_field.customer_group) {
						return
					}
					is_inherited = mapped_field.is_inherited
					if (mapped_field.excluded_options_by_group) {
						excluded_options = mapped_field.excluded_options_by_group.options
					}
				})
				field.is_inherited = is_inherited
				field.excluded_options = excluded_options
				delete field.mapping
				return field
			})
			.sort((a, b) => {
				if (a.label.toLowerCase() === 'reports recipients') {
					return -1
				} else if (a.is_inherited && !b.is_inherited) {
					return -1
				} else if (!a.is_inherited && b.is_inherited) {
					return 1
				} else if (!a.field_type.toLowerCase().includes('select') && b.field_type.toLowerCase().includes('select')) {
					return -1
				} else if (a.field_type.toLowerCase().includes('select') && !b.field_type.toLowerCase().includes('select')) {
					return 1
				} else {
					return a.id - b.id
				}
			})
		let remove_options_list = data_fields.map((field) => {
			return {
				error: false,
				open_list: false,
			}
		})
		commit('setDataFields', data_fields)
		commit('setRemoveOptions', remove_options_list)
	},
	updateDataFields({ commit }, data) {
		commit('setDataFields', data)
	},
	async createFieldOption({ commit }, data) {
		const response = await DatasetsAPI.createFieldOption(data.option_data)
		if (response.error) {
			return response
		}
		await store.dispatch('getCgFields', data.cg_id)
		store.dispatch('getDataFields', data.cg_id)
		return response
	},
	async excludeFieldOption({ commit }, data) {
		const response = await DatasetsAPI.excludeFieldOption(
			data.field_id,
			data.option_data
		)
		if (response.error) {
			return response
		}
		await store.dispatch('getCgFields', data.option_data.customer_group)
		store.dispatch('getDataFields', data.option_data.customer_group)
		return response
	},
	async updateFieldOption({ commit }, data) {
		const response = await DatasetsAPI.updateFieldOption(data.option_data)
		if (response.error) {
			return response
		}
		await store.dispatch('getCgFields', data.cg_id)
		store.dispatch('getDataFields', data.cg_id)
		return response
	},
	async deleteFieldOption({ commit }, data) {
		const response = await DatasetsAPI.deleteFieldOption(data.cg_id, data.id)
		if (response.error) {
			return response
		}
		await store.dispatch('getCgFields', data.cg_id)
		store.dispatch('getDataFields', data.cg_id)
		return response
	},
	async getDataFieldOptionRelatedScenarios({ commit }, field_option_id) {
		const response = await DatasetsAPI.getDataFieldOptionRelatedScenarios(
			field_option_id
		)
		return response
	},
	async getDataFieldRelatedScenarios({ commit }, field_id) {
		const response = await DatasetsAPI.getDataFieldRelatedScenarios(field_id)
		return response
	},
	updateRemoveOptions({ commit }, data) {
		let options_list = JSON.parse(JSON.stringify(store.getters.remove_options))
		options_list[data.index] = data.values
		commit('setRemoveOptions', options_list)
	},
}

const mutations = {
	setDataFields: (state, fields) => (state.data_fields = fields),
	setRemoveOptions: (state, options_list) =>
		(state.remove_options = options_list),
}

export default { state, getters, actions, mutations }
