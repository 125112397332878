import DatasetsAPI from '@/services/api/Datasets.js'
import store from '@/store/store.js'
import helpers from '@/utils/helpers.js'

const state = {
	sftp_settings: [],
	import_type_options: [
		{ label: 'Add new', value: 'basic_import' },
		{ label: 'Add new and update existing', value: 'update_import' },
		{
			label:
				'Add new, update existing and delete any users not listed in import file',
			value: 'full_import',
		},
		{ label: 'Delete these users only', value: 'delete_only_import' },
	],
	selection_list: [],
}

const getters = {
	sftp_settings: (state) => state.sftp_settings,
	selection_list: (state) => state.selection_list,
	sftp_templates: (state) => state.sftp_settings.templates,
}

const actions = {
	async getSavedTemplate({ commit }, cg_id) {
		const response = await DatasetsAPI.getSavedTemplate(cg_id)

		for (let index in response.templates) {
			await store.dispatch('updateSelectionList', {
				import_type: response.templates[index].template_settings.import_type,
				column: response.templates[index].template_settings.import_by_column,
				cg: response.templates[index].template_settings.customer_group,
				index,
				external_id: response.templates[index].template_settings.external_id,
				gpg_encrypted:
					response.templates[index].template_settings.gpg_encrypted,
			})
		}
		commit('setSavedTemplate', response)
	},
	async updateSelectionList({ commit }, obj) {
		let options = await helpers.buildSelectionListOptions(obj)
		let copy_selection_list = JSON.parse(
			JSON.stringify(store.getters.selection_list)
		)
		copy_selection_list[obj.index] = options
		commit('setSelectionList', copy_selection_list)
	},
	async saveTemplate({ commit }, data) {
		const response = await DatasetsAPI.saveTemplate(data)
		return response
	},
	updateSftpTemplates({ commit }, data) {
		commit('setSftpTemplates', data)
	},
	async analyzeFile({ commit }, obj) {
		var form_data = new FormData()
		form_data.append('file', obj.val)
		const response = await DatasetsAPI.analyzeFile(form_data, null, true)
		let sftp_settings = JSON.parse(
			JSON.stringify(store.getters.sftp_settings)
		)
		sftp_settings.templates[obj.index].template_settings.rules=null
		sftp_settings.templates[obj.index] = {
			...sftp_settings.templates[obj.index],
			...{ file_headers: response.file_headers, file_data: response.file_data },
		}
		commit('setSftpSettings', sftp_settings)
	},
	async deleteTemplate({ commit }, index) {
		await DatasetsAPI.deleteTemplate(index)
		let sftp_templates = JSON.parse(
			JSON.stringify(store.getters.sftp_templates)
		)
		sftp_templates.splice(index, 1)
		commit('setSftpTemplates', sftp_templates)
	}
}

const mutations = {
	setSavedTemplate: (state, sftp_settings) =>
		(state.sftp_settings = sftp_settings),
	setSelectionList: (state, val) => (state.selection_list = val),
	setSftpTemplates: (state, sftp_templates) =>
		(state.sftp_settings.templates = sftp_templates),
	setSftpSettings: (state, sftp_settings) =>
		(state.sftp_settings = sftp_settings),

}

export default { state, getters, actions, mutations }
