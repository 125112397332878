<template>
	<q-expansion-item dense v-model="expanded" v-bind="$attrs">
		<template v-slot:header>
			<q-item-section avatar>
				<q-avatar :icon="icon" />
			</q-item-section>
			<q-item-section class="text-left"> {{ label }} </q-item-section>
		</template>
		<q-list separator dense>
			<layout-drawer-item
				v-for="(item, index) in items"
				:key="index"
				:label="item.label"
				:icon="item.icon"
				:route_name="item.route_name"
				:active="$route.name === item.route_name"
				:inset-level="0.35"
			/>
		</q-list>
	</q-expansion-item>
</template>

<script>
import LayoutDrawerItem from '@/components/utils/LayoutDrawerItem'
export default {
	name: 'LayoutDrawerExpansionItem',
	components: {
		LayoutDrawerItem,
	},
	props: {
		items: Array,
		label: String,
		icon: String,
		default_open: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			expanded: false,
		}
	},
	watch: {
		default_open(val) {
			this.expanded = val
		},
	},
}
</script>
