<template>
	<div :class="parent_el_class_list">
		<div :class="input_wrapper_class_list">
			<div class="icon-wrapper leading-icon-wrapper">
				<slot name="leading-icon"></slot>
			</div>
			<input
				:class="input_class_list"
				:name="name"
				:id="id"
				:disabled="disabled"
				:autocomplete="comp_autocomplete"
				v-model="inputVal"
				v-if="!textarea"
				:type="type"
				@focusout="inputFocusOut"
				@focus="inputFocus"
			/>
			<div class="input-borders"></div>
			<div :id="`${id}-notch`" :class="notch_class_list"></div>
			<textarea
				:class="input_class_list"
				:name="name"
				:id="id"
				:disabled="disabled"
				:rows="rows || 5"
				v-if="textarea"
				v-model="inputVal"
				@focusout="inputFocusOut"
				@focus="inputFocus"
			></textarea>
			<label
				@click="labelClick"
				:class="label_class_list"
				:id="`${id}-label`"
				:for="id"
				>{{ label }}</label
			>
			<div class="icon-wrapper trailing-icon-wrapper">
				<slot name="trailing-icon" v-if="!error"></slot>
			</div>
			<span v-if="error" class="material-icons-outlined error-icon">error</span>
			<div class="right-borders"></div>
		</div>
		<div v-if="helper_text && !error" class="helper-text">
			{{ helper_text }}
		</div>
		<div class="error-msg" v-if="error">{{ error_msg }}</div>
	</div>
</template>

<script>
export default {
	name: 'MaterialInput',
	props: {
		value: [String, Number],
		label: String,
		name: String,
		error_msg: String,
		error: Boolean,
		helper_text: String,
		focus: Boolean,
		disabled: Boolean,
		autocomplete: {
			type: Boolean,
			default: true,
		},
		textarea: Boolean,
		rows: Number,
		type: {
			validator: (val) => {
				return ['text', 'number'].includes(val)
			},
			default: 'text',
		},
	},
	data() {
		return {
			focused: false,
			trailing_icon_padding: false,
			guid: null,
			notch_width: 0,
			notch_class_list: {
				'input-notch': true,
				hidden: true,
			},
			label_class_list: {
				'material-input-label': true,
				'active-label': true,
				'label-transition': false,
			},
			allowed_num_type_keys: ['Backspace', '-', '.', 'Tab', 'Enter'],
		}
	},
	computed: {
		id() {
			return this.getId()
		},
		inputVal: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
		comp_autocomplete() {
			if (this.autocomplete) {
				return 'on'
			}
			return 'off'
		},
		input_wrapper_class_list() {
			return {
				relative: true,
				'inline-block': true,
				'w-full': true,
				'textarea-wrapper': this.textarea,
			}
		},
		input_class_list() {
			return {
				'leading-icon-padding': this.$slots['leading-icon'],
				'trailing-icon-padding': this.$slots['trailing-icon'],
				'material-input': true,
			}
		},
		parent_el_class_list() {
			return {
				'input-container': true,
				'inline-block': true,
				'disabled-input': this.disabled,
				'w-full': true,
				error: this.error,
				focused: this.focused,
				'leading-icon-input-wrapper': this.$slots['leading-icon'],
			}
		},
		inputIsEmpty() {
			return this.value === '' || this.value === null
		},
	},
	methods: {
		labelClick() {
			if (this.disabled) {
				return
			}
			this.activateLabel()
			this.focused = true
		},
		inputFocusOut() {
			if (this.inputIsEmpty) {
				this.notch_class_list['hidden'] = true
				this.label_class_list['active-label'] = false
			}
			this.focused = false
		},
		inputFocus() {
			this.activateLabel()
			this.focused = true
		},
		activateLabel() {
			this.notch_class_list['hidden'] = false
			this.label_class_list['active-label'] = true
		},
		getId() {
			return `${this.name}-${this.guid}`
		},
		setNotchWidth() {
			let label = document.querySelector(`#${this.getId()}-label`)
			let notch = document.querySelector(`#${this.getId()}-notch`)
			if (label.offsetWidth) {
				notch.style.width = (label.offsetWidth + 6).toString() + 'px'
			}
		},
		initStyles() {
			if (!this.inputIsEmpty) {
				this.label_class_list['active-label'] = true
				this.notch_class_list['hidden'] = false
			} else {
				this.label_class_list['active-label'] = false
			}
			setTimeout(() => {
				this.label_class_list['label-transition'] = true
			})
		},
	},
	created() {
		this.guid = Math.random().toString(16).slice(2)
		if (!this.inputIsEmpty) {
			this.label_class_list['active-label'] = true
		}
	},
	mounted() {
		this.setNotchWidth()
		this.initStyles()
	},
	watch: {
		value(val) {
			if (val == 0 || val) {
				this.activateLabel()
			}
		},
		focus() {
			if (this.focus) {
				this.inputFocus()
			} else {
				this.inputFocusOut()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.input-container {
	@apply relative;
	z-index: 1;
}
.material-input {
	@apply relative;
	@apply bg-transparent;
	@apply w-full;
	border: none;
	color: rgba(0, 0, 0, 0.87);
	padding: 6px 16px;
	height: 56px;
	z-index: 3;
	&:focus,
	&:focus-visible {
		outline: none;
	}
	&:hover {
		border-color: rgb(0, 0, 0);
	}
	// Remove number input arrows
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	&[type='number'] {
		-moz-appearance: textfield;
	}
}
.input-borders {
	@apply h-full;
	@apply w-full;
	@apply border;
	@apply rounded;
	@apply absolute;
	@apply left-0;
	@apply top-0;
	border-color: rgba(0, 0, 0, 0.38);
	z-index: 0;
}
.input-notch {
	@apply h-full;
	@apply border-t-2;
	@apply border-white;
	@apply absolute;
	@apply top-0;
	left: 12px;
	z-index: 1;
}
.material-input-label {
	@apply absolute;
	@apply cursor-text;
	@apply text-black;
	@apply opacity-60;
	font-size: 16px;
	top: 50%;
	transform: translateY(-50%);
	left: 16px;
	z-index: 2;
}
.label-transition {
	@apply transition-all;
	@apply duration-150;
	@apply ease-in-out;
}
.leading-icon-input-wrapper {
	.material-input-label {
		left: 40px;
	}
	.active-label {
		left: 16px;
	}
}
.helper-text {
	@apply opacity-60;
	@apply text-black;
	@apply text-left;
	@apply mt-1;
	@apply text-xs;
	padding: 0 16px;
}
// Focused state styles
.focused {
	& .material-input-label {
		color: var(--ac-highlight-color);
		opacity: 0.87;
	}
	& .input-borders {
		@apply border-2;
		border-color: var(--ac-highlight-color);
	}
}
.active-label {
	top: 0px;
	font-size: 12px;
}
// Icon stylings
.icon-wrapper {
	@apply flex;
	@apply items-center;
	@apply justify-center;
	position: absolute;
	font-size: 24px;
	top: 50%;
	transform: translateY(-50%);
}
.leading-icon-wrapper {
	left: 6px;
}
.leading-icon-padding {
	padding-left: 40px;
}
.trailing-icon-wrapper {
	right: 6px;
}
.trailing-icon-padding {
	padding-right: 40px;
}
// Textarea stylings
.textarea-wrapper {
	@apply py-3;
	.material-input-label {
		top: 24px;
	}
	.active-label {
		top: 0px;
	}
	.material-input {
		@apply border-b-0;
		@apply rounded-none;
		@apply bg-transparent;
		@apply resize-none;
		@apply transition-none;
		@apply px-4;
		@apply py-0;
		@apply h-auto;
		// Textarea scrollbar
		/* width */
		&::-webkit-scrollbar {
			width: 7px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			@apply bg-transparent;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			@apply bg-materialGray;
			@apply bg-opacity-70;
			border-radius: 10px;
		}
	}
}
// Disabled state styles
.disabled-input {
	@apply relative;
	@apply select-none;
	@apply opacity-60;
	&::before {
		@apply block;
		@apply absolute;
		@apply top-0;
		@apply left-0;
		@apply w-full;
		@apply h-full;
		content: '';
		z-index: 4;
	}
	.material-input {
		@apply cursor-default;
	}
	.material-input-label {
		@apply cursor-default;
	}
}
// Errors styling
.error {
	.input-borders {
		@apply border-red-700;
	}
	.material-input {
		padding-right: 40px;
	}
	.material-input-label,
	.error-icon,
	.error-msg {
		@apply text-red-700;
		@apply opacity-100;
	}
}
.error-msg {
	@apply text-xs;
	@apply text-left;
	@apply mt-1;
	padding: 0 16px;
}
.error-icon {
	font-size: 24px;
	position: absolute;
	right: 6px;
	top: 50%;
	transform: translateY(-50%);
}
</style>
