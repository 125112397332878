
import DispatchDetailsAPI from '@/services/api/DispatchDetails.js';
import store from '@/store/store.js'

const state = {
	paginated_recipients: [],
	count: 1,
	search_errors: "",
	file: {
		"message": "",
		"generating": false,
		"file_error": false,
	},
	can_resend: false,
	can_create_new_message: false,
	users_with_replies: [],
	query_param: "",
	timeout_message: "",
	error_message: "",
	is_session_init_message: false,
	resend_task_id: null,
	real_scenarios: [],
	test_scenarios: [],
	total_number_of_scenarios: 0,
	new_session_id: "",
	page_number: 1,
	items_per_page: 10,
	all_dataset_fields: [],
	total_not_responded_recipients: 0,
	report_details: {},
	user_stats_data: {
		total_recipients: 0,
		excluded_users: 0,
		total_responded_recipients: 0,
		delivered_recipients: 0,
		delivery_faliure_recipients: 0,
	},
	list_recipients_inited: false

};

const getters = {
	paginated_recipients: state => state.paginated_recipients,
	count: state => state.count,
	search_errors: state => state.search_errors,
	file: state => state.file,
	can_resend: state => state.can_resend,
	can_create_new_message: state => state.can_create_new_message,
	users_with_replies: state => state.users_with_replies,
	query_param: state => state.query_param,
	timeout_message: state => state.timeout_message,
	error_message: state => state.error_message,
	is_session_init_message: state => state.is_session_init_message,
	resend_task_id: state => state.resend_task_id,
	real_scenarios: state => state.real_scenarios,
	test_scenarios: state => state.test_scenarios,
	total_number_of_scenarios: state => state.total_number_of_scenarios,
	new_session_id: state => state.new_session_id,
	page_number: state => state.page_number,
	items_per_page: state => state.items_per_page,
	all_dataset_fields: state => state.all_dataset_fields,
	total_not_responded_recipients: state => state.total_not_responded_recipients,
	report_details: state => state.report_details,
	user_stats_data: state => state.user_stats_data,
	list_recipients_inited: state => state.list_recipients_inited
};

const actions = {
	async listRecipientsPagination ({commit}, args){
		let recipients;
		recipients = await DispatchDetailsAPI.getRecipientsPagination(args);
		if(recipients.error) {
			args.page_number = 1;
			recipients = await DispatchDetailsAPI.getRecipientsPagination(args);
		}
		let errors = recipients.results.length == 0 ? "The filter criteria does not match any results." : "";
		commit('setCount', recipients.count),
		commit("setPaginatedRecipients", recipients.results)
		commit("setSearchErrors", errors)
		await store.dispatch('getRecipientsIds', {"session_id": args.session_id, "query_params": store.getters.query_param});
		await store.dispatch('checkUsersStatistic', args.session_id);
	},
	async getAllDatasetFields({commit}, session){
		let session_settings = session.settings;
		let cg_id = store.getters.current_CG.customer_group.id
		let fields = createDefaultFields(session_settings)
		if(store.getters.logged_user.customer_permission === 'ADMIN' || store.getters.logged_user.customer_permission === 'OPERATOR'){
			let dataset_fields = await DispatchDetailsAPI.getDataFields(cg_id, session.id);
			for(let i in dataset_fields){
				dataset_fields[i].filter_by = "field_id_" + dataset_fields[i].id
			}
			fields = fields.concat(dataset_fields)
		}
		commit('setAllDatasetFields', fields)
	},
	async resendMessageToNoReplies({commit}, session_id) {
		let response = await DispatchDetailsAPI.sendToNoReplies(session_id);
		if(response.status == 200){
			commit('setResendMessage', response.data.task_id);
		} else{
			commit('setTimeoutMessage', "Re-send message failed!");
		}
	},
	async listScenariosForModal({commit}, args) {
		const response_scenarios = await DispatchDetailsAPI.getScenarios(args);
		let scenarios = []
		if(args.is_trigger) {
			scenarios = response_scenarios.results.scenarios.filter(scenario => scenario.id != args.triggering_scenario)
		} else {
			scenarios = JSON.parse(JSON.stringify(response_scenarios.results.scenarios));
		}

		let real_scenarios = [];
		let test_scenarios = [];
		test_scenarios = scenarios.filter(scenario => !scenario.real)
		real_scenarios = scenarios.filter(scenario => scenario.real)

		commit('setRealScenarios', real_scenarios)
		commit('setTestScenarios', test_scenarios)
		commit('setTotalNumberOfScenarios', response_scenarios.count)
	},

	updateFileTimeout({commit}) {
		let file = {
			"generating": false,
			"file_error": false,
			"message": "",
		};
		commit('setUpdatedFileTimeout', file)
	},

	async getRecipientsIds({commit}, args) {
		let response = await DispatchDetailsAPI.getRecipientsIds(args);
		let converted_ids_to_string = '';
		for(let element of response) {
			converted_ids_to_string += element+",";
		}
		commit('setUserIds', converted_ids_to_string);
	},

	async getReportDetails({commit}, session_id){
		let session_details = await DispatchDetailsAPI.getReportDetails(session_id);
		commit('setReportDetails', session_details)
	},

	async checkUsersStatistic({commit}, session_id) {
		let recipients = await DispatchDetailsAPI.aggregate(session_id, 'reply');
		let total_recipients = 0;
		let excluded_users = 0;
		let total_responded_recipients = 0;
		let session_type = state.report_details.session_type.charAt(0).toUpperCase() + state.report_details.session_type.slice(1);
		for(let i in recipients){
			total_recipients += recipients[i].count;
			if(recipients[i].label == "Excluded Users"){
				excluded_users += recipients[i].count;
			}
			if(
				recipients[i].label != "Excluded Users" 
				&& recipients[i].label != "No Response" 
				&& recipients[i].label != 'Delivery Failure'
				&& !(recipients[i].label == '2' && session_type == 'Conference')
			){
				total_responded_recipients += recipients[i].count;
			}
		}

		recipients = await DispatchDetailsAPI.aggregate(session_id, 'state');
		let delivered_recipients = 0;
		let delivery_faliure_recipients = 0;
		for(let i in recipients){
			if(recipients[i].label == "Successful Delivery" || recipients[i].label == 'Successful Connection' || recipients[i].label == 'Conference Rejected'){
				delivered_recipients += recipients[i].count;
			}
			if(recipients[i].label == "Delivery Failure"){
				delivery_faliure_recipients += recipients[i].count;
			}
		}
		let total_not_responded_recipients = total_recipients - total_responded_recipients - excluded_users - delivery_faliure_recipients;
		let user_stats_data = {
			'total_recipients': total_recipients,
			'excluded_users': excluded_users,
			'total_responded_recipients': total_responded_recipients,
			'delivered_recipients': delivered_recipients,
			'total_not_responded_recipients': total_not_responded_recipients
		}
		commit('setUserStatisticsData', user_stats_data);
	},
	initListRecipients ({commit}) {
		commit('setListRecipients', true);
	}
};

const mutations = {
	setPaginatedRecipients: (state, paginated_recipients) => {state.paginated_recipients = paginated_recipients},
	setCount: (state, count) => {state.count = count},
	setSearchErrors: (state, errors) => {state.search_errors = errors},
	updateFile: (state, file) => state.file = file,
	canResend: (state, can_resend) => state.can_resend = can_resend,
	canCreateNewMessage: (state, can_create_new_message) => state.can_create_new_message = can_create_new_message,
	setUserIds: (state, users) => state.users_with_replies = users,
	setQueryParam: (state, query_param) => state.query_param = query_param,
	setTimeoutMessage: (state, message) => { state.timeout_message = message },
	setErrorMessage: (state, message) => { state.error_message = message },
	setRealScenarios: (state, real_scenarios) => { state.real_scenarios = real_scenarios},
	setTestScenarios: (state, test_scenarios) => { state.test_scenarios = test_scenarios},
	setTotalNumberOfScenarios: (state, count) => state.total_number_of_scenarios = count,
	setUpdatedFileTimeout: (state, file) => state.file = file,
	setAllDatasetFields: (state, all_fields) => state.all_dataset_fields = all_fields,
	setTotalNotRespondedRecipients: (state, total_not_responded_recipients) => state.total_not_responded_recipients = total_not_responded_recipients,
	setResendMessage: (state, resend_task_id) => {
		if (resend_task_id) {
			state.resend_task_id = resend_task_id;
			state.is_session_init_message = true;
		} else {
			state.resend_task_id = null;
			state.is_session_init_message = false;
		}
	},
	setReportDetails: (state, session_details) => {state.report_details = session_details},
	setUserStatisticsData: (state, user_stats_data) => {
		state.user_stats_data = user_stats_data
	},
	setListRecipients: (state, value) => {state.list_recipients_inited = value}
};

//  ---------------------------------EXTRA FUNCTIONS-----------------------------------------

function createDefaultFields(session_settings){
	let logged_user = store.getters.logged_user
	let root_cg_permissions = store.getters.current_CG.root_customer_group_permissions
	let operator_extended_access_permission = "Allow Operators to view address string (detailed reports)"
	let aggregate = async function(session_id, filter_by) {
		let response = await DispatchDetailsAPI.aggregate(session_id, filter_by)
		let field_options = []
		// remove the Delivery Failure dispatches when we are filtering by reply or reply_time
		for(let i=0;i<response.length;i++){
			if(filter_by != 'state' && response[i].label != 'Delivery Failure'){
				field_options.push(response[i]);
			}
		}
		if(!field_options.length){
			field_options = response;
		}
		let all_count = 0
		for(let i in field_options){
			all_count += field_options[i].count
		}
		let first_option = [{'label': "All", 'value': "All", 'count': all_count}]
		field_options = first_option.concat(field_options);
		for(let i in field_options){
			field_options[i].display_count = field_options[i].label + " (" + field_options[i].count + ")"
		}
		return field_options;
	}
	let fields = [{
		'filter_by': 'state',
		'label': 'Delivery Status',
		"field_type": "SINGLESELECT",
		'options': [{'label': "All", 'value': "All"}],
		'on_selected': aggregate
	}]

	if(session_settings.polling_options){
		fields = fields.concat([
			{
				'filter_by':'reply',
				'label':'Response',
				"field_type": "SINGLESELECT",
				"options": [],
				"on_selected": aggregate
			},
			{
				'filter_by':'reply_time',
				'label':'Response Time',
				"field_type": "SINGLESELECT",
				"options": [],
				"on_selected": aggregate

			}
		])
	}
	if(!(root_cg_permissions.includes(operator_extended_access_permission) && (logged_user.customer_permission==='OPERATOR'))){
		fields = fields.concat([
			{
				'filter_by':'address_string',
				'label':'User Name',
				"field_type": "TEXT",
				"options": [],
			},
		]);
		if((session_settings.EMAIL != undefined && session_settings.EMAIL != null)){
			fields = fields.concat([
				{
					'filter_by':'email',
					'label':'Email',
					"field_type": "TEXT",
					"options": [],
				}
			])
		}
		if((session_settings.VOICE != undefined && session_settings.VOICE != null) ||
			(session_settings.SMS != undefined && session_settings.SMS !=null)){
				fields = fields.concat([
				{
					'filter_by':'phone_number',
					'label':'Phone Number',
					"field_type": "TEXT",
					"options": [],
				},
			])
		}
	}
	return fields;
}
export default {state, getters, actions, mutations}
