export default {
	generate(arr, top = false, order_col = 'order') {
		return (!arr.length) ? 'zzzzz' :
			(top) ? decrement(arr[0][order_col]) : increment(arr[arr.length - 1][order_col]);
	},

	update(index, arr, order_col = 'order') {
		const succ = arr[index + 1];
		const pred = arr[index - 1];
		let order = '';

		// check if moved to top, bottom, or in-between
		if (!pred) {
			return (succ) ? decrement(succ[order_col]) : order;
		} else if (!succ) {
			return increment(pred[order_col]);
		}

		// update the order (default behaviour)
		order = decrement(succ[order_col]);

		// check for overlapping
		if (order === pred[order_col]) {
			order += 'm'; //middle of the alphabet
		} else if (pred[order_col].length > order.length) {
			order = increment(pred[order_col]);
		}
		return order;
	},
};

function increment(order_string) {
	let string = order_string;
	let acc = '';

	while (string) {
		let char = string.slice(-1);
		string = string.slice(0, -1);

		if (char === 'z') {
			acc += char;
			continue;
		} else {
			char = String.fromCharCode(char.charCodeAt(0) + 1);
			return string + char + acc;
		}
	}
	return order_string + 'a';
}

function decrement(order_string) {
	let string = order_string;
	let acc = '';

	while (string) {
		let char = string.slice(-1);

		if (char === '0') {
			return char + order_string;
		}

		string = string.slice(0, -1);

		if (char === 'a') {
			acc += char;
			continue;
		} else {
			char = String.fromCharCode(char.charCodeAt(0) - 1);
			return string + char + acc;
		}
	}
	return '0' + 'z'.repeat(order_string.length);
}
