import axios from 'axios';
import store from '@/store/store.js';

axios.interceptors.request.use((config) => {
		if (localStorage.getItem("auth-token")){
			config.headers['Authorization'] = 'Token ' + JSON.parse(localStorage.getItem("auth-token"));
		}
		return config;
	}, 
	(error) => {
		return Promise.reject(error)
	}
);

axios.interceptors.response.use((response) => {
	// Update the expiry time in the localStorage
	try {
		const date = new Date();
		date.setSeconds(date.getSeconds() + 1700);
		let idle_expiration = date.toISOString();
		store.dispatch('setIdleExpiration', idle_expiration)
	}
	catch(error) {};
	
	return response;
}, 
(error) => {
	if (error.response.status === 401 || error.response.status === 403) {
			store.dispatch('logout');
	}
	else {
		return Promise.reject(error);
	}
});
