import DatasetsAPI from '@/services/api/Datasets.js'
import store from '@/store/store.js'

const state = {
	audit_settings: null,
	audit_recipients_options: null,
	audit_message: null,
	audit_domains: [],
	audit_additional_recipients: [],
}

const getters = {
	audit_settings: (state) => state.audit_settings,
	audit_recipients_options: (state) => state.audit_recipients_options,
	audit_message: (state) => state.audit_message,
	audit_domains: (state) => state.audit_domains,
	audit_additional_recipients: (state) => state.audit_additional_recipients,
}

const actions = {
	async getAuditSettings({ commit }, customer_group_id) {
		let response = await DatasetsAPI.getAuditSettings(customer_group_id)
		let recipients_settings = [
			'imports_this_dataset_audit_recipients',
			'imports_children_dataset_audit_recipients',
			'on_call_imports_this_dataset_audit_recipients',
			'on_call_imports_children_dataset_audit_recipients',
			'media_this_dataset_audit_recipients',
			'media_children_dataset_audit_recipients',
			'disabled_user_this_dataset_audit_recipients',
			'disabled_user_children_dataset_audit_recipients',
			'invalid_phone_email_this_dataset_audit_recipients',
			'invalid_phone_email_children_dataset_audit_recipients',
			'on_call_this_dataset_audit_recipients',
			'on_call_children_dataset_audit_recipients',
			'phone_invoke_this_dataset_audit_recipients',
			'phone_invoke_children_dataset_audit_recipients',
			'not_logged_in_users_audit_recipients',
			'suppressed_users_audit_recipients',
			'inactive_users_audit_recipients',
			'no_number_users_audit_recipients',
			'audit_domains',
			'audit_additional_recipients',
		]

		if (!response.audit_settings) {
			commit('setAuditMessage', response.message)
			let obj = { is_audit_active: false }
			for (let item of recipients_settings) {
				obj[item] = []
			}
			commit('setAuditSettings', obj)
		} else {
			// if there is data returned database format the recipient arrays for the select component
			for (let item of recipients_settings) {
				if (item == 'audit_domains'){
					if (!response.audit_settings.hasOwnProperty(item)){
						commit('setAuditDomains', [])
					}else{
						commit('setAuditDomains', response.audit_settings[item])
					}
				}else if (item == 'audit_additional_recipients'){
					if (!response.audit_settings.hasOwnProperty(item)){
						commit('setAuditAdditionalRecipients', [])
					}else{
						commit('setAuditAdditionalRecipients', response.audit_settings[item])
					}
				}else if (!response.audit_settings.hasOwnProperty(item)) {
					response.audit_settings[item] = []
				} else {
					response.audit_settings[item] = response.audit_settings[item].map(
						(recipient) => {
							return {
								label: recipient.email,
								value: recipient.id,
							}
						}
					)
				}
			}
			commit('setAuditSettings', response.audit_settings)
		}
	},
	updateAuditSettings({ commit }, obj) {
		commit('setAuditSettings', obj)
	},
	async getAuditRecipientsOptions({ commit }, customer_group_id) {
		const response = await DatasetsAPI.getAuditRecipientsOptions(
			customer_group_id
		)
		let formatted_audit_recipients_options = response.map((recipient) => {
			return {
				label: recipient.email,
				value: recipient.id,
			}
		})
		formatted_audit_recipients_options.sort((a, b) => a.label.localeCompare(b.label))
		commit('setAuditRecipientsOptions', formatted_audit_recipients_options)
	},
	async saveAuditSettings({ commit }, customer_group_id) {
		let copy = JSON.parse(JSON.stringify(store.getters.audit_settings))
		let recipients_settings = [
			'imports_this_dataset_audit_recipients',
			'imports_children_dataset_audit_recipients',
			'on_call_imports_this_dataset_audit_recipients',
			'on_call_imports_children_dataset_audit_recipients',
			'media_this_dataset_audit_recipients',
			'media_children_dataset_audit_recipients',
			'disabled_user_this_dataset_audit_recipients',
			'disabled_user_children_dataset_audit_recipients',
			'invalid_phone_email_this_dataset_audit_recipients',
			'invalid_phone_email_children_dataset_audit_recipients',
			'on_call_this_dataset_audit_recipients',
			'on_call_children_dataset_audit_recipients',
			'phone_invoke_this_dataset_audit_recipients',
			'phone_invoke_children_dataset_audit_recipients',
			'not_logged_in_users_audit_recipients',
			'suppressed_users_audit_recipients',
			'inactive_users_audit_recipients',
			'no_number_users_audit_recipients',
		]

		for (let item of recipients_settings) {
			copy[item] = copy[item].map((recipient) => {
				return recipient.value
			})
		}
		if (store.getters.audit_domains.length){
			copy['audit_domains'] = store.getters.audit_domains
		}
		if (store.getters.audit_additional_recipients.length){
			copy['audit_additional_recipients'] = store.getters.audit_additional_recipients
		}

		if(copy.is_audit_active && !copy.add_additional_emails_to_distribution_list){
			for (let key in store.getters.audit_settings) {
				if (key.includes('for_')){
					store.getters.audit_settings[key] = []
				}else if (key == 'audit_domains'){
					commit('setAuditDomains', [])
				}else if (key == 'audit_additional_recipients'){
					commit('setAuditAdditionalRecipients', [])
				}
			}
		}
		if (!copy.is_audit_active) {
			for (let item_name in copy){
				if (item_name.includes('recipients')){
					if (item_name == 'audit_additional_recipients'){
						commit('setAuditAdditionalRecipients', [])
					}else{
						store.getters.audit_settings[item_name] = []
					}
				}else if (item_name.includes('audit_domains')){
					commit('setAuditDomains', [])
				}else{
					store.getters.audit_settings[item_name] = false
				}
			}
			copy = { is_audit_active: false }
		}else {
			// remove the recipients array from the payload data object if it's empty
			for (let key in store.getters.audit_settings) {
				if (key.includes('recipients')) {
					if (key.includes('for_') && (
						copy['add_additional_emails_to_distribution_list'] == false || !copy['add_additional_emails_to_distribution_list']
					)){
						delete copy[key]
					}else if (copy[key] && !copy[key].length){
						delete copy[key]
					}
				}
				// this has to check if the type is also boolean
				else if (store.getters.audit_settings[key] === false) {
					if (key == 'add_additional_emails_to_distribution_list'){
						if (copy['audit_domains']){
							delete copy['audit_domains']
						}
						if (copy['audit_additional_recipients']){
							delete copy['audit_additional_recipients']
						}
					}
					delete copy[key]
				}
			}
		}

		const response = await DatasetsAPI.saveAuditSettings(customer_group_id, copy)
		return response
	},
	updateAuditDomains({ commit }, obj){
		commit('setAuditDomains', obj)
	},
	updateAuditAdditionalRecipients({ commit }, obj){
		commit('setAuditAdditionalRecipients', obj)
	}
}

const mutations = {
	setAuditSettings: (state, audit_settings) =>
		(state.audit_settings = audit_settings),
	setAuditRecipientsOptions: (state, audit_recipients_options) =>
		(state.audit_recipients_options = audit_recipients_options),
	setAuditMessage: (state, message) => (state.audit_message = message),
	setAuditDomains: (state, audit_domains) =>
		(state.audit_domains = audit_domains),
	setAuditAdditionalRecipients: (state, audit_additional_recipients) =>
		(state.audit_additional_recipients = audit_additional_recipients),
}

export default { state, getters, actions, mutations }
